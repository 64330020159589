import React from "react";
import { Link } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa6";
import menuData from "../Data/MenuData";
import "../styles/SiteMap.css";

export const SiteMap = () => {
  return (
    <>
     
     {/* breadcrumb section start */}
     <section className="breadcrumb_section">
             {/* container start */}
             <div className="container">
               {/* row start */}
               <div className="row">
                 {/* col start */}
                 <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                   {/* breadcrumb main div start */}
                   <div className="breadcrumb_main_div">
                     {/* breadcrumb nav div start */}
                     <nav className="breadcrum_nav">
                       <ol className="breadcrumb">
                         <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                         <li className="breadcrumb-item active" aria-current="page">Sitemap </li>
                       </ol>
                     </nav>
                     {/* breadcrumb nav div end */}
                   </div>
                   {/* breadcrumb main div end */}
                 </div>
                 {/* col end */}
               </div>
               {/* row end */}
             </div>
             {/* container end */}
           </section>
           {/*  */}

{/* sitemap section start */}
<section className="sitemap_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        {/* sitemap main div start */}
        <div className="sitemap_main_div">
          <h1>Water Purifiers</h1>
          <ul>
          <li> <Link to="/water-purifiers/water-purifier"><i class="fa fa-angle-double-right" aria-hidden="true"></i> Water Purifier</Link></li>
    <li>  <Link to="/water-purifiers/ro-water-purifier"><i class="fa fa-angle-double-right" aria-hidden="true"></i> RO Water Purifier </Link></li>
    <li><Link to="/water-purifiers/water-filter"><i class="fa fa-angle-double-right" aria-hidden="true"></i> Water Filter </Link></li>
    <li><Link to="/water-purifiers/uv-water-purifier"><i class="fa fa-angle-double-right" aria-hidden="true"></i> UV Water Purifier </Link></li>
    <li> <Link to="/water-purifiers/gravity-water-purifier"><i class="fa fa-angle-double-right" aria-hidden="true"></i> Gravity Water Purifier </Link></li>
    <li><Link to="/water-purifiers/commercial-water-purifier"><i class="fa fa-angle-double-right" aria-hidden="true"></i> Commercial Water Purifier </Link></li>
</ul>

        </div>
        {/* sitemap main div end */}
      </div>
      {/* col end */}


       {/* col start */}
       <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        {/* sitemap main div start */}
        <div className="sitemap_main_div">
          <h2>Water Dispensers</h2>
          <ul>
          <li> <Link to="/water-dispensers/hot-normal-and-cold">
          <i class="fa fa-angle-double-right" aria-hidden="true"></i> Hot, Normal &amp; Cold
   </Link></li>
   <li>  <Link to="/water-dispensers/hot-normal-cold-and-cooling-cabinet">
   <i class="fa fa-angle-double-right" aria-hidden="true"></i> Hot, Normal, Cold &amp; Cooling Cabinet
   </Link></li>
   <li><Link to="/water-dispensers/hot-normal-cold-with-ro">
   <i class="fa fa-angle-double-right" aria-hidden="true"></i> Hot, Normal, Cold with RO
   </Link></li>
   <li><Link to="/water-dispensers/hot-and-normal">
   <i class="fa fa-angle-double-right" aria-hidden="true"></i> Hot &amp; Normal
   </Link></li>
</ul>
        </div>
        {/* sitemap main div end */}
      </div>
      {/* col end */}


       {/* col start */}
       <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        {/* sitemap main div start */}
        <div className="sitemap_main_div">
          <h2>Kitchen Appliances</h2>
          <ul>
          <li><Link to="/kitchen-appliances/mixer-grinder"><i class="fa fa-angle-double-right" aria-hidden="true"></i>  Mixer Grinder
   </Link></li>
   <li> <Link to="/kitchen-appliances/egg-boiler">
   <i class="fa fa-angle-double-right" aria-hidden="true"></i> Egg Boiler
   </Link></li>
</ul>
        </div>
        {/* sitemap main div end */}
      </div>
      {/* col end */}


       {/* col start */}
       <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        {/* sitemap main div start */}
        <div className="sitemap_main_div">
          <h2>Vacuum Cleaners</h2>
          <ul>
          <li><Link to="/vacuum-cleaners/wiz-handheld-vacuum-cleaner"> Wiz Handheld <i class="fa fa-angle-double-right" aria-hidden="true"></i> Vacuum Cleaner</Link></li>
   <li><Link to="/vacuum-cleaners/wet-and-dry-vacuum-cleaner"><i class="fa fa-angle-double-right" aria-hidden="true"></i> Wet &amp; Dry Vacuum Cleaner</Link></li>
</ul>
        </div>
        {/* sitemap main div end */}
      </div>
      {/* col end */}

      
       {/* col start */}
       <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        {/* sitemap main div start */}
        <div className="sitemap_main_div">
          <h2>Spare Parts</h2>
          <ul>
          <li><Link to="/spare-parts/spart-parts-one"><i class="fa fa-angle-double-right" aria-hidden="true"></i> Spare Parts - 1</Link></li>
   <li><Link to="/spare-parts/spare-parts-two"><i class="fa fa-angle-double-right" aria-hidden="true"></i> Spare Parts - 2</Link></li>
   <li><Link to="/spare-parts/spare-parts-three"><i class="fa fa-angle-double-right" aria-hidden="true"></i> Spare Parts - 3</Link></li>
</ul>
        </div>
        {/* sitemap main div end */}
      </div>
      {/* col end */}

       {/* col start */}
       <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        {/* sitemap main div start */}
        <div className="sitemap_main_div">
          <h2>Our Brand</h2>
         <ul>
          <li><Link to=""><i class="fa fa-angle-double-right" aria-hidden="true"></i> Keel</Link></li>
          <li><Link to=""><i class="fa fa-angle-double-right" aria-hidden="true"></i> Aqafresh</Link></li>
          <li><Link to=""><i class="fa fa-angle-double-right" aria-hidden="true"></i> grand plus</Link></li>
          <li><Link to=""><i class="fa fa-angle-double-right" aria-hidden="true"></i> royal aquafresh</Link></li>
         
         </ul>
        </div>
        {/* sitemap main div end */}
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        {/* sitemap main div start */}
        <div className="sitemap_main_div">
          <h2>Information</h2>
         <ul>
          <li><Link to=""><i class="fa fa-angle-double-right" aria-hidden="true"></i> About Us</Link></li>
          <li><Link to=""><i class="fa fa-angle-double-right" aria-hidden="true"></i> Privacy Policy</Link></li>
          <li><Link to=""><i class="fa fa-angle-double-right" aria-hidden="true"></i> Terms & Conditions</Link></li>
         
         </ul>
        </div>
        {/* sitemap main div end */}
      </div>
      {/* col end */}


    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* sitemap section end */}

     
    </>
  );
};
