import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import "../styles/Deal.css";
import duration from "dayjs/plugin/duration";
import { getAllDeals } from "../api/DealsApi";
import { AddToCart, GetFilteredProducts } from "../api/ProductsApi";
import toast, { Toaster } from "react-hot-toast";
import { Api_Url } from "../constant/Url";
import { MdOutlineShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getDecryptedCookie } from "../utils/Utils";
import Slider from "react-slick";
import { SkeletonCard } from "../components/layout/SkeletonCard";
import MyContext from "../context/myContext";

dayjs.extend(duration);

export const Deals = () => {
  const { handleAddtoCart } = useContext(MyContext);
  const [timeLeft, setTimeLeft] = useState(null);
  const [timerColor, setTimerColor] = useState("black");
  const [currentDeal, setCurrentDeal] = useState(null);
  const [Loadings, SetLoadings] = useState(false);

  const navigate = useNavigate();

  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;

  const calculateTimeLeft = (endTime) => {
    const now = dayjs();
    const end = dayjs(endTime);
    const diff = end.diff(now);

    if (diff <= 0) {
      return null; // Deal expired
    }

    const durationObj = dayjs.duration(diff);
    return {
      hours: durationObj.hours(),
      minutes: durationObj.minutes(),
      seconds: durationObj.seconds(),
    };
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Tablets and small desktops
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchAllDeals = async () => {
      try {
        SetLoadings(true);
        const res = await getAllDeals();

        if (res?.status === "success" && res?.data?.length > 0) {
          const now = dayjs();

          // Filter deals to include only active ones
          const activeDeals = res.data.filter((deal) => {
            const startTime = dayjs(deal.startTime);
            const endTime = dayjs(deal.endTime);
            return now.isAfter(startTime) && now.isBefore(endTime);
          });

          // Set the first active deal (if any)
          if (activeDeals.length > 0) {
            setCurrentDeal(activeDeals[0]);
          } else {
            setCurrentDeal(null); // No active deals
          }
        }
      } catch (error) {
        console.error("Error fetching deals:", error);
      } finally {
        SetLoadings(false);
      }
    };

    fetchAllDeals();
  }, []);

  const HandleAddToCart = async (productData) => {
    try {
      // if (userId === undefined) {
      //   //  navigate("/login");;
      // }

      const data = {
        productId: productData._id,
        quantity: 1,
        userId: userId,
        token: token,
      };

       await handleAddtoCart(data);

    
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewProduct = (productId) => {
    navigate(
      `${productId?.CategoryNameSlug}/${productId?.SubCategoryNameSlug}/product-details/${productId?._id}`
    );
  };

  const getShopColor = (shopNameSlug) => {
    const colors = {
      keel: "rgb(34,179,84)",
      aquafresh: "rgb(0,125,189)",
      "grand-plus": "rgb(104,208,247)",
      "royal-aquafresh": "rgb(254,0,0)",
    };

    // Default RGBA color if slug not found
    return colors[shopNameSlug] || "rgba(0, 0, 0, 1)"; // Black as fallback
  };

  useEffect(() => {
    if (currentDeal?.endTime) {
      const interval = setInterval(() => {
        const timeRemaining = calculateTimeLeft(currentDeal.endTime);

        if (!timeRemaining) {
          clearInterval(interval); // Stop the timer when the deal expires
          setTimeLeft(null);
        } else {
          setTimeLeft(timeRemaining);

          // Change timer color if less than 60 minutes are left
          if (dayjs(currentDeal.endTime).diff(dayjs(), "minute") <= 60) {
            setTimerColor("red");
          } else {
            setTimerColor("black");
          }
        }
      }, 1000);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [currentDeal]);

  const [AllProductsData, setAllProductsData] = useState();

  const fetchFilteredProducts = async () => {
    const filterData = {
      CategoryName: "",
      SubCategoryName: "",
      priceMin: "",
      priceMax: "",
      shopName: "",
      order: "",
      dealsType: "",
      dealNameSlug: currentDeal?.dealNameSlug,
    };

    try {
      const res = await GetFilteredProducts(filterData);
      if (res?.status === "success") {
        setAllProductsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFilteredProducts();
  }, [currentDeal]);

  return (
    <>
      <Toaster />

      {/* deal section start */}
      <section className="deal_section">
        {/* container start */}
        <div className="container">
          {/* deal title start */}
          <div className="row ">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <div className="deal_title">
                <h1>{currentDeal?.dealName}</h1>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-end">
              <div className=" ">
                <h5 className="bg-success text-white py-1 px-3 rounded-3 d-flex align-items-center justify-content-between">
                  <i class="bx bx-timer bx-tada  pe-2"></i>
                  <span >
                    {timeLeft?.hours}h {timeLeft?.minutes}m {timeLeft?.seconds}{" "}
                    sec
                  </span>
                </h5>
              </div>
            </div>
          </div>
          {/* deal title end */}
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {AllProductsData ? (
                <div>
                  <Slider {...settings}>
                    {AllProductsData.map((product) => (
                      <div className="product-card" key={product._id}>
                        <div
                          className="card-content"
                          onClick={() => handleViewProduct(product)}
                        >
                          <div className="image-wrapper">
                            <img
                              src={`${Api_Url}${product.ProductImage[0]}`}
                              alt={product.ProductName}
                              loading="lazy"
                            />
                             <div className="mobile_view_discount">
                                      Save {product.DiscountPercentage}%
                           </div>
                          </div>
                          <div className="product-details">
                            <h5>
                              {product?.ProductName?.length > 20
                                ? product?.ProductName.slice(0, 15) + "..."
                                : product?.ProductName}
                            </h5>
                            <div className="deals_txt_div">
                              <p>
                                {product?.ProductDescription?.length > 25
                                  ? product?.ProductDescription.slice(0, 20) +
                                    "..."
                                  : product?.ProductDescription}
                              </p>
                            </div>
                            <p className="deal_name_txt_offer">
                              {currentDeal?.dealName}
                            </p>
                            <p>
                              <b>
                                ₹{" "}
                                {parseInt(
                                  product.PriceAfterDiscount
                                ).toLocaleString()}{" "}
                              </b>{" "}
                              <span className="px-2">
                                ₹{" "}
                                {parseInt(
                                  product.TotalPriceWithTax
                                ).toLocaleString()}
                              </span>
                              <b className="discount">
                                Save {product.DiscountPercentage}%
                              </b>
                            </p>

                            <div className="brand-name-flex">
                              <div className="brand_name_title">Brand: </div>
                              <div className="deal_brand_name_div_txt" style={{
                                color: getShopColor(product.ShopNameSlug),
                              }}>
                                {product.ShopName}
                              </div>
                            </div>
                            <div className="product-actions">
                              <button className="view-details">
                                View Product
                              </button>
                              <button
                                className="add-to-cart"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  HandleAddToCart(product);
                                }}
                              >
                                <MdOutlineShoppingCart />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                  {/* <h5 className="deal_will_end_txt">
                    <span className="deal_end_title">DEAL WILL END IN</span>
                    <span className="deal_time_end_div">
                      {timeLeft?.hours} hr {timeLeft?.minutes} mins{" "}
                      {timeLeft?.seconds}
                      seconds
                    </span>
                  </h5> */}
                </div>
              ) : (
                <div className="row d-flex justify-content-center">
                  <p className="text-center">No Data Found</p>
                </div>
              )}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* deal section end */}
    </>
  );
};
