import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getAllBlogs } from "../../api/BlogsApi";
import { FaCalendarAlt } from "react-icons/fa";
import { Api_Url } from "../../constant/Url";

export const LatestBlogs = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768, // Mobile and tablet view
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [BlogsData, setBlogsData] = useState([]);

  const FetchAllBlogs = async () => {
    try {
      const res = await getAllBlogs();
      if (res?.status === "success") {
        setBlogsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const ConvertDateTimeFormat = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes();
    const amOrPm = date.getHours() < 12 ? "AM" : "PM";

    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    FetchAllBlogs();
  }, []);

  return (
    <>
    {/* lates blogs section start */}
    <section className="lates_blogs_section">
      {/* container start */}
      <div className="container">
        {/* latest title start */}
        <div className="latest_title">
          <h2>Latest Blogs</h2>
        </div>
        {/* latest title end */}

        {/* row start */}
        <div className="row">
          {/* col start */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
             {/* lates blog slider main div start */}
             <div className="latest_blog_slider_main_div">
             {BlogsData ? (
            <Slider {...settings}>
              {BlogsData.map((ele, index) => (
                <div key={ele?._id || index}>
                  {/* latest blog main div start */}
                  <div className="latest_blog_main_div">
                    {/* latest img box start*/}
                    <div className="latest_img_box">
                    <img src={`${Api_Url}/${ele?.BlogsImage}`}  alt="Blog Image"    loading="lazy" className="img-fluid"/>
                    </div>
                    {/* latest img box end*/}
                    {/* latest content div start */}
                    <div className="latest_blog_content_div">
                      <h2>{ele?.BlogsTitle?.length > 38 ? ele?.BlogsTitle.slice(0, 38) + "..." : ele?.BlogsTitle}</h2>
                      <p> {ele?.BlogsDescription?.length > 135 ? ele?.BlogsDescription.slice(0, 135) + "..." : ele?.BlogsDescription}
                      </p>

                    {/* time and date div start lates blogs */}
                    <div className="time_and_date_div_lates_blogs">
                    <FaCalendarAlt />
                       <span>{ConvertDateTimeFormat(ele?.createdAt)}</span>
                    </div>
                    {/* time and date div end lates blogs */}
                    </div>
                    {/* latest content div end */}
                    <div>
                     
                    </div>
                  </div>
                   {/* latest blog main div start */}
                </div>
              ))}
            </Slider>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          )}
             </div>
             {/* lates blog slider main div end */}
          </div>
          {/* col end */}
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
    {/* lates blogs section end */}
    </>
  );
};
