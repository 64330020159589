// export const domainName = `http://192.168.1.74:3000`;

export const Api_Url = `https://api.balajiwaterpurifier.com/api`;

// export const Api_Url = `http://localhost:9090`;  

// export const Api_Url = "";

export const convertSpaceToDash = (str) => {
  return str
    .trim() // Remove any leading/trailing spaces
    .replace(/\s+/g, "-") // Replace all spaces with dashes
    .toLowerCase() // Convert to lowercase
    .replace(/&/g, "and") // Replace & with 'and'
    .replace(/[^a-z0-9\-]/g, ""); // Remove any non-alphanumeric characters except
};

export const convertDashToSpace = (str) => {
  return str
    .split("-") // Split the string by dashes
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(" "); // Join the words with spaces
};
