import React, { useContext, useEffect, useState } from "react";
import "../../styles/Header.css";
import { Link, useNavigate } from "react-router-dom";
import logoWhite from "../../assets/images/logo/logo-white.png";
import logoColor from "../../assets/images/logo/logo-color.png";
import { FaChevronDown, FaRegUserCircle } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import Cookies from "js-cookie";
import myImage from "../../assets/images/icons/drop.png";

import {
  MdLogout,
  MdSearch,
  MdOutlineShoppingCart,
  MdOutlineEdit,
  MdOutlineLocationOn,
  MdOutlineCardGiftcard,
} from "react-icons/md";
import { Api_Url } from "../../constant/Url";
import { SearchProductApi } from "../../api/SearchApi";
import menuData from "../../Data/MenuData";
import { Sidenavbar } from "./Sidenavbar";
import { GetAllOrdersApi, GetUserProfile } from "../../api/UserApi";
import { getDecryptedCookie, isAuthenticated } from "../../utils/Utils";
import {
  FetchAllCategoriesWithSubCategories,
  getAllCategories,
} from "../../api/CategoryApi";
import MyContext from "../../context/myContext";
import { BsCart4 } from "react-icons/bs";

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const { userData, userProfile } = useContext(MyContext);
  const userDataCookies = getDecryptedCookie("UserData");
 
  const [categoryData, setCategoryData] = useState([]);
  const { fetchCartData,cartData} = useContext(MyContext);
  useEffect(()=>{
    fetchCartData()
  },[])
  useEffect(() => {
    const handleScroll = () => {
      if (isDesktop) {
        if (window.scrollY > 80) {
          setIsScrolled(true); // Show Header 2 when scrolled
        } else {
          setIsScrolled(false); // Show Header 1 when at the top
        }
      }
    };

    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  const [orderData, setOrderData] = useState([]);

  const handleOrdersApi = async () => {
    const userData = getDecryptedCookie("UserData");
    const token = userData?.token;
    const userId = userData?.userId;

    const data = {
      userId: userId,
    };

    try {
      const response = await GetAllOrdersApi(data);

      if (response?.status === "success") {
        setOrderData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Search Function

  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const placeholders = [
    "Search for Water Purifier",
    "Search for Vacuum Cleaner",
    "Search for Water Dispenser",
  ];
  const typingSpeed = 100; // Typing speed in milliseconds
  const pauseTime = 2000; // Pause between phrases

  const [displayText, setDisplayText] = useState(""); // Current typing text
  const [index, setIndex] = useState(0); // Current placeholder index
  const [charIndex, setCharIndex] = useState(0); // Current character index
  const [isDeleting, setIsDeleting] = useState(false); // Typing or deleting

  useEffect(() => {
    const typeEffect = () => {
      const currentPlaceholder = placeholders[index];

      if (!isDeleting) {
        // Typing effect
        setDisplayText((prev) => currentPlaceholder.slice(0, prev.length + 1));

        if (displayText === currentPlaceholder) {
          // Pause before deleting
          setTimeout(() => setIsDeleting(true), pauseTime);
        }
      } else {
        // Deleting effect
        setDisplayText((prev) => currentPlaceholder.slice(0, prev.length - 1));

        if (displayText === "") {
          // Move to the next placeholder
          setIsDeleting(false);
          setIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
        }
      }
    };

    const timer = setTimeout(
      typeEffect,
      isDeleting ? typingSpeed / 2 : typingSpeed
    );

    return () => clearTimeout(timer);
  }, [displayText, isDeleting, index, placeholders]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setQuery(value);

    if (value) {
      try {
        const response = await SearchProductApi({ productName: value });
        setSearchResults(response.data);
        setShowDropdown(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShowDropdown(false);
      setSearchResults([]);
    }
  };

  // Close dropdown when clicking outside

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".search_container")) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    try {
      // Remove user cookie
      Cookies.remove("UserData");

      // Clear any local/session storage
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
      // Reset user state
      if (userProfile) userProfile();

      // Navigate to login page
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error.message);
    }
  };

  // Get User Profile

  // Fetch All Categories

  const [HeadersMenuData, setHeadersMenuData] = useState([]);
 
// Fetch all categories

  const fetchCategories = async () => {
    try {
      const response = await FetchAllCategoriesWithSubCategories(); // Replace with your API endpoint

      // Ensure response.data is available and structured properly
      if (response?.data && Array.isArray(response.data)) {
        const categories = response.data.map((category) => ({
          title: category.CategoryName,
          categoryNameSlug: category.CategoryNameSlug,
          items: category.subcategories.map((sub) => ({
            name: sub.SubCategoryName,
            path: `/${category.CategoryNameSlug}/${sub.SubCategoryNameSlug}`,
          })),
        }));

        // Only set the state if categories is not empty
        if (categories.length > 0) {
          setHeadersMenuData(categories);
        } else {
          console.warn("No categories found in the response");
        }
      } else {
        console.error("Invalid response data format:", response);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
    userProfile();
    handleOrdersApi();
  }, []);

  return (
    <>
      {(!isScrolled || !isDesktop) && (
        <div className="header-container">
          <div className="phone_view_icons_div mobile-view py-2">
            <div className="d-flex justify-content-between">
              <Link to="/">
                <img
                  src={logoWhite}
                  className="img-fluid"
                  alt=""
                  style={{ width: "200px" }}
                />
              </Link>
              <Link className="text-white"  to="/cart">
              
              
              
                               
              {isAuthenticated && 
              <div className="menu-item-wrapper">
    <a className="header_cart_btn2" href="#">
  <button type="button" className="  position-relative">
  <BsCart4/>
<span className="badge rounded-pill-mobile fs-2">
{cartData?.length}
<span class="visually-hidden">unread messages</span>
</span>
</button>
</a>
  </div>}
             
           
</Link>
             

              <Link
                to="#sidenavbar"
                className="offcanvas_btn text-white"
                data-bs-toggle="offcanvas"
                aria-controls="offcanvasScrolling"
              >
                <GiHamburgerMenu />
              </Link>
            </div>
            <div className="pt-2">
              <div
                className="search_container w-100"
                style={{ position: "relative" }}
              >
                <input
                  placeholder={displayText}
                  value={query}
                  onChange={handleInputChange}
                  onFocus={() => setShowDropdown(true)}
                  type="search"
                  className="search_field w-100"
                />

                <div className="search_div">
                  <MdSearch />
                </div>

                {showDropdown && (
                  <div className="search_container_dropdown">
                    {searchResults.length > 0 ? (
                      searchResults.map((product, index) => (
                        <div
                          key={index}
                          className="dropdown_item"
                          onClick={() =>
                            navigate(
                              `/${product?.CategoryNameSlug}/${product?.SubCategoryNameSlug}/product-details/${product._id}`
                            )
                          }
                        >
                          <div className="py-2 d-flex align-items-center">
                            <div>
                              <div>
                                <img
                                  src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                  alt={product?.ProductName}
                                  className="img-fluid"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                            </div>

                            <div className="px-2">
                              <div style={{ fontWeight: "600" }}>
                                {product.ProductName}
                              </div>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "green",
                                }}
                              >
                                {`₹ ${parseInt(
                                  product?.PriceAfterDiscount
                                ).toLocaleString()}`}{" "}
                                /-
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="dropdown-item">
                        <div className="dropdown-item">
                          {HeadersMenuData?.map((ele, index) => (
                            <div>
                              <Link to={ele?.CategoryNameSlug}>
                                ({index + 1}){""}
                                {ele?.title}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container desktop-view">
            <div className="mobile-view">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="w-100"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  placeholder="Search for products"
                  value={query}
                  onChange={handleInputChange}
                  onFocus={() => setShowDropdown(true)}
                />

                {/* Dropdown menu */}
                {showDropdown && (
                  <div className="search_container_dropdown">
                    {searchResults.length > 0 ? (
                      searchResults.map((product, index) => (
                        <div
                          key={index}
                          className="dropdown_item"
                          onClick={() =>
                            navigate(
                              `/${product?.CategoryNameSlug}/${product?.SubCategoryNameSlug}/product-details/${product._id}`
                            )
                          }
                        >
                          <div className="py-2 d-flex align-items-center">
                            <div>
                              <div>
                                <img
                                  src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                  alt={product?.ProductName}
                                  className="img-fluid"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                            </div>

                            <div className="px-2">
                              <div style={{ fontWeight: "600" }}>
                                {product.ProductName}
                              </div>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "green",
                                }}
                              >
                                {`₹ ${parseInt(
                                  product?.PriceAfterDiscount
                                ).toLocaleString()}`}{" "}
                                /-
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="dropdown-item">
                        <div className="dropdown-item">
                          {HeadersMenuData?.map((ele, index) => (
                            <div>
                              <Link to={ele?.CategoryNameSlug}>
                                ({index + 1}){""}
                                {ele?.title}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="row d-flex justify-content-end ">
              <div className="col-md-12 p-0 d-flex justify-content-between align-items-center">
                <div className="flex-fill mx-1 text-center">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={logoWhite}
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className="flex-fill mx-1 text-center "
                  style={{ width: "40%" }}
                >
                  <div
                    className="search_container w-100"
                    style={{ position: "relative" }}
                  >
                    <input
                      placeholder={displayText}
                      value={query}
                      onChange={handleInputChange}
                      onFocus={() => setShowDropdown(true)}
                      type="search"
                      className="search_field w-100"
                    />

                    <div className="search_div">
                      <MdSearch />
                    </div>

                    {showDropdown && (
                      <div className="search_container_dropdown">
                        {searchResults.length > 0 ? (
                          searchResults.map((product, index) => (
                            <div
                              key={index}
                              className="dropdown_item"
                              onClick={() =>
                                navigate(
                                  `/${product?.CategoryNameSlug}/${product?.SubCategoryNameSlug}/product-details/${product._id}`
                                )
                              }
                            >
                              <div className="py-2 d-flex align-items-center">
                                <div>
                                  <div>
                                    <img
                                      src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                      alt={product?.ProductName}
                                      className="img-fluid"
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        border: "1px solid #ccc",
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="px-2">
                                  <div style={{ fontWeight: "600" }}>
                                    {product.ProductName}
                                  </div>
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "green",
                                    }}
                                  >
                                    {`₹ ${parseInt(
                                      product?.PriceAfterDiscount
                                    ).toLocaleString()}`}{" "}
                                    /-
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="dropdown-item">
                            <div className="dropdown-item">
                              {HeadersMenuData?.map((ele, index) => (
                                <div>
                                  <Link to={ele?.CategoryNameSlug}>
                                    ({index + 1}){""}
                                    {ele?.title}
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="menu-item-wrapper">
                      <div className="menu-item">
                        Our Categories
                        <FaChevronDown className="dropdown__arrow" />
                      </div>

                      <div className="dropdown-content">
                        <div className="dropdown-inner container">
                          <div className="row">
                            {HeadersMenuData.map((menu, index) => (
                              <div key={index} className="col-md-3 mb-3">
                                <div className="dropdown_items_heading">
                                  <a href={menu?.categoryNameSlug}>
                                    {menu?.title}
                                  </a>
                                </div>
                                <ul>
                                  {menu.items.map((item, subIndex) => (
                                    <a href={item.path} key={subIndex}>
                                      <li>
                                        <img src={myImage} alt="" /> {item.name}
                                      </li>
                                    </a>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="menu-item-wrapper">
                      <div className="menu-item">
                        Our Shop
                        <FaChevronDown className="dropdown__arrow" />
                      </div>

                      <ul className="shop__menu_dropdown dropdown__menu">
                        {menuData?.shops[0]?.items?.map((item, idx) => (
                          <li key={idx}>
                            <Link to={item.path} className="dropdown__link">
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="menu-item-wrapper">
                      {userData?.mobile ? (
                        <div>
                          <div className="menu-item-wrapper">
                            <div className="menu-item">
                              {userData?.name && userData?.mobile
                                ? "Hi! " + userData?.name.slice(0, 7) + "..."
                                : userData?.mobile}
                              <FaChevronDown className="dropdown__arrow" />
                            </div>

                            <div className="shop__menu_dropdown dropdown__menu">
                              <a href="/userprofile">
                                <div className="dropdown__link d-flex align-items-center">
                                  <div>
                                    {userData?.ProfilePhoto ? (
                                      <div className="userProfilePhoto">
                                        <img
                                          src={`${Api_Url}${userData?.ProfilePhoto}`}
                                          alt=""
                                          className=""
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <FaRegUserCircle />
                                      </div>
                                    )}
                                  </div>
                                  <div className="ps-1">
                                    {userData?.name && userData?.mobile
                                      ? userData?.name.slice(0, 7) + "..."
                                      : userData?.mobile}
                                  </div>
                                </div>
                              </a>

                              <a href="/editprofile">
                                <div className="dropdown__link">
                                  <MdOutlineEdit />
                                  <span className="ps-1">Edit Profile</span>
                                </div>
                              </a>

                              <a href="/orders">
                                <div className="dropdown__link">
                                  <MdOutlineCardGiftcard />
                                  <span className="ps-1">My Orders</span>
                                </div>
                              </a>

                              <a href="/cart">
                                <div className="dropdown__link">
                                  <MdOutlineShoppingCart />
                                  <span className="ps-1">Cart</span>{" "}
                                  <span>({cartData?.length})</span>
                                </div>
                              </a>

                              <a href="/editaddress">
                                <div className="dropdown__link">
                                  <MdOutlineLocationOn />
                                  <span className="ps-1">Addresses</span>
                                </div>
                              </a>

                              <a href="/gstnumber">
                                <div className="dropdown__link">
                                  <MdOutlineLocationOn />
                                  <span className="ps-1">GST No.</span>
                                </div>
                              </a>

                              <div
                                className="dropdown__link"
                                onClick={handleLogout}
                              >
                                <MdLogout />
                                <span className="ps-1">Logout</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Link to="/login">
                          <div className="menu-item">Login</div>
                        </Link>
                      )}
                    </div>
                    <div className="menu-item-wrapper">
     <a className="header_cart_btn" href="/cart">
        <button type="button" className="  position-relative">
        <BsCart4/>
          <span className="badge rounded-pill"> {cartData?.length}</span>
        </button>
     </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Sidenavbar />
        </div>
      )}

      {/* Header 2 */}
      {isScrolled && isDesktop && (
        <div className="header-container-white">
          <div className="phone_view_icons_div mobile-view">
            <div className="d-flex justify-content-between">
              <img
                src={logoWhite}
                className="img-fluid"
                alt=""
                style={{ width: "200px" }}
              />

              <Link
                to="#sidenavbar"
                className="offcanvas_btn text-white"
                data-bs-toggle="offcanvas"
                aria-controls="offcanvasScrolling"
              >
                <GiHamburgerMenu />
              </Link>
            </div>
          </div>

          <div className="container desktop-view">
            <div className="mobile-view">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="w-100"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  placeholder="Search for products"
                  value={query}
                  onChange={handleInputChange}
                  onFocus={() => setShowDropdown(true)}
                />

                {/* Dropdown menu */}
                {showDropdown && (
                  <div className="search_container_dropdown">
                    {searchResults.length > 0 ? (
                      searchResults.map((product, index) => (
                        <div
                          key={index}
                          className="dropdown_item"
                          onClick={() =>
                            navigate(
                              `/${product?.CategoryNameSlug}/${product?.SubCategoryNameSlug}/product-details/${product._id}`
                            )
                          }
                        >
                          <div className="py-2 d-flex align-items-center">
                            <div>
                              <div>
                                <img
                                  src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                  alt={product?.ProductName}
                                  className="img-fluid"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                            </div>

                            <div className="px-2">
                              <div style={{ fontWeight: "600" }}>
                                {product.ProductName}
                              </div>
                              <span
                                style={{
                                  fontWeight: "600",
                                  color: "green",
                                }}
                              >
                                {`₹ ${parseInt(
                                  product?.PriceAfterDiscount
                                ).toLocaleString()}`}{" "}
                                /-
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="dropdown-item">
                        <div className="dropdown-item">
                          {HeadersMenuData?.map((ele, index) => (
                            <div>
                              <Link to={ele?.CategoryNameSlug}>
                                ({index + 1}){""}
                                {ele?.title}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="row d-flex justify-content-end ">
              <div className="col-md-12 p-0 d-flex justify-content-between align-items-center">
                <div className="flex-fill mx-1 text-center">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={logoColor}
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="flex-fill mx-1" style={{ width: "40%" }}>
                  <div
                    className="search_container w-100"
                    style={{ position: "relative" }}
                  >
                    <input
                      placeholder={displayText}
                      value={query}
                      onChange={handleInputChange}
                      onFocus={() => setShowDropdown(true)}
                      type="search"
                      className="search_field w-100"
                    />

                    <div className="search_div">
                      <MdSearch />
                    </div>

                    {showDropdown && (
                      <div className="search_container_dropdown">
                        {searchResults.length > 0 ? (
                          searchResults.map((product, index) => (
                            <div
                              key={index}
                              className="dropdown_item"
                              onClick={() =>
                                navigate(
                                  `/${product?.CategoryNameSlug}/${product?.SubCategoryNameSlug}/product-details/${product._id}`
                                )
                              }
                            >
                              <div className="py-2 d-flex align-items-center">
                                <div>
                                  <div>
                                    <img
                                      src={`${Api_Url}${product?.ProductImage?.[0]}`}
                                      alt={product?.ProductName}
                                      className="img-fluid"
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        border: "1px solid #ccc",
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="px-2">
                                  <div style={{ fontWeight: "600" }}>
                                    {product.ProductName}
                                  </div>
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "green",
                                    }}
                                  >
                                    {`₹ ${parseInt(
                                      product?.PriceAfterDiscount
                                    ).toLocaleString()}`}{" "}
                                    /-
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="dropdown-item">
                            <div className="dropdown-item">
                              {HeadersMenuData?.map((ele, index) => (
                                <div>
                                  <Link to={ele?.CategoryNameSlug}>
                                    ({index + 1}){""}
                                    {ele?.title}
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="menu-item-wrapper">
                      <div className="menu-item">
                        Our Categories
                        <FaChevronDown className="dropdown__arrow" />
                      </div>
                      <div className="dropdown-content">
                        <div className="dropdown-inner container">
                          <div className="row">
                            {HeadersMenuData.map((menu, index) => (
                              <div key={index} className="col-md-3 mb-3">
                                <div className="dropdown_items_heading">
                                  <a href={menu?.categoryNameSlug}>
                                    {menu.title}
                                  </a>
                                </div>
                                <ul>
                                  {menu.items.map((item, subIndex) => (
                                    <a href={item.path} key={subIndex}>
                                      <li>
                                        <img src={myImage} alt="" /> {item.name}
                                      </li>
                                    </a>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="menu-item-wrapper">
                      <div className="menu-item">
                        Our Shop
                        <FaChevronDown className="dropdown__arrow" />
                      </div>

                      <ul className="shop__menu_dropdown dropdown__menu">
                        {menuData?.shops[0]?.items?.map((item, idx) => (
                          <li key={idx}>
                            <Link to={item.path} className="dropdown__link">
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div  className="menu-item-wrapper">
                      {userData?.mobile ? (
                        <div>
                          <div className="menu-item-wrapper">
                            <div className="menu-item">
                              {userData?.name && userData?.mobile
                                ? "Hi! " + userData?.name.slice(0, 7) + "..."
                                : userData?.mobile}
                              <FaChevronDown className="dropdown__arrow" />
                            </div>

                            <div className="shop__menu_dropdown dropdown__menu">
                              <a href="/userprofile">
                                <div className="dropdown__link d-flex align-items-center">
                                  <div>
                                    {userData?.ProfilePhoto ? (
                                      <div className="userProfilePhoto">
                                        <img
                                          src={`${Api_Url}${userData?.ProfilePhoto}`}
                                          alt=""
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <FaRegUserCircle />
                                      </div>
                                    )}
                                  </div>
                                  <div className="ps-1">
                                    {userData?.name && userData?.mobile
                                      ? userData?.name.slice(0, 7) + "..."
                                      : userData?.mobile}
                                  </div>
                                </div>
                              </a>

                              <a href="/editprofile">
                                <div className="dropdown__link">
                                  <MdOutlineEdit />
                                  <span className="ps-1">Edit Profile</span>
                                </div>
                              </a>

                              <a href="/orders">
                                <div className="dropdown__link">
                                  <MdOutlineCardGiftcard />
                                  <span className="ps-1">My Orders</span>
                                </div>
                              </a>

                              <a href="/cart">
                                <div className="dropdown__link">
                                  <MdOutlineShoppingCart />
                                  <span className="ps-1">Cart</span>{" "}
                                  <span>({cartData?.length})</span>
                                </div>
                              </a>

                              <a href="/editaddress">
                                <div className="dropdown__link">
                                  <MdOutlineLocationOn />
                                  <span className="ps-1">Addresses</span>
                                </div>
                              </a>

                              <div
                                className="dropdown__link"
                                onClick={handleLogout}
                              >
                                <MdLogout />
                                <span className="ps-1">Logout</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Link to="/login">
                          <div className="menu-item">Login</div>
                        </Link>
                      )}
                    </div>
                   <div className="menu-item-wrapper">
                      <a className="header_cart_btn2" href="/cart">
                    <button type="button" className="  position-relative">
                    <BsCart4/>
                <span className="badge rounded-pill">
  {cartData?.length}
    <span class="visually-hidden">unread messages</span>
  </span>
</button>
</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Sidenavbar />
        </div>
      )}
    </>
  );
};
