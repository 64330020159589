import React, { useEffect, useState } from "react";
import "../styles/EditProfile.css";
import Modal from "react-bootstrap/Modal";
import {
  AddNewAddress,
  GetAllUserAddresses,
  GetUserProfile,
  RemoveAddress,
  UpdateAddress,
  UpdatePrimaryAddress,
} from "../api/UserApi";
import { getDecryptedCookie } from "../utils/Utils";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import { indianStates } from "../Data/IndianStatesData";
import { Link } from "react-router-dom";

export const EditAddress = () => {
  const [UserAddressData, setUserAddressData] = useState([]);

  const [userData, setUserData] = useState(null);

  const FetchUserProfile = async () => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const token = userData?.token;
      const mobile = userData?.mobile; // Use mobile instead of userId

      if (!token) {
        console.log("Token not found in cookies");
        return;
      }

      // Call GetUserProfile API with mobile and token
      const res = await GetUserProfile(mobile, token);
      if (res?.status === "success") {
        setUserData(res.data);
      } else {
        console.error("Failed to fetch user user addresses", res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllUserAddresses = async () => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const token = userData?.token;
      const mobile = userData?.mobile;
      const requestedData = {
        mobile: mobile,
      };

      const res = await GetAllUserAddresses(requestedData);
      if (res?.status === "success") {
        setUserAddressData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [formInitialValues, setFormInitialValues] = useState();

  useEffect(() => {
    if (userData) {
      setFormInitialValues({
        mobile: userData?.mobile || "",
        address: {
          contactnumber: userData?.mobile || "",
          fullName: userData?.name || "",
          addressLine1: "",
          city: "",
          state: "",
          country: "India",
          postalCode: "",
        },
      });
    }
  }, [userData]);

  useEffect(() => {
    FetchAllUserAddresses();
    FetchUserProfile(); // Fetch the user profile when the component mounts
  }, []);

  const [showRemoveAddress, setShowRemoveAddress] = useState(false);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [showUpdateAddress, setShowUpdateAddress] = useState(false);

  const handleUpdateAddressClose = () => {
    setShowUpdateAddress(false);
    SetSelectedAddress(null);
  };
  const handleUpdateAddressShow = (address) => {
    setShowUpdateAddress(true);
    SetSelectedAddress(address);
    console.log(address);
  };

  const handleNewAddressClose = () => {
    setShowNewAddress(false);
  };

  const handleNewAddressShow = () => {
    setShowNewAddress(true);
  };

  const handleRemoveAddressClose = () => {
    setShowRemoveAddress(false);
    SetSelectedAddress("");
  };

  const [SelectedAddress, SetSelectedAddress] = useState(null);

  const initialValues = {
    mobile: userData?.mobile || "",
    address: {
      mobile: userData?.mobile || "",
      fullName: userData?.name,
      addressLine1: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    },
  };

  const validationSchema = Yup.object().shape({
    address: Yup.object().shape({
      contactnumber: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]{10}$/, "Invalid mobile number"),
      fullName: Yup.string().required("Full name is required"),
      addressLine1: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      country: Yup.string(),
      addressType: Yup.string().required("Please select an address type"),
      postalCode: Yup.string()
        .required("Postal code is required")
        .matches(/^\d{6}$/, "Postal code must be 6 digits"),
    }),
  });

  const handleRemoveAddressShow = async (data) => {
    setShowRemoveAddress(true);
    SetSelectedAddress(data);
    try {
      const userData = getDecryptedCookie("UserData");
      const token = userData?.token;
      const mobile = userData?.mobile;
      const addressId = data?._id;
      console.log(userData, token, mobile, addressId);
      const res = await RemoveAddress(mobile, data?._id, token);
      if (res?.status === "success") {
        FetchAllUserAddresses();
        toast.success(res?.message, { duration: 3000 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddAddressFormSubmit = async (values, actions) => {
    try {
      const userData = getDecryptedCookie("UserData"); // Decrypted user data
      const userMobile = userData?.mobile;

      const formData = {
        mobile: userMobile, // User's main mobile number
        address: values.address, // Use the `address` object directly
      };
      console.log(formData);

      const res = await AddNewAddress(formData, userMobile);

      if (res?.status === "success") {
        toast.success(res?.message, { duration: 3000 });
        FetchAllUserAddresses();
        actions.resetForm();
        handleNewAddressClose();
      } else {
        toast.error(res?.message || "Failed to add address.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleUpdateAddressFormSubmit = async (values, actions) => {
    try {
      // Merge values with SelectedAddress for final payload preparation
      const payload = {
        mobile: userData.mobile || userData.mobile, // Use existing mobile or form value
        addressId: SelectedAddress._id || values.addressId, // Use the ID from SelectedAddress
        updatedAddress: {
          contactnumber:
            values.address.contactnumber || SelectedAddress.contactnumber,
          fullName: values.address.fullName || SelectedAddress.fullName,
          addressLine1:
            values.address.addressLine1 || SelectedAddress.addressLine1,
          city: values.address.city || SelectedAddress.city,
          state: values.address.state || SelectedAddress.state,
          country: values.address.country || SelectedAddress.country,
          postalCode: values.address.postalCode || SelectedAddress.postalCode,
          addressType:
            values.address.addressType || SelectedAddress.addressType,
        },
      };

      // Debug the formatted payload
      console.log("Formatted Payload:", payload);

      // Populate FormData
      const formData = new FormData();
      formData.append("mobile", payload.mobile || "");
      formData.append("addressId", payload.addressId || "");
      formData.append("updatedAddress", JSON.stringify(payload.updatedAddress));

      // Debug the FormData
      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      const res = await UpdateAddress(payload);
      if (res?.status === "success") {
        toast.success(res?.message, { duration: 3000 });
        FetchAllUserAddresses();
      }

      actions.setSubmitting(false); // Reset form submission state
      handleUpdateAddressClose(); // Close the modal or form
    } catch (error) {
      console.error("Error updating address:", error);
      actions.setSubmitting(false); // Reset form submission state on error
    }
  };

  const handleSetPrimary = async (address) => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the user data from cookies
      const mobile = userData?.mobile; // Get mobile number
      const token = userData?.token;

      const requestedData = {
        mobile: mobile,
        addressId: address?._id,
      };

      const res = await UpdatePrimaryAddress(requestedData);
      if (res?.status === "success") {
        toast.success(res?.message, { duration: 3000 });
        FetchAllUserAddresses();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmRemoveAddress = async (address) => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the user data from cookies
      const mobile = userData?.mobile; // Get mobile number
      const token = userData?.token; // Get authorization token

      if (!mobile || !address?._id || !token) {
        toast.error("Required data missing");
        return;
      }

      const res = await RemoveAddress(mobile, address?._id, token); // Call the API to remove address

      if (res?.status === "success") {
        toast.success("Address removed successfully!", { duration: "3000" });
        handleRemoveAddressClose(); // Close the modal after removal
        UserAddressData(); // Refresh the address list after removal
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />

      {/* breadcrumb section start */}
      <section className="breadcrumb_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/* breadcrumb main div start */}
              <div className="breadcrumb_main_div">
                {/* breadcrumb nav div start */}
                <nav className="breadcrum_nav">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"> Manage Addresses</li>
                  </ol>
                </nav>
                {/* breadcrumb nav div end */}
              </div>
              {/* breadcrumb main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/*  */}

      {/* adreess section start */}
      <section className="address_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row justify-content-center">
            {/* col start */}
            <div className="col-lg-10 col-md-10 col-sm-12 col-12">
              {/* address card main div start */}
              <div className="address_card_main_div">
                {/* address user title and add address div sticky to start */}
                <div className="address_user_title_add_address_div_sticky">
                  {/* address title div start */}
                  <div className="address_title_div">
                    <h1>Manage Addresses</h1>
                  </div>
                  {/* address title div end */}

                  {/* add new address div start */}
                  <div className="add_new_address_div">
                    <Link
                      to="javascript:void(0);"
                      onClick={handleNewAddressShow}
                    >
                      {" "}
                      <span className="fa fa-plus"></span> add a new address
                    </Link>

                    {/* Modal Add New Address */}

                    <div className="editaddress_modal_div">
                      <Modal
                        show={showNewAddress}
                        onHide={handleNewAddressClose}
                        backdrop="static"
                        keyboard={false}
                        size="l"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Add a new Address</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div>
                            <Formik
                              initialValues={formInitialValues}
                              validationSchema={validationSchema}
                              onSubmit={handleAddAddressFormSubmit}
                              enableReinitialize
                            >
                              {({ isSubmitting }) => (
                                <Form>
                                  <div className="form_main_div">
                                    {/* row start */}
                                    <div className="row">
                                      {/* col start */}
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form_input_div">
                                          <label>Full Name</label>
                                          <Field
                                            type="text"
                                            name="address.fullName"
                                            placeholder="Enter your full name"
                                            className="Form_Field form-control"
                                          />
                                          <ErrorMessage
                                            name="address.fullName"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                      {/* col end */}

                                      {/* col start */}
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form_input_div">
                                          <label style={{ fontWeight: "600" }}>
                                            Mobile{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="address.contactnumber"
                                            className="p-0 px-2 m-0 form-control"
                                            placeholder="Mobile Number"
                                            maxLength={10}
                                            onInput={(e) =>
                                              (e.target.value =
                                                e.target.value.replace(
                                                  /[^0-9]/g,
                                                  ""
                                                ))
                                            }
                                          />
                                          <ErrorMessage
                                            name="address.contactnumber"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                      {/* col end */}

                                      {/* col start */}
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form_input_div">
                                          <label>Address Line 1</label>
                                          <Field
                                            type="text"
                                            name="address.addressLine1"
                                            placeholder="Enter your address"
                                            className="Form_Field form-control"
                                          />
                                          <ErrorMessage
                                            name="address.addressLine1"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                      {/* col end */}

                                      {/* col start */}
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form_input_div">
                                          <label>City</label>
                                          <Field
                                            type="text"
                                            name="address.city"
                                            placeholder="Enter your city"
                                            className="Form_Field form-control"
                                          />
                                          <ErrorMessage
                                            name="address.city"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                      {/* col end */}

                                      {/* col start */}
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form_input_div">
                                          <label>State</label>
                                          <Field
                                            as="select"
                                            name="address.state"
                                            className="Form_Field form-control"
                                          >
                                            <option value="">
                                              Select a State
                                            </option>
                                            {indianStates?.map((state) => (
                                              <option key={state} value={state}>
                                                {state}
                                              </option>
                                            ))}
                                          </Field>
                                          <ErrorMessage
                                            name="address.state"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                      {/* col end */}

                                      {/* col start */}
                                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form_input_div">
                                          <label>Country</label>
                                          <Field
                                            type="text"
                                            name="address.country" // Correct the `name` to match your form's schema
                                            value="India" // Prefill the value
                                            placeholder="Enter your country"
                                            className="Form_Field form-control"
                                            readOnly // Make the field non-editable
                                          />
                                          <ErrorMessage
                                            name="address.country"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                      {/* col end */}

                                      {/* col start */}
                                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="form_input_div">
                                          <label>Pincode</label>
                                          <Field
                                            type="text"
                                            name="address.postalCode"
                                            placeholder="Enter your Pincode"
                                            className="Form_Field form-control"
                                            maxLength={6} // Restrict length to 6
                                            onInput={(e) =>
                                              (e.target.value =
                                                e.target.value.replace(
                                                  /[^0-9]/g,
                                                  ""
                                                ))
                                            } // Allow only numbers
                                          />
                                          <ErrorMessage
                                            name="address.postalCode"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                      {/* col end */}

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="form_input_div">
          <label>Address Type</label>
          <div className="main_div_flrx_edit">
            <div className="radio_flex_div_main">
              <label htmlFor="flexRadioDefault13">Home</label>
              <Field id="flexRadioDefault13" type="radio" name="address.addressType" value="Home"/>
            </div>
            <div className="radio_flex_div_main">
            <label htmlFor="flexRadioDefault14">Office</label>
              <Field id="flexRadioDefault14" type="radio" name="address.addressType" value="Office"/>
            </div>
          </div>
          <ErrorMessage
            name="address.addressType"
            component="div"
            className="text-danger"
          />
        </div>
      </div>

                                      {/* col start */}
                                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="edit_profile_btn">
                                          <button
                                            type="submit"
                                            className="btn btn-md"
                                            disabled={isSubmitting}
                                          >
                                            Create Address
                                          </button>
                                        </div>
                                      </div>

                                      {/* col end */}
                                    </div>
                                    {/* row end */}
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>

                    {/* Modal Update Address */}

                    <div className="editaddress_modal_div">
                      <Modal
                        show={showUpdateAddress}
                        onHide={handleUpdateAddressClose}
                        backdrop="static"
                        keyboard={false}
                        size="l"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Update Address</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Formik
                            initialValues={{
                              address: {
                                fullName: SelectedAddress?.fullName || "",
                                contactnumber:
                                  SelectedAddress?.contactnumber || "",
                                addressLine1:
                                  SelectedAddress?.addressLine1 || "",
                                city: SelectedAddress?.city || "",
                                state: SelectedAddress?.state || "",
                                country: SelectedAddress?.country || "India",
                                postalCode: SelectedAddress?.postalCode || "",
                                addressType:
                                  SelectedAddress?.addressType || "Home",
                              },
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleUpdateAddressFormSubmit}
                            enableReinitialize
                          >
                            {({ isSubmitting }) => (
                              <Form>
                                {/* Reuse your existing fields here */}
                                <div className="form_main_div">
                                  <div className="row">
                                    {/* col start */}
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      <div className="form_input_div">
                                        <label>Full Name</label>
                                        <Field
                                          type="text"
                                          name="address.fullName"
                                          placeholder="Enter your full name"
                                          className="Form_Field form-control"
                                        />
                                        <ErrorMessage
                                          name="address.fullName"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                    {/* col end */}

                                    {/* col start */}
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      <div className="form_input_div">
                                        <label style={{ fontWeight: "600" }}>
                                          Mobile{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="address.contactnumber"
                                          className="p-0 px-2 m-0 form-control"
                                          placeholder="Mobile Number"
                                          maxLength={10}
                                          onInput={(e) =>
                                            (e.target.value =
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              ))
                                          }
                                        />
                                        <ErrorMessage
                                          name="address.contactnumber"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                    {/* col end */}

                                    {/* col start */}
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      <div className="form_input_div">
                                        <label>Address Line 1</label>
                                        <Field
                                          type="text"
                                          name="address.addressLine1"
                                          placeholder="Enter your address"
                                          className="Form_Field form-control"
                                        />
                                        <ErrorMessage
                                          name="address.addressLine1"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                    {/* col end */}

                                    {/* col start */}
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      <div className="form_input_div">
                                        <label>City</label>
                                        <Field
                                          type="text"
                                          name="address.city"
                                          placeholder="Enter your city"
                                          className="Form_Field form-control"
                                        />
                                        <ErrorMessage
                                          name="address.city"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                    {/* col end */}

                                    {/* col start */}
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      <div className="form_input_div">
                                        <label>State</label>
                                        <Field
                                          as="select"
                                          name="address.state"
                                          className="Form_Field form-control"
                                        >
                                          <option value="">
                                            Select a State
                                          </option>
                                          {indianStates?.map((state) => (
                                            <option key={state} value={state}>
                                              {state}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="address.state"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                    {/* col end */}

                                    {/* col start */}
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      <div className="form_input_div">
                                        <label>Country</label>
                                        <Field
                                          type="text"
                                          name="address.country" // Correct the `name` to match your form's schema
                                          value="India" // Prefill the value
                                          placeholder="Enter your country"
                                          className="Form_Field form-control"
                                          readOnly // Make the field non-editable
                                        />
                                        <ErrorMessage
                                          name="address.country"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                    {/* col end */}

                                    {/* col start */}
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      <div className="form_input_div">
                                        <label>Pincode</label>
                                        <Field
                                          type="text"
                                          name="address.postalCode"
                                          placeholder="Enter your Pincode"
                                          className="Form_Field form-control"
                                          maxLength={6} // Restrict length to 6
                                          onInput={(e) =>
                                            (e.target.value =
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              ))
                                          } // Allow only numbers
                                        />
                                        <ErrorMessage
                                          name="address.postalCode"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                    {/* col end */}

                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      <div className="form_input_div">
                                        <label>Address Type</label>
                                        <div className="me-3">
                                          <div className="d-flex align-items-center ">
                                            <span>Home</span>
                                            <Field
                                              type="radio"
                                              name="address.addressType"
                                              value="Home"
                                              className="me-2 w-100 bg-primary"
                                            />
                                          </div>
                                          <div className="d-flex align-items-center ">
                                            <span>Office</span>
                                            <Field
                                              type="radio"
                                              name="address.addressType"
                                              value="Office"
                                              className="me-2 w-100 bg-primary"
                                            />
                                          </div>
                                          {/* <div className="bg-success">
                                            <Field
                                              type="radio"
                                              name="address.addressType"
                                              value="Office"
                                              className="me-2"
                                            />
                                            Office
                                          </div> */}
                                        </div>
                                        <ErrorMessage
                                          name="address.addressType"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>

                                    {/* col start */}
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      <div className="edit_profile_btn">
                                        <button
                                          type="submit"
                                          className="btn btn-md"
                                          disabled={isSubmitting}
                                        >
                                          Update Address
                                        </button>
                                      </div>
                                    </div>

                                    {/* col end */}
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                  {/* add new address div end */}
                </div>
                {/* address user title and add address div sticky to end */}

                {/* user address list flex main div start */}

                {Array.isArray(UserAddressData?.allAddresses) &&
                UserAddressData.allAddresses.length > 0 ? (
                  UserAddressData.allAddresses.map((ele) => (
                    <div
                      className="user_address_list_flex_main_div"
                      key={ele._id}
                    >
                      {/*  address list main div start */}
                      <div className="address_list_main_div">
                        <span className="address_type">{ele?.addressType}</span>
                        {/* user address name and number div start */}
                        <div className="user_address_name_number_div">
                          <h2>
                            {ele.fullName} <b>{ele?.contactnumber}</b>
                          </h2>
                          <p>
                            {ele.addressLine1}, {ele.state} - {ele.postalCode}{" "}
                            <b>({ele.country})</b>
                          </p>
                        </div>
                        {/* user address name and number div end */}
                      </div>
                      {/*  address list main div end */}

                      {/* user edit dlt btn nav div start */}
                      <nav className="user_edit_dlt_nav">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item edit_btn">
                            {/* <a href="#">Edit</a> */}
                            <b onClick={() => handleUpdateAddressShow(ele)}>
                              Edit
                            </b>
                          </li>
                          <li
                            className="breadcrumb-item remove_btn"
                            aria-current="page"
                          >
                            <b onClick={() => handleRemoveAddressShow(ele)}>
                              Remove
                            </b>
                          </li>
                          <li
                            className="breadcrumb-item primary_btn"
                            aria-current="page"
                          >
                            {UserAddressData.primaryAddress._id === ele._id ? (
                              <span>Primary Delivery Address ✔</span>
                            ) : (
                              <b onClick={() => handleSetPrimary(ele)}>
                                Set as Primary
                              </b>
                            )}
                          </li>

                          {/* <div className="mt-3">
                              {UserAddressData?.primaryAddress?._id ===
                              ele._id ? (
                                <span className="text-success">
                                  This is your primary address
                                </span>
                              ) : (
                                <>
                                  <button
                                    onClick={() => handleSetPrimary(ele)}
                                    className="btn btn-outline-primary me-2"
                                  >
                                    Set as Primary
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleRemoveAddressShow(ele);
                                    }}
                                    className="btn btn-outline-danger"
                                  >
                                    Remove
                                  </button>
                                </>
                              )}
                            </div> */}
                        </ol>
                      </nav>
                      {/* user edit dlt btn nav div end */}
                    </div>
                  ))
                ) : (
                  <>
                    <h5 className="text-center">No Addresses Available</h5>
                  </>
                )}
                {/* user address list flex main div end */}
              </div>
              {/* address card main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* adreess section end */}
    </>
  );
};
