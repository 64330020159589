import { Api_Url } from "../constant/Url";

export const FetchAllBanners = async () => {
  try {
    const res = await fetch(`${Api_Url}/websitebanners/getallbanners`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: "An error occurred while fetching the Banners",
    };
  }
};

export const GetFilteredBanners = async (data) => {
  try {
    const params = new URLSearchParams({
      title: data?.title || "",
      titleSlug: data?.titleSlug || "",
      type: data?.type || "",
      platform: data?.platform || "",
      status: data?.status || "",
    });

    // Construct the API URL
    let url = `${Api_Url}/banner/getfilterbanner?${params.toString()}`;

    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    // Read the response body once
    const responseData = await res.json();

    return responseData; // Return the response data directly
  } catch (error) {
    console.error("Error fetching filtered products:", error);
    throw error;
  }
};
