import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { getDecryptedCookie, isAuthenticated } from "../utils/Utils";
import Table from "react-bootstrap/esm/Table";
import { IoTrashBinOutline } from "react-icons/io5";
import { CiCircleMinus } from "react-icons/ci";
import { CiCirclePlus } from "react-icons/ci";
import toast, { Toaster } from "react-hot-toast";
import { MdOutlineShoppingCart } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import { Api_Url } from "../constant/Url";
import "../styles/Cart.css";
import orderimg from "../assets/images/image/keelproduct4.jpeg";

import {
  CartProductCheckoutApi,
  
  RemoveCart,
  UpdateAddToCart,
} from "../api/CartApi";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MyContext from "../context/myContext";
import { GetAllUserAddresses } from "../api/UserApi";

export const Cart = () => {
  const { fetchCartData,cartData,handleDeleteCart,handleQuantityChange,loadings,responseData} = useContext(MyContext);
 console.log(cartData,'cartDatcartDatacartDatacartDatacartDataa');
  function preparePayload(inputData) {
    // Transform the input data into the desired payload format
    return {
      userId: inputData.userId,
      products: inputData.products.map((item) => ({
        productId: item.product._id,
        quantity: item.quantity,
      })),
    };
  }
  const navigate = useNavigate();

 
  const [quantity, setQuantity] = useState(0);
  const [particularItemQty, setParticularItemQty] = useState();

  const [loading, setLoading] = useState(true);
console.log(responseData,'responseDataresponseDataresponseDataresponseDataresponseData');
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  const [itemsPerPage] = useState(3); // Show 3 items per page
  const [totalPages, setTotalPages] = useState(1); // Store the total number of pages

  // Calculate the current items to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cartData.slice(indexOfFirstItem, indexOfLastItem);

 
  useEffect(() => {
    fetchCartData(currentPage, itemsPerPage);
  }, [currentPage]); // Only trigger when currentPage changes

  // Handle quantity changes


  // Handle deleting items from the cart


  // Handle checkout
  const HandleCheckout = async (cartData) => {
    const userId = getDecryptedCookie("UserData")?.userId;

    const reqSend = { userId, products: cartData };
    const reqSend2 = preparePayload(reqSend);

    try {
      if (!isAuthenticated) {
      navigate("/login");
      }
      const response = await CartProductCheckoutApi(reqSend2);
      if (response?.status === "success") {
        toast.success(response?.message);
        navigate(`/checkout/${response?.data?.checkoutId}`);
      } else {
        // toast.error(response?.message || "Something went wrong!");
      }
    } catch (error) {
      console.error("Checkout Error:", error);
      alert("Something went wrong!");
    }
  };

  // Handle next page click
  const handleNextPage = () => {
    console.log("Next Page Clicked", currentPage, totalPages);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle previous page click
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [UserAddressData, setUserAddressData] = useState([]);
  const [totalAddresses, setTotalAddresses] = useState([]);
  const FetchAllUserAddresses = async () => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const token = userData?.token;
      const mobile = userData?.mobile;
      const requestedData = {
        mobile: mobile,
      };

      const res = await GetAllUserAddresses(requestedData);
      console.log(res, "resres");
      if (res?.status === "success") {
        setUserAddressData(res?.data?.primaryAddress);
        setTotalAddresses(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllUserAddresses();
  }, []);

  const getShopColor = (ShopName) => {
    const colors = {
      Keel: "rgb(34,179,84)",
      Aquafresh: "rgb(0,125,189)",
      "Grand Plus": "rgb(104,208,247)",
      "Royal Aquafresh": "rgb(254,0,0)",
    };

    // Default RGBA color if slug not found
    return colors[ShopName] || "rgba(0, 0, 0, 1)"; // Black as fallback
  };

  return (
    <>
    <Toaster />

    {/* <div className="cart_deliver_flex_div">
        <div className="cart_deliver_address"> */}

    {/* breadcrumb section start */}
    <section className="breadcrumb_section">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row">
          {/* col start */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            {/* breadcrumb main div start */}
            <div className="breadcrumb_main_div">
              {/* breadcrumb nav div start */}
              <nav className="breadcrum_nav">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    My Cart{" "}
                  </li>
                </ol>
              </nav>
              {/* breadcrumb nav div end */}
            </div>
            {/* breadcrumb main div end */}
          </div>
          {/* col end */}
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
    {/*  */}

    {/* cart section start */}
    <section className="cart_section">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="">
          {cartData?.length > 0 ? (
            <div className="row">
              {/* col start */}
              <div className="col-lg-7 col-md-6 col-sm-12 col-12">
                {/* cart main div start */}
                <div className="cart_main_div">
             

                  {/* cart deliver flex start */}
                {isAuthenticated &&  <div className="cart_deliver_flex_div">
                    {/* cart deliver address start */}
                    {totalAddresses?.length>0 && <div className="cart_deliver_address">
                      <p>
                        Deliver to:
                        {loadings ? (
                          <Skeleton height={20} width={150} />
                        ) : (
                          <b>
                            {UserAddressData?.fullName},
                            {UserAddressData?.postalCode}
                          </b>
                        )}
                        <span>
                          {loadings ? (
                            <Skeleton height={20} width={30} />
                          ) : (
                            <span>{UserAddressData?.addressType==null ?
                              "No Address Selected":UserAddressData?.addressType}</span>
                          )}
                        </span>
                      </p>
                      <h5>
                        {loadings ? (
                          <Skeleton height={20} width={200} />
                        ) : (
                          <div>
                            {UserAddressData?.addressLine1},
                            {UserAddressData?.city},{UserAddressData?.state}
                          </div>
                        )}
                      </h5>
                    </div>}
                    {/* cart deliver address end */}
                  </div>}
                  {/* cart deliver flex end */}

                  {cartData?.map((ele, index) => {
                    return (
                      <div
                        className="cart_products_list_main_div"
                        key={index}
                      >
                        {/* cart products flex list div start */}
                        <div className="cart_products_flex_list_div">
                          {/* cart products div start  */}
                          <div className="cart_products_img_box">
                            <img
                              src={
                                ele?.product?.ProductImage?.[0]
                                  ? `${Api_Url}${ele.product.ProductImage[0]}`
                                  : "/path-to-default-image.png"
                              }
                              alt={
                                ele?.product?.ProductName || "Product Image"
                              }
                              className="img-fluid"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  `/${ele?.product?.CategoryNameSlug}/${ele?.product?.SubCategoryNameSlug}/product-details/${ele?.product._id}`
                                )
                              }
                            />
                            {/* cart increment div start */}
                            <div className="cart_increment_div">
                              <button
                                 onClick={() =>
                                  handleQuantityChange(
                                    ele?.product?._id,
                                    "decrease"
                                  )
                                }
                                className="increment_btn"
                              >
                                -
                              </button>
                              <span className="count_number_div">
                                {ele?.quantity || 0}
                              </span>
                              <button
                                onClick={() =>
                                  handleQuantityChange(
                                    ele?.product?._id,
                                    "increase"
                                  )
                                }
                                className="decrement_btn"
                              >
                                +
                              </button>
                            </div>
                            {/* cart increment div end */}
                          </div>
                          {/* cart products div end  */}

                          {/* cart products description div start*/}
                          <div className="cart_products_description_div">
                            <p
                              onClick={() =>
                                navigate(
                                  `/${ele?.product?.CategoryNameSlug}/${ele?.product?.SubCategoryNameSlug}/product-details/${ele?.product._id}`
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {ele?.product?.ProductName || "N/A"}
                            </p>
                            <p className="cart_product_brand_name">
                              Brand:{" "}
                              <b
                                style={{
                                  color: getShopColor(ele?.product?.ShopName),
                                }}
                              >
                                {ele?.product?.ShopName}
                              </b>
                            </p>
                            <h4>
                              <span className="cart_discount_price">
                                {" "}
                                ₹
                                {parseInt(
                                  ele?.product?.TotalPriceWithTax
                                ).toLocaleString()}
                              </span>
                              <b className="cart_price">
                                ₹{" "}
                                {parseInt(
                                  ele?.product?.PriceAfterDiscount
                                ).toLocaleString()}
                              </b>
                              <span className="cart_offer">
                                {ele?.product?.DiscountPercentage}% Off
                              </span>
                            </h4>
                            <h5>
                              Delivery by Tue 01 Feb 2025 | <span>Free</span>
                            </h5>

                            {/* cart remove btn div start */}
                            <div className="cart_remove_btn_div">
                              <button
                                className="remove_btn"
                                onClick={() =>
                                  handleDeleteCart(ele?.product?._id)
                                }
                              >
                                Remove
                              </button>
                            </div>
                            {/* cart remove btn div end */}
                          </div>
                          {/* cart products description div end*/}
                        </div>
                        {/* cart products flex list div end */}
                      </div>
                    );
                  })}

                  {/* cart products list main div end */}
                </div>
                {/* cart main div end */}
              </div>
              {/* col end */}

              {/* col start */}
              <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                {/* cart products Price details div start */}
                <div className="cart_product_price_details_main_div">
                  <h1>Price details</h1>
                  {/* cart product price flex div start */}
                  <div className="cart_product_price_flex_div">
                    {/* product item div start */}
                    <div className="cart_product_item_div">
                      <p>Price ({cartData?.length} items)</p>
                      <b>
                        ₹{" "}
                        {parseInt(responseData?.summary?.totalPriceAfterDiscount).toLocaleString()}
                      </b>
                    </div>
                    {/* product item div end */}

                    {/* product item div start */}
                    <div className="cart_product_item_div">
                      <p>Discount</p>
                      <b className="discount_price">− ₹0.00</b>
                    </div>
                    {/* product item div end */}

                    {/* product item div start */}
                    <div className="cart_product_item_div">
                      <p>Coupons for you</p>
                      <b className="discount_price">− ₹0.00</b>
                    </div>
                    {/* product item div end */}

                    {/* product item div start */}
                    <div className="cart_product_item_div">
                      <p>Platform Fee </p>
                      <b>₹0</b>
                    </div>
                    {/* product item div end */}

                    {/* product item div start */}
                    <div className="cart_product_item_div">
                      <p>Delivery Charges</p>
                      <b>
                        {" "}
                        <span className="deliver_charge">₹80</span>{" "}
                        <span className="delivery_free_charge">Free</span>
                      </b>
                    </div>
                    {/* product item div end */}

                    {/* product item div start */}
                    <div className="cart_product_totalprice_div">
                      <h3>Total Amount</h3>
                      <h5>
                        ₹{" "}
                        {parseInt(responseData?.summary?.totalPriceAfterDiscount).toLocaleString()}
                      </h5>
                    </div>
                    {/* product item div end */}

                    {/* product item div start */}
                    <div className="cart_product_saveprice_div">
                      <h3>You will save ₹80 on this order</h3>
                    </div>
                    {/* product item div end */}
                  </div>
                  {/* cart product price flex div end */}

                  {/* place order cart div start */}
                  <div className="cart_place_order_div">
                    <div className="place_order_btn_div d-grid">
                      <button className="place_order_btn"   onClick={() => HandleCheckout(cartData)}>place order</button>
                    </div>
                  </div>
                  {/* place order cart div end */}
                </div>
                {/* cart products Price details div end */}
              </div>
            </div>
          ) : (
            <div>
              <div className="container">
                <div className="row d-flex justify-content-center fs-1">
                  <div className="col-md-5 Section_Container">
                    <div className="text-center py-3">
                      <MdOutlineShoppingCart
                        className="text-danger"
                        style={{ fontSize: "100px" }}
                      />
                    </div>
                    <p className="text-center m-0">Your Cart is Empty</p>
                    <p className="text-center">
                      <a href="/" className="fs-5">
                        <span className="pe-1">Continue Shopping</span>
                        <FaArrowRightLong />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* col end */}
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
    {/* cart section end */}
  </>
  );
};
