import React, { useEffect, useState } from "react";
import { getDecryptedCookie } from "../utils/Utils";
import { Link } from "react-router-dom";
import { GetUserProfile } from "../api/UserApi";
import userprofile from "../assets/images/image/userprofile.jpg";
import "../styles/userprofile.css";
import { Api_Url } from "../constant/Url";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const FetchUserProfile = async () => {
    try {
      const userData = getDecryptedCookie("UserData");
      const token = userData?.token;
      const mobile = userData?.mobile;

      if (!token) {
        console.log("Token not found in cookies");
        return;
      }

      const res = await GetUserProfile(mobile, token);

      if (res?.status === "success") {
        setUserData(res.data);
      } else {
        console.error("Failed to fetch user profile", res?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    FetchUserProfile();
  }, []);

  return (
    <>
      {/* breadcrumb section start */}
      <section className="breadcrumb_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/* breadcrumb main div start */}
              <div className="breadcrumb_main_div">
                {/* breadcrumb nav div start */}
                <nav className="breadcrum_nav">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {" "}
                      User Profile
                    </li>
                  </ol>
                </nav>
                {/* breadcrumb nav div end */}
              </div>
              {/* breadcrumb main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* end */}

      {/* user profile section start */}
      <section className="user_profile_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row justify-content-center">
            {/* col start */}
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              {/* userprofile main div start */}
              <div className="userprofile_main_div_relative">
                {/* userprofile flex div start */}
                <div className="userprofile_flex_main_div">
                  {/* userprofile image div start */}
                  <div className="userprofile_image_main_div">
                    {/* user image box start */}

                    {loading ? (
                      <div className="user_image_box">
                        <img
                          src={userprofile}
                          alt="User Profile"
                          className="img-fluid"
                        />

                        <Skeleton
                          height={50}
                          width={150}
                          style={{ margin: "10px auto", display: "block" }}
                        />

                        <div>
                          <Link
                            to="/editprofile"
                            className="userprofile_edit_btn_div"
                          >
                            {" "}
                            <span className="fa fa-edit"></span> Edit
                          </Link>
                        </div>
                        {/* userprofile edit btn div end */}
                      </div>
                    ) : (
                      <div className="user_image_box">
                        <img
                          src={
                            userData?.ProfilePhoto
                              ? `${Api_Url}${userData?.ProfilePhoto}`
                              : userprofile
                          }
                          alt="User Profile"
                          className="img-fluid"
                        />

                        <h2>
                          {loading ? <Skeleton width={10} /> : userData?.name}
                        </h2>

                        <div>
                          <Link
                            to="/editprofile"
                            className="userprofile_edit_btn_div"
                          >
                            {" "}
                            <span className="fa fa-edit"></span> Edit
                          </Link>
                        </div>
                        {/* userprofile edit btn div end */}
                      </div>
                    )}

                    {/* user image box end */}
                  </div>
                  {/* userprofile image div end */}

                  {/* user profile table start  */}
                  <div className="user_profile_details_flex_table">
                    {/* user profile table row start */}
                    <div className="user_profile_table_row">
                      <h2>Name:</h2>
                      <h3>
                        {loading ? (
                          <Skeleton height={20} width={150} />
                        ) : (
                          <div>{userData?.name}</div>
                        )}
                      </h3>
                    </div>
                    {/* user profile table row end */}

                    {/* user profile table row start */}
                    <div className="user_profile_table_row">
                      <h2>Email ID:</h2>
                      <h3>
                        {loading ? (
                          <Skeleton height={20} width={150} />
                        ) : (
                          <div>{userData?.email}</div>
                        )}
                      </h3>
                    </div>
                    {/* user profile table row end */}

                    {/* user profile table row start */}
                    <div className="user_profile_table_row">
                      <h2>Mobile No:</h2>
                      <h3>
                        {loading ? (
                          <Skeleton height={20} width={150} />
                        ) : (
                          <div>+91-{userData?.mobile}</div>
                        )}
                      </h3>
                    </div>
                    {/* user profile table row end */}

                    {/* user profile table row start */}
                    <div className="user_profile_table_row">
                      <h2>Delivery Address:</h2>
                      <h3>
                        {loading ? (
                          <Skeleton height={20} width={150} />
                        ) : (
                          <div>
                            {userData?.addresses?.length > 0 ? (
                              userData.addresses.map((address) =>
                                address._id === userData.primaryAddress ? (
                                  <div key={address._id}>
                                    <span>{address.addressLine1},</span>
                                    <span>
                                      {address.city}, {address.state},
                                      {address.country} - {address.postalCode}
                                    </span>
                                  </div>
                                ) : null
                              )
                            ) : (
                              <p>No address available</p>
                            )}
                          </div>
                        )}
                      </h3>
                    </div>
                    {/* user profile table row end */}

                    {/* user profile table row start */}
                    <div className="user_profile_table_row">
                      <h2>GST No:</h2>

                      <h3>
                        {loading ? (
                          <Skeleton height={20} width={150} />
                        ) : (
                          <div>
                            {userData?.gstnumber?.length > 0 ? (
                              <div>{userData.gstnumber.at(-1)}</div>
                            ) : (
                              <p>No GST available</p>
                            )}
                          </div>
                        )}
                      </h3>
                    </div>
                    {/* user profile table row end */}
                  </div>
                  {/* user profile table end */}
                </div>
                {/* userprofile flex div end */}
              </div>
              {/* userprofile main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* user profile section end */}
    </>
  );
};
