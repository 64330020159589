import React, { useEffect, useState } from "react";
import { MdOutlineShoppingCart } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import { getDecryptedCookie } from "../utils/Utils";
import { AddToCart, GetFilteredProducts } from "../api/ProductsApi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Api_Url } from "../constant/Url";
import { FetchAllSubCategoriesWithCategories } from "../api/CategoryApi";
import { FetchAllOurBrands } from "../api/OurBrandsApi";
import "../styles/WaterPurifiers.css";

export const WaterPurifier = () => {
  const location=useLocation();
  const { CategoryNameSlug, SubCategoryNameSlug } = useParams();
  const [subCategories, setSubCategories] = useState([]);
  const [ProductsData, SetProductsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const getFirstPathSegment = () => {
    const segments = location.pathname.split("/").filter(Boolean);
    return segments || "";
  };

  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;
  // Filter State
  const [priceMin, setPriceMin] = useState(1000); // Set initial min price
  const [priceMax, setPriceMax] = useState(100000); // Set initial max price
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'
  const [selectedShops, setSelectedShops] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [shops, setShops] = useState([]);

  const getSubCategoryName = async () => {
    const data = {
      CategoryNameSlug: CategoryNameSlug,
    };
    try {
      const response = await FetchAllSubCategoriesWithCategories(data);
      if (response.status === "success") {
        setSubCategories(
          response?.data?.map((item) => item?.SubCategoryNameSlug)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFilteredProducts = async () => {
    setLoading(true);
    try {
      const decodedCategoryName = CategoryNameSlug || "";
      const decodedSubCategoryNames = selectedSubCategories || "";

      const filterData = {
        CategoryNameSlug: decodedCategoryName || "",
        SubCategoryNameSlug: decodedSubCategoryNames || "",
        priceMin: priceMin || "",
        priceMax: priceMax || "",
        ShopNameSlug: selectedShops.join(",") || "",
        order: sortOrder || "asc",
      };

      const res = await GetFilteredProducts(filterData);

      if (res?.status === "success") {
        SetProductsData(res?.data);
      } else {
        console.error("Failed to fetch products:", res);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const handleShopChange = (shop) => {
    setSelectedShops((prev) =>
      prev.includes(shop) ? prev.filter((s) => s !== shop) : [...prev, shop]
    );
  };

  const handleSelectAllShops = (e) => {
    if (e.target.checked) {
      setSelectedShops(shops);
    } else {
      setSelectedShops([]);
    }
  };

  const handleSubCategoryChange = (subCategory) => {
    setSelectedSubCategories((prev) =>
      prev.includes(subCategory)
        ? prev.filter((s) => s !== subCategory)
        : [...prev, subCategory]
    );
  };

  const handleSelectAllSubCategories = (e) => {
    if (e.target.checked) {
      setSelectedSubCategories(subCategories);
    } else {
      setSelectedSubCategories([]);
    }
  };

  // Handle Slider Change
  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    if (name === "min") {
      setPriceMin(parseInt(value));
    } else if (name === "max") {
      setPriceMax(parseInt(value));
    }
  };

  const HandleAddToCart = async (productData) => {
    try {
      if (userId === undefined) {
        // //  navigate("/login");;
      }

      const data = {
        productId: productData._id,
        quantity: 1,
        userId: userId,
      };

      const res = await AddToCart(data, token);

      if (res?.status === "success") {
        toast.success(res?.message);
        window.location.reload();
      } else {
        toast.error("Error in Add to Product");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewProduct = (productId) => {
    navigate(
      `/${productId?.CategoryNameSlug}/${productId?.SubCategoryNameSlug}/product-details/${productId?._id}`
    );
  };

  const fetchShops = async () => {
    try {
      const res = await FetchAllOurBrands(); // Fetch brands from the API
      if (res.status === "success" && res.data) {
        // Extract the names of the shops and set it in the state
        const shopNames = res.data.map((brand) => brand.OurBrandsNameSlug);
        setShops(shopNames);
      }
    } catch (error) {
      console.error("Error fetching shops:", error);
    }
  };

  const getShopColor = (shopNameSlug) => {
    const colors = {
      keel: "rgb(34,179,84)",
      aquafresh: "rgb(0,125,189)",
      "grand-plus": "rgb(104,208,247)",
      "royal-aquafresh": "rgb(254,0,0)",
    };

    // Default RGBA color if slug not found
    return colors[shopNameSlug] || "rgba(0, 0, 0, 1)"; // Black as fallback
  };

  useEffect(() => {
    fetchShops();
    getSubCategoryName();
  }, []);

  useEffect(() => {
    fetchFilteredProducts();
  }, [
    CategoryNameSlug,
    SubCategoryNameSlug,
    priceMin,
    priceMax,
    selectedShops,
    sortOrder,
    selectedSubCategories,
  ]);

  return (
    <>
    {/* water purifire section start */}
    {/* breadcrumb section start */}
<section className="breadcrumb_section">
  {/* container start */}
 <div className="container">
  {/* row start */}
 <div className="row">
  {/* col start */}
  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
    {/* breadcrumb main div start */}
    <div className="breadcrumb_main_div">
    {/* breadcrumb nav div start */}
    <nav className="breadcrum_nav">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
    <li className="breadcrumb-item active" aria-current="page">{window.location.pathname}</li>
  </ol>
</nav>
    {/* breadcrumb nav div end */}
    </div>
    {/* breadcrumb main div end */}

  </div>
  {/* col end */}
 </div>
  {/* row end */}
 </div>
  {/* container end */}
</section>
{/*  */}
    <section className="water_purifire_section">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row">
          {/* col start */}
          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
            {/* filter main div left side start */}
            <div className="filler_main_div_left_side">
              {/* filter title div start */}
                <div className="filter_title_div">
                <h5>Filter by Price</h5>
                </div>
              {/* filter title div end */}
              {/* price slider div start */}
              <div className="price_slider_main_div">
                {/* vales start */}
                    <div className="price_values">
                      ₹ {priceMin} - ₹ {priceMax}
                    </div>
                    {/* value end */}

                    {/* price input start */}
                     <input type="range" name="min" min="1000" max="100000" value={priceMin}
                      onChange={handlePriceChange} className="price_slider_min"/>
                      
                     <input type="range" name="max" min="1000"max="100000" value={priceMax}
                      onChange={handlePriceChange} className="price_slider_max"/>
                    {/* price input end */}
                 </div>
              {/* price slider div end */}

              {/* filter by price  dropdown start*/}
              <div className="filter_by_price_div">
                {/* filter by price title start */}
                <div className="filter_by_price_title_div">
                <h5>Filter by Price</h5>
                </div>
                {/* filter by price title end */}
                <select className="form-select" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} >
                  <option value="asc">Low to High</option>
                  <option value="desc">High to Low</option>
                </select>
              </div>
              {/* filter by price  dropdown end*/}

              {/* filter by subcategory div start */}
              <div className="filter_by_subcategory_div">
                {/* filter by subcategory title start */}
                <div className="filter_by_subcategory_title_div">
                  <h5>Filter by Subcategory</h5>
                </div>
                {/* filter by subcategory title end */}

                {/* filter by subcategory list div start */}
                <div className="filter_by_subcategory_list_div">
                <input type="checkbox" onChange={handleSelectAllSubCategories} />{" "}
                All Subcategories
                  {subCategories.map((subCategory) => (
                    <div className="category_list_div" key={subCategory}>
                      <input type="checkbox" checked={selectedSubCategories.includes(subCategory)}onChange={() => handleSubCategoryChange(subCategory)}/>{" "} {subCategory}
                    </div>
                  ))}
                </div>
                {/* filter by subcategory list div end */}
              </div>
              {/* filter by subcategory div end */}

              {/* filter by shop name div start */}
              <div className="filter_by_shop_name_div">
                {/* filter by shop name title start */}
                <div className="filter_by_shop_name_title_div">
                <h5>Filter by Shop Name</h5>
                </div>
                {/* filter by shop name title end */}

                {/* filter by shop name list div start */}
                <div className="filter_by_shop_name_list_div">
                <input type="checkbox" onChange={handleSelectAllShops} /> All Shops
                {shops.map((shop) => (
                    <div className="category_list_div" key={shop}>
                      <input type="checkbox" checked={selectedShops.includes(shop)} onChange={() => handleShopChange(shop)} />{" "}
                      {shop}
                    </div>
                  ))}
                </div>
                {/* filter by shop name list div end */}

              </div>
              {/* filter by shop name div end */}

            </div>
            {/* filter main div left side end */}
          </div>
          {/* col end */}

            {/* col start */}
            <div className="col-lg-9 col-md-12 col-sm-12 col-12">

              {/* filter Products main div right side start */}
              <div className="filter_products_right_side_main_div">

                {/* filter products title div start */}
                <div className="filter_products_title_flex_div">

                <div className="filter_products_title_div">
                 <h1>{getFirstPathSegment(window.location.pathname)}</h1>
                 <p>Total Products: {ProductsData.length}</p>
                </div>
 
                {/* filter icon div start */}
                <div className="filter_icon_div">
                  <Link to="#filterproductsoffcanvas" data-bs-toggle="offcanvas" aria-controls="offcanvasRight"  className="fa fa-filter"></Link>
                </div>
                {/* filter icon div end */}

                </div>
                {/* filter products title div end */}

                {/* nested main div start */}
                <div className="nested_main_div">
                  {/* nested row start */}
                  <div className="row">
                    {/* nested col start */}
                     {ProductsData?.map((product) => (
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6" key={product._id}>
                      {/* nested products main div start */}
                      <div className="products_nested_main_div" 
                      onClick={() => handleViewProduct(product)}>
                       {/* nested product image div start */}
                       <div className="nested_product_image_div">
                         <img src={`${Api_Url}${product.ProductImage[0]}`} alt={product.ProductName} className="img-fluid"/>
                         {/* mobile view save off products */}
                         <div className="mobile_view_save_off_products_div">
                         <b className="discount">Save {product.DiscountPercentage}%</b>
                         </div>
                         {/* mobile view save off products end*/}

                       </div>
                       {/* nested product image div end */}
                       {/* nested product details div start */}
                       <div className="nested_product_details_div">
                       <h2>
                       {product?.ProductName?.length > 20
                           ? product?.ProductName?.slice(0, 17) +
                             "...": product?.ProductName}</h2>
                        <p> {product?.ProductDescription?.length > 35
                            ? product?.ProductDescription.slice(0, 20) + "..."
                            : product?.ProductDescription}
                            </p>

                            {/* nested product price div start */}
                            <div className="nested_product_price_div">
                              <p>
                           <b className="price_products_nested"> ₹ {parseInt(product.PriceAfterDiscount).toLocaleString()}</b>
                          <span> ₹ {parseInt(product.TotalPriceWithTax).toLocaleString()}</span>
                          <b className="discount">Save {product.DiscountPercentage}%</b></p>
                            </div>
                            {/* nested product price div end */}
                            {/* nested brand name start */}
                            <div className="nested_brand_name_div">
                              <h4>Brand:</h4>
                              <h5 style={{color: getShopColor(product.ShopNameSlug),}}>{product.ShopName}</h5>
                            </div>
                            {/* nested brand name end */}
                       </div>
                       {/* nested product details div end */}

                       {/* nested product button div start */}
                       <div className="nested_product_button_div">
                        <button className="view-details">View Product</button>
                       <button className="add-to-cart" onClick={(e) => {e.stopPropagation();
                           HandleAddToCart(product); }}>
                          <MdOutlineShoppingCart />
                       </button>
                       </div>
                       {/* nested product button div end */}

                      </div>
                      {/* nested products main div end */}
                    </div>
                     ))}
                    {/* nested col end */}
                  </div>
                  {/* nested row end */}
                </div>
                {/* nested main div end */}
              
              </div>
              {/* filter Products main div right side end */}

            </div>
          {/* col end */}
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
    {/* water purifire section end */}






{/*====================products filter main div start ================ */}

  {/* offcanvas main div start */}
  <div className="offcanvas_products_filter_main_div">
    {/* offcanvas main div start */}
  <div className="offcanvas offcanvas-end" tabIndex={-1} id="filterproductsoffcanvas" aria-labelledby="offcanvasRightLabel">

    {/* filter close button div start */}
    <div className="filter_btn_close_div">
     <button type="button" className="fa fa-x" data-bs-dismiss="offcanvas"></button>
    </div>
    {/* filter close button div end */}

{/* filter offcanvas body div start */}
    <div className="offcanvas-body">
      {/* offcanvas products filter by price and products div start */}
      <div className="products_filter_price_products_div">

        {/* filter title div start */}
                <div className="filter_title_div">
                <h5>Filter by Price</h5>
                </div>
              {/* filter title div end */}

{/* price slider div start */}
<div className="price_slider_main_div">
                {/* vales start */}
                    <div className="price_values">
                      ₹ {priceMin} - ₹ {priceMax}
                    </div>
                    {/* value end */}

                    {/* price input start */}
                     <input type="range" name="min" min="1000" max="100000" value={priceMin}
                      onChange={handlePriceChange} className="price_slider_min"/>
                      
                     <input type="range" name="max" min="1000"max="100000" value={priceMax}
                      onChange={handlePriceChange} className="price_slider_max"/>
                    {/* price input end */}
                 </div>
              {/* price slider div end */}

               {/* filter by price  dropdown start*/}
               <div className="filter_by_price_div">
                {/* filter by price title start */}
                <div className="filter_by_price_title_div">
                <h5>Filter by Price</h5>
                </div>
                {/* filter by price title end */}
                <select className="form-select" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} >
                  <option value="asc">Low to High</option>
                  <option value="desc">High to Low</option>
                </select>
              </div>
              {/* filter by price  dropdown end*/}

                {/* filter by subcategory div start */}
                <div className="filter_by_subcategory_div">
                {/* filter by subcategory title start */}
                <div className="filter_by_subcategory_title_div">
                  <h5>Filter by Subcategory</h5>
                </div>
                {/* filter by subcategory title end */}

                {/* filter by subcategory list div start */}
                <div className="filter_by_subcategory_list_div">
                <input type="checkbox" onChange={handleSelectAllSubCategories} />{" "}
                All Subcategories
                  {subCategories.map((subCategory) => (
                    <div className="category_list_div" key={subCategory}>
                      <input type="checkbox" checked={selectedSubCategories.includes(subCategory)}onChange={() => handleSubCategoryChange(subCategory)}/>{" "} {subCategory}
                    </div>
                  ))}
                </div>
                {/* filter by subcategory list div end */}
              </div>
              {/* filter by subcategory div end */}

               {/* filter by shop name div start */}
               <div className="filter_by_shop_name_div">
                {/* filter by shop name title start */}
                <div className="filter_by_shop_name_title_div">
                <h5>Filter by Shop Name</h5>
                </div>
                {/* filter by shop name title end */}

                {/* filter by shop name list div start */}
                <div className="filter_by_shop_name_list_div">
                <input type="checkbox" onChange={handleSelectAllShops} /> All Shops
                {shops.map((shop) => (
                    <div className="category_list_div" key={shop}>
                      <input type="checkbox" checked={selectedShops.includes(shop)} onChange={() => handleShopChange(shop)} />{" "}
                      {shop}
                    </div>
                  ))}
                </div>
                {/* filter by shop name list div end */}

              </div>
              {/* filter by shop name div end */}

      </div>
      {/* offcanvas products filter by price and products div end */}
    </div>
{/* filter offcanvas body div end */}

  </div>
    {/* offcanvas main div start */}
  </div>
  {/* offcanvas main div end */}


{/*====================products filter main div end ================ */}


    </>
  );
};
