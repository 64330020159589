import { RiChatHistoryLine } from "react-icons/ri";
import { Api_Url } from "../constant/Url";
import { FaAmazonPay } from "react-icons/fa6";

export const FetchAllProducts = async () => {
  try {
    const res = await fetch(`${Api_Url}/product/getallproduct`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: "An error occurred while fetching the products",
    };
  }
};

export const GetSingleProductsApi = async (productId) => {
  try {
    const res = await fetch(
      `${Api_Url}/product/getsingleProduct/${productId}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: "An error occurred while fetching the Products.",
    };
  }
};

export const AddToCart = async (data, token) => {
  try {
    const res = await fetch(`${Api_Url}/cart/createcart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
      body: JSON.stringify(data), // Send the data as a JSON string in the request body
    });

    // Parse the response as JSON
    const responseData = await res.json();

    // Check if the request was successful
    if (!res.ok) {
      throw new Error(responseData.message || "Failed to add product to cart");
    }

    // Return the response data (e.g., the updated cart or success message)
    return responseData;
  } catch (error) {
    // Handle errors more explicitly
    console.error("An error occurred while adding to cart:", error.message);
    return { success: false, message: error.message }; // Return error message to handle in the calling function
  }
};

export const GetFilteredProducts = async (data) => {
  try {
    // Dynamically construct query parameters
    const params = new URLSearchParams(
      Object.fromEntries(
        Object.entries({
          CategoryNameSlug: data?.CategoryNameSlug || "",
          SubCategoryNameSlug: data?.SubCategoryNameSlug || "",
          priceMin: data?.priceMin || "",
          priceMax: data?.priceMax || "",
          ShopNameSlug: data?.ShopNameSlug || "",
          dealNameSlug: data?.dealNameSlug || "",
          sortBy: data?.sortBy || "price",
          order: data?.order || "asc",
        }).filter(([_, value]) => value !== "")
      )
    );

    // Construct the API URL
    let url = `${Api_Url}/product/getAllFilteredProduct?${params.toString()}`;

    console.log(url, "urlrulrurl");

    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    // Read the response body once
    const responseData = await res.json();

    return responseData; // Return the response data directly
  } catch (error) {
    console.error("Error fetching filtered products:", error);
    throw error;
  }
};

export const GetFilteredProductsCategorywise = async (data) => {
  try {
    console.log(data, "datadatadatadatadata");
    const res = await fetch(
      `${Api_Url}/product/getAllFilteredProduct?CategoryNameSlug=${data?.CategoryNameSlug}&SubCategoryNameSlug=&priceMin=1000&priceMax=100000&sortBy=price&order=asc
    `,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log(res, "meradata");

    const responseData = await res.json();
    return responseData; // Return the response data directly
  } catch (error) {
    console.log(error);
  }
};

export const CheckPincodeDeliveryProductsApi = async (productId, zipCode) => {
  try {
    const res = await fetch(
      `${Api_Url}/product/${productId}/checkzipcode/${zipCode}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: "An error occurred while fetching the Products.",
    };
  }
};
