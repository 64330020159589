import React, { useEffect, useState } from "react";
import { FetchAllTestimonialsApi } from "../../api/TestimonialsApi";
import Slider from "react-slick";
import { Api_Url } from "../../constant/Url";
import Quote from "../../assets/images/icons/quote.svg";

export const Testimonials = () => {
  const [AllTestimonialsData, setAllTestimonialsData] = useState();

  const AllTestimonialsFetch = async () => {
    try {
      const res = await FetchAllTestimonialsApi();
      if (res?.status === "success") {
        setAllTestimonialsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllTestimonialsFetch();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3300,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
   <>
   {/* testimonials section start */}
   <section className="testimonial_section">
     {/* container start */}
     <div className="container">
      {/* testimonial title start */}
      <div className="testimonial_title">
        <h2>Testimonials</h2>
      </div>
      {/* testimonial title end */}
      {/* row start */}
      <div className="row">
        {/* col start */}
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
           {/* testimonials slider start */}
           <div className="testimonial_slider_main_div">
           {AllTestimonialsData ? (
            <Slider {...settings}>
              {AllTestimonialsData.map((ele, index) => (
                <div  key={index}>
                  
                  <div className="testimonials_main_div">
                  <div className="testmonials_img_box">
                      <img src={`${Api_Url}${ele?.Image}`}
                        alt={ele?.Name} loading="lazy" className="img-fluid" />
                      <div className="testimonials_rating_div">
                        <span className="text-warning">★</span>
                        <span className="text-warning">★</span>
                        <span className="text-warning">★</span>
                        <span className="text-warning">★</span>
                        <span className="text-secondary">★</span>
                      </div>
                      <h5>{ele?.Name}</h5>
                    </div>
                    <div className="testmonials_txt_box">
                      {ele?.Description?.length > 180
                        ? ele?.Description.slice(0, 180) + "..."
                        : ele?.Description}
                    </div>

                  
                    <div className="overlay_quote_img_box">
                      <img src={Quote} alt="" className="img-fluid" loading="lazy"/>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          )}
           </div>
           {/* testimonials slider end */}
        </div>
        {/* col end */}
      </div>
      {/* row end */}
     </div>
     {/* container end */}
   </section>
   {/* testimonials section end */}
   </>
  );
};
