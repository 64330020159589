import React from 'react'
import { Link } from 'react-router-dom'
import chatimg from "../assets/images/image/success01.avif";

const RaiseticketHistory = () => {
  return (
    <>
      
      
{/* breadcrumb section start */}
<section className="breadcrumb_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/* breadcrumb main div start */}
              <div className="breadcrumb_main_div">
                {/* breadcrumb nav div start */}
                <nav className="breadcrum_nav">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                   
                    <li className="breadcrumb-item active" aria-current="page">
                      Raise Ticket History</li>
                  </ol>
                </nav>
                {/* breadcrumb nav div end */}
              </div>
              {/* breadcrumb main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/*  */}


      {/* raise ticket history section start */}
      <section className="raise_ticket_history_section">
        {/* container start */}
        <div className="container">
            {/* row start */}
            <div className="row justify-content-center">
                {/* col start */}
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    {/* raise ticket history main div start */}
                    <div className="raise_ticket_history_main_div">
                        {/* raise ticket history username and email div start */}
                        <div className="raise_histroy_user_flex_div">

                        <div className="raise_ticket_username_main_div">
                            <h2>Ticket History</h2>
                            <p><b>Name:</b> Rinku Singh</p>
                            <p><b>Email ID:</b> rinkusingh78675@gmail.com</p>
                        </div>
                     <Link to="/replyticket">Reply ticket</Link>
                        </div>
                        {/* raise ticket history username and email div end */}
                        
                        {/* raise ticket history details div start */}
                        <div className="raise_ticket_history_details_div">
                        <h1>payment issue</h1>
                        <p>"We are currently experiencing a payment issue. Our team is actively working on resolving it as soon as possible."</p>
                        
                        {/* raise ticket history img div start */}
                        <div className="raise_ticket_history_img_div">
                            <img src={chatimg} alt="raise" className='img-fluid'/>
                        </div>
                        {/* raise ticket history img div end */}


                         {/* raise ticket history date and time div start */}
                         <div className="raise_ticket_history_date_time_div">
                            <p><strong>23 Jan 2025</strong></p>
                         </div>
                         {/* raise ticket history date and time div end */}

                        </div>
                        {/* raise ticket history details div end */}


                        {/* raise ticket history answered details div start */}
                        <div className="raise_history_answered_details_div">
                           <h5>Answered</h5>
                           <p>"We are currently experiencing a payment issue. Our team is actively working on resolving it as soon as possible."</p>

                            {/* raise ticket history img div start */}
                        <div className="raise_ticket_hsitory_answered_img_div">
                            <img src={chatimg} alt="raise" className='img-fluid'/>
                        </div>
                        {/* raise ticket history img div end */}

                         {/* raise ticket history date and time div start */}
                         <div className="raise_ticket_history_answered_date_time_div">
                            <p><strong>23 Jan 2025</strong></p>
                         </div>
                         {/* raise ticket history date and time div end */}

                        </div>
                        {/* raise ticket history answered details div end */}

                         

                    </div>
                    {/* raise ticket history main div end */}
                </div>
                {/* col end */}
            </div>
            {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* raise ticket history section end */}

    </>
  )
}

export default RaiseticketHistory
