import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDecryptedCookie, isAuthenticated } from "../utils/Utils";
import toast, { Toaster } from "react-hot-toast";
import {
  AddNewAddress,
  GetAllUserAddresses,
  GetUserProfile,
} from "../api/UserApi";
import { IoTrashBinOutline } from "react-icons/io5";
import { createOrderApi } from "../api/OrderApi";
import axios from "axios";
import { Api_Url } from "../constant/Url";
import logoWhite from "../assets/images/logo/logo-white.png";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { indianStates } from "../Data/IndianStatesData";
import {
  cartPreCheckoutApi,
  GetAllCart,
  UpdateAddToCart,
} from "../api/CartApi";
import { AddGSTNumber, GetAllGSTNumber } from "../api/GSTApi";

export const CheckOut = () => {
  const navigate = useNavigate();
  const [ProductDetails, setProductDetails] = useState(null);
  const [checkOutData, setCheckoutDetails] = useState(null);
  const [UserAddressData, SetUserAddressData] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("COD");
  const [formInitialValues, setFormInitialValues] = useState();

  const [userData, setUserData] = useState(null);

  const FetchUserProfile = async () => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const token = userData?.token;
      const mobile = userData?.mobile; // Use mobile instead of userId

      if (!token) {
        console.log("Token not found in cookies");
        return;
      }

      // Call GetUserProfile API with mobile and token
      const res = await GetUserProfile(mobile, token);
      if (res?.status === "success") {
        setUserData(res.data);
      } else {
        console.error("Failed to fetch user user addresses", res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchUserProfile();
  }, []);

  useEffect(() => {
    if (userData) {
      setFormInitialValues({
        mobile: userData?.mobile || "",
        address: {
          contactnumber: userData?.mobile || "",
          fullName: userData?.name || "",
          addressLine1: "",
          city: "",
          state: "",
          country: "",
          postalCode: "",
        },
      });
    }
  }, [userData]);

  const FetchAllUserAddresses = async () => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const token = userData?.token;
      const mobile = userData?.mobile;
      const requestedData = {
        mobile: mobile,
      };

      const res = await GetAllUserAddresses(requestedData);
      if (res?.status === "success") {
        SetUserAddressData(res?.data);
        setSelectedAddressId(res?.data?.primaryAddress?._id || "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Productcheckout = async (price, orderId) => {
    try {
      const userData = getDecryptedCookie("UserData");
      if (!userData || !userData?.userId) {
        throw new Error("User data is missing or invalid");
      }

      // Fetch the Razorpay key from the payment gateway
      const {
        data: { key },
      } = await axios.get(`${Api_Url}/paymentgateway/getkey`);

      // Prepare the order data for payment gateway
      const {
        data: { order },
      } = await axios.post(`${Api_Url}/paymentgateway/checkout`, { price });

      const options = {
        key, // Razorpay key
        amount: order.amount, // Amount in currency subunits (INR - paise)
        currency: "INR", // Currency type
        name: "Balaji Water Purifier", // Name of the business or website
        description: "Test Transaction",
        image: { logoWhite }, // Logo or icon for Razorpay popup
        order_id: order.id, // Order ID from the backend
        callback_url: `${Api_Url}/paymentVerify`, // Backend URL for payment verification
        prefill: {
          name: userData?.name || "User Name",
          email: userData?.email || "example@example.com",
          contact: userData?.mobile || "9876543210",
        },
        notes: {
          address: "Nawada" || "Not provided", // Dynamically use the user's address
          userId: userData?._id, // Send userId
          productId: ProductDetails?._id, // Send productId
        },
        theme: {
          color: "#121212", // Customize theme color
        },
      };

      // Open Razorpay for payment
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error("Error during Razorpay checkout:", error);
      alert("An error occurred during checkout. Please try again later.");
    }
  };

  const createOrder = async () => {
    if (!isAuthenticated) {
      return //  navigate("/login");;
    }
    if (!selectedAddressId) {
      toast.error("Please select an address is loaded.");
      return;
    }

    const selectedAddress = UserAddressData.allAddresses.find(
      (addr) => addr._id === selectedAddressId
    );

    if (!selectedAddress) {
      toast.error("Selected address is invalid.");
      return;
    }

    const payload = {
      userId: getDecryptedCookie("UserData")?.userId,
      shippingAddress: {
        fullName: selectedAddress.fullName,
        addressLine1: selectedAddress.addressLine1,
        addressLine2: selectedAddress.addressLine2 || "",
        city: selectedAddress.city,
        state: selectedAddress.state,
        country: selectedAddress.country,
        postalCode: selectedAddress.postalCode,
      },
      orderItems: checkOutData?.data?.items,
      paymentDetails: {
        method: paymentMethod,
        status: "Pending",
      },
      totalPrice: parseInt(checkOutData?.totalPriceWithGst),
    };

    try {
      const res = await createOrderApi(payload);
      if (res?.status === "success") {
        toast.success("Order created successfully!");
        // navigate("/order-success"); // Navigate to a success page
      } else {
        toast.error("Failed to create order.");
      }

      if (paymentMethod == "Online Payment") {
        // If payment method is UPI, proceed with checkout
        const price = res.data?.totalPrice; // Get the total price from the order creation response
        await Productcheckout(price, res.data.id); // Pass the price and order ID to Productcheckout function for payment
      } else if (paymentMethod == "COD") {
        // If payment method is cash on delivery, confirm the order and show confirmation
        toast.success("Order Placed Successfully through COD");
        navigate("/order-confirmation");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while creating the order.");
    }
  };

  useEffect(() => {
    FetchAllUserAddresses();
    getAllPreCheckout();
  }, []);

  async function getAllPreCheckout() {
    try {
      const userData = getDecryptedCookie("UserData");

      const userId = userData?.userId;
      const res = await cartPreCheckoutApi({ userId: userId });
      if (res?.status === "success") {
        setCheckoutDetails(res);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [showAddAddress, setShowAddAddress] = useState(false);

  const handleAddAddressClose = () => setShowAddAddress(false);
  const handleAddAddressShow = () => setShowAddAddress(true);

  const [showAddGST, setShowAddGST] = useState(false);

  const handleAddGSTClose = () => setShowAddGST(false);
  const handleAddGSTShow = () => setShowAddGST(true);

  const validationSchema = Yup.object().shape({
    address: Yup.object().shape({
      contactnumber: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]{10}$/, "Invalid mobile number"),
      fullName: Yup.string().required("Full name is required"),
      addressLine1: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      country: Yup.string().required("Country is required"),
      postalCode: Yup.string()
        .required("Postal code is required")
        .matches(/^\d{6}$/, "Postal code must be 6 digits"),
    }),
  });

  const handleAddAddressFormSubmit = async (values, actions) => {
    try {
      const userData = getDecryptedCookie("UserData"); // Decrypted user data
      const userMobile = userData?.mobile;

      const formData = {
        mobile: userMobile, // User's main mobile number
        address: values.address, // Use the `address` object directly
      };

      const res = await AddNewAddress(formData, userMobile);

      if (res?.status === "success") {
        toast.success("Address added successfully!");
        actions.resetForm();
        handleAddAddressClose();
        window.location.reload();
      } else {
        toast.error(res?.message || "Failed to add address.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      actions.setSubmitting(false);
    }
  };

  // GST Number

  const initialValuesGST = {
    gstnumber: "",
  };

  const validationSchemaGST = Yup.object().shape({
    gstnumber: Yup.string()
      .required("GST Number is required")
      .matches(
        /^[A-Z0-9]{15}$/,
        "GST number must be exactly 15 characters, with uppercase letters and digits only"
      ),
  });

  const handleGSTFormSubmit = async (values, actions) => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const mobile = userData?.mobile; // Extract mobile number from userData
      if (!mobile) {
        toast.error("Mobile number not found!");
        return;
      }

      const formData = {
        gstnumber: values.gstnumber.toUpperCase(),
        mobile: mobile,
      };

      const res = await AddGSTNumber(formData);

      if (res?.status === "success") {
        toast.success("GST Added successfully!", { duration: 3000 });
        actions.resetForm();
        fetchGSTNumbers();
      } else {
        toast.error(res?.message, { duration: 3000 });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }

    actions.setSubmitting(false); // Enable the submit button after process
  };

  const [GSTData, SetGSTData] = useState(null);
  const [selectedGST, setSelectedGST] = useState("");

  const fetchGSTNumbers = async () => {
    try {
      const userData = getDecryptedCookie("UserData");
      const mobile = userData?.mobile;

      if (!mobile) {
        toast.error("Mobile number not found!");
        return;
      }

      const response = await GetAllGSTNumber({ mobile });
      if (response.status === "success") {
        SetGSTData(response.data || []);
      } else {
        toast.error(response.message || "Failed to fetch GST numbers.");
      }
    } catch (error) {
      console.error("Error fetching GST numbers:", error);
      toast.error("An error occurred while fetching GST numbers.");
    }
  };

  useEffect(() => {
    fetchGSTNumbers();
  }, []);

  //  Fetch All Cart Products
  const [CartData, setCartData] = useState([]);

  const FetchCartData = async () => {
    try {
      const userData = getDecryptedCookie("UserData");
      const token = userData?.token;
      const userId = userData?.userId;

      if (!userId || !token) {
        console.error("User ID or token is missing");
        return;
      }

      const data = { userId: userId }; // Dynamically use the user ID

      const res = await GetAllCart(data, token);

      if (res?.status === "success") {
        if (Array.isArray(res?.data)) {
          setCartData(res?.data); // Set cart data only if it's a valid array
        } else {
          setCartData([]); // Set an empty cart if no items are found
        }
      } else {
        console.error("Failed to fetch cart data", res?.message);
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  useEffect(() => {
    FetchCartData();
  }, []);

  const handleQuantityChange = async (productId, action) => {
    // Retrieve user data and token from cookies
    const userData = getDecryptedCookie("UserData");
    const token = userData?.token;
    const userId = userData?.userId;

    if (!token || !userId) {
      console.error("User is not authenticated");
      return;
    }

    // Update cart data in the frontend
    setCartData((prevCartData) =>
      prevCartData.map((item) => {
        if (item.product._id === productId) {
          let newQuantity = item.quantity;

          // Handle quantity increase or decrease logic
          if (action === "increase" && newQuantity < 10) {
            newQuantity += 1;
          } else if (action === "decrease" && newQuantity > 1) {
            newQuantity -= 1;
          }

          // Return updated item with the new quantity
          return { ...item, quantity: newQuantity };
        }
        return item; // Return unmodified items
      })
    );

    // Prepare data for API call
    const data = {
      productId: productId,
      quantity: action === "increase" ? 1 : -1, // Calculate the new quantity to send to API
      userId: userId,
    };

    try {
      // Make API call to update the cart in the backend
      const UpdateApiResponse = await UpdateAddToCart(data, token);
      if (UpdateApiResponse?.status === "success") {
        toast.success(UpdateApiResponse?.message);
        FetchCartData();
      }
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  return (
    <div>
      <Toaster />
      <section className="inner_page_tisection_bred">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="page_ti_inner_page">
                <ul>
                  <li className="active">
                    <Link to="/">Home</Link>
                  </li>
                  -
                  <li>
                    <Link to="/"> Checkout</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="checkout_section_page">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {/* Address */}

              <div className="checkout_main_div">
                <div className="d-flex justify-content-between align-item-center py-2">
                  <h5 className="checkout-title">Billing Details</h5>
                  <div>
                    <button
                      className="view-details"
                      onClick={handleAddAddressShow}
                    >
                      Add More Addresses
                    </button>

                    <Modal
                      show={showAddAddress}
                      onHide={handleAddAddressClose}
                      backdrop="static"
                      keyboard={false}
                      size="l"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Add New Address</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <Formik
                            initialValues={formInitialValues}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={handleAddAddressFormSubmit}
                          >
                            {({ isSubmitting }) => (
                              <Form>
                                <div className="form_main_div">
                                  <div className="form_input_div">
                                    <label>Full Name</label>
                                    <Field
                                      type="text"
                                      name="address.fullName"
                                      placeholder="Enter your full name"
                                      className="Form_Field"
                                    />
                                    <ErrorMessage
                                      name="address.fullName"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>

                                  <div className="form_input_div">
                                    <label style={{ fontWeight: "600" }}>
                                      Mobile{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="address.contactnumber"
                                      className="p-0 px-2 m-0"
                                      placeholder="Mobile Number"
                                      length={10}
                                      style={{ height: "40px", width: "100%" }}
                                    />
                                    <ErrorMessage
                                      name="address.contactnumber"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>

                                  <div className="form_input_div">
                                    <label>Address Line 1</label>
                                    <Field
                                      type="text"
                                      name="address.addressLine1"
                                      placeholder="Enter your address"
                                      className="Form_Field"
                                    />
                                    <ErrorMessage
                                      name="address.addressLine1"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>

                                  <div className="form_input_div">
                                    <label>City</label>
                                    <Field
                                      type="text"
                                      name="address.city"
                                      placeholder="Enter your city"
                                      className="Form_Field"
                                    />
                                    <ErrorMessage
                                      name="address.city"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>

                                  <div className="form_input_div">
                                    <label>State</label>
                                    <Field
                                      as="select"
                                      name="address.state"
                                      className="Form_Field"
                                    >
                                      <option value="">Select a State</option>
                                      {indianStates?.map((state) => (
                                        <option key={state} value={state}>
                                          {state}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name="address.state"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>

                                  <div className="form_input_div">
                                    <label>Country</label>
                                    <Field
                                      type="text"
                                      name="address.country"
                                      placeholder="Enter your country"
                                      className="Form_Field"
                                    />
                                    <ErrorMessage
                                      name="address.country"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>

                                  <div className="form_input_div">
                                    <label>Postal Code</label>
                                    <Field
                                      type="text"
                                      name="address.postalCode"
                                      placeholder="Enter your postal code"
                                      className="Form_Field"
                                    />
                                    <ErrorMessage
                                      name="address.postalCode"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>

                                  <button
                                    type="submit"
                                    className="common_button bg-white"
                                    disabled={isSubmitting}
                                  >
                                    Create Address
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
                <div className="row">
                  {Array.isArray(UserAddressData?.allAddresses) &&
                  UserAddressData.allAddresses.length > 0 ? (
                    UserAddressData.allAddresses.map((ele, index) => (
                      <div
                        className={`card mb-2 ${
                          selectedAddressId === ele._id ? "border-primary" : ""
                        }`}
                        onClick={() => setSelectedAddressId(ele._id)}
                        style={{ cursor: "pointer" }}
                        key={ele._id}
                      >
                        <div className="d-flex">
                          <div className="form-check mt-2">
                            <input
                              type="radio"
                              name="address"
                              className="form-check-input"
                              checked={selectedAddressId === ele._id}
                              onChange={() => setSelectedAddressId(ele._id)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </div>
                          <div className="card-body">
                            <h5 className="card-title">
                              Address {index + 1}
                              {UserAddressData.primaryAddress._id ===
                                ele._id && (
                                <span className="badge bg-primary ms-2">
                                  Primary
                                </span>
                              )}
                            </h5>
                            <p className="card-text m-0">
                              <strong>{ele.fullName}</strong>
                            </p>
                            <div>
                              {ele.addressLine1}, {ele.city}, {ele.state},{" "}
                              {ele.country}, {ele.postalCode}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h5 className="text-center">No Addresses Available</h5>
                  )}
                </div>
              </div>

              {/* GST Numbers - Optional*/}

              <div className="checkout_main_div">
                <div className="d-flex justify-content-between align-item-center py-2">
                  <h5 className="checkout-title">
                    Choose GST Number (Optional)
                  </h5>
                  <div>
                    <button className="view-details" onClick={handleAddGSTShow}>
                      Add More GST
                    </button>
                  </div>
                </div>

                <div>
                  {GSTData?.length > 0 ? (
                    <div>
                      {GSTData.map((gstNumber, index) => (
                        <div
                          key={index}
                          className={`gst-item ${
                            selectedGST === gstNumber ? "selected" : ""
                          }`}
                          onClick={() => setSelectedGST(gstNumber)} // Handle click on the whole div
                          style={{
                            border:
                              selectedGST === gstNumber
                                ? "1px solid #0087cb"
                                : "1px solid gray",
                            padding: "10px",
                            margin: "5px 0",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <div className="form-check">
                            <input
                              type="radio"
                              name="gstNumber"
                              className="form-check-input"
                              checked={selectedGST === gstNumber}
                              readOnly // Makes the radio button non-editable directly
                            />
                            <label
                              className="form-check-label"
                              style={{ marginLeft: "10px" }}
                            >
                              {gstNumber}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No GST Number Available</p>
                  )}
                </div>

                <Modal
                  show={showAddGST}
                  onHide={handleAddGSTClose}
                  backdrop="static"
                  keyboard={false}
                  size="l"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add New GST</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <Formik
                        initialValues={initialValuesGST}
                        enableReinitialize
                        validationSchema={validationSchemaGST}
                        onSubmit={handleGSTFormSubmit}
                      >
                        {({ isSubmitting }) => (
                          <Form>
                            <div className="form_main_div">
                              <div className="form_input_div">
                                <label>
                                  GST Number{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="gstnumber"
                                  placeholder="Enter your GST Numbers"
                                  className="Form_Field"
                                  maxLength={15}
                                />
                                <ErrorMessage
                                  name="gstnumber"
                                  component="div"
                                  className="text-danger mb-2"
                                />
                              </div>

                              <button
                                type="submit"
                                className="common_button bg-white"
                                disabled={isSubmitting}
                              >
                                Create GST Number
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>

              {/* Checkout Products */}

              <div className="checkout_main_div">
                <h5 className="checkout-title">All Cart Items</h5>
                <div className="row">
                  {Array.isArray(CartData) && CartData?.length > 0 ? (
                    CartData.map((ele, index) => (
                      <tr key={ele?._id}>
                        <td>{index + 1}</td>
                        <td>{ele?.product?.ProductName}</td>
                        <td>
                          {`₹ ${parseInt(
                            ele?.product?.ProductPrice
                          ).toLocaleString()}`}
                        </td>

                        <td>
                          <img
                            src={
                              ele?.product?.ProductImage?.[0]
                                ? `${Api_Url}${ele.product.ProductImage[0]}`
                                : "default-image-url.png"
                            }
                            alt={ele?.product?.ProductName || "Product Image"}
                            style={{ height: "100px", objectFit: "fit" }}
                          />
                        </td>
                        {/* <td>{ele?.quantity}</td> */}

                        <td>
                          <div className="d-flex align-items-center">
                            <button
                              onClick={() =>
                                handleQuantityChange(
                                  ele?.product?._id,
                                  "decrease"
                                )
                              }
                              className="btn btn-outline-secondary"
                            >
                              -
                            </button>
                            <span className="mx-2">{ele?.quantity}</span>
                            <button
                              onClick={() =>
                                handleQuantityChange(
                                  ele?.product?._id,
                                  "increase"
                                )
                              }
                              className="btn btn-outline-secondary"
                            >
                              +
                            </button>
                          </div>
                        </td>

                        <td>
                          {`₹ ${parseInt(
                            ele?.quantity * ele?.product?.ProductPrice
                          ).toLocaleString()}`}
                        </td>

                        <td>
                          <div className="d-flex">
                            <div
                              //   onClick={() => handleSingleTestimonial(ele)}
                              className="action_button_view"
                            >
                              {/* <IoEye /> */}
                            </div>
                            <div
                              className="px-2 action_button_update"
                              //   onClick={() => handleUpdateTestimonial(ele)}
                            >
                              {/* <HiPencilSquare className="text-success" /> */}
                            </div>
                            <div
                              className="action_button_delete"
                              // onClick={() => handleDeleteCart(ele)}
                            >
                              <IoTrashBinOutline className="text-danger" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        Data Not Available
                      </td>
                    </tr>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="order-summary-details">
                <h5 className="checkout-title">Price Details</h5>
                <div className="order-summary-content">
                  <div className="order-summary-table">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>Product Price</td>
                          <td>
                            ₹{" "}
                            {parseInt(
                              checkOutData?.totalPrice
                            ).toLocaleString()}
                            /-
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Discount</td>
                          <td>{checkOutData?.totalPrice}%</td>
                        </tr> */}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total Amount (Inc. GST)</td>
                          <td>
                            ₹{" "}
                            {parseInt(
                              checkOutData?.totalPriceWithGst
                            ).toLocaleString()}
                            /-
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="order-payment-method">
                    <div className="mb-3">
                      <label htmlFor="paymentMethod" className="form-label">
                        Payment Method
                      </label>
                      <select
                        id="paymentMethod"
                        className="form-select"
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <option value="COD">Cash on Delivery</option>
                        <option value="Online Payment">Online Payment</option>
                      </select>
                    </div>
                    <button
                      type="button"
                      className="btn btn-sqr"
                      onClick={createOrder}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
