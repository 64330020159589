import React, { useEffect, useState } from "react";
import { Api_Url } from "../../constant/Url";
import { useNavigate } from "react-router-dom";
import { AddToCart, FetchAllProducts } from "../../api/ProductsApi";
import { getDecryptedCookie } from "../../utils/Utils";
import { MdOutlineShoppingCart } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import Slider from "react-slick";
import { Loader } from "../layout/Loader";

export const NewArrivals = () => {
  const navigate = useNavigate();

  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;

  const [AllProductsData, setAllProductsData] = useState();
    const [Loadings, SetLoadings] = useState(false);
  

  const AllProducts = async () => {
    try {
      const res = await FetchAllProducts();
      if (res?.status === "success") {
        setAllProductsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllProducts();
  }, []);

  const handleAddToCart = async (productData) => {
    try {
      const data = {
        productId: productData._id,
        quantity: 1,
        userId: userId,
      };

      const res = await AddToCart(data, token);

      if (res?.status === "success") {
        toast.success(res?.message);
        window.location.reload();
      } else {
        toast.error("Error in Add to Product");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewProduct = (productId) => {
    navigate(
      `${productId?.CategoryNameSlug}/${productId?.SubCategoryNameSlug}/product-details/${productId?._id}`
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3300,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Tablets and small desktops
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getShopColor = (shopNameSlug) => {
    const colors = {
      keel: "rgb(34,179,84)",
      aquafresh: "rgb(0,125,189)",
      "grand-plus": "rgb(104,208,247)",
      "royal-aquafresh": "rgb(254,0,0)",
    };

    // Default RGBA color if slug not found
    return colors[shopNameSlug] || "rgba(0, 0, 0, 1)"; // Black as fallback
  };

  return (
   <>
   {/* new arrival section start */}
   <section className="new_arrival_section">
  {/* container start */}
  <div className="container">
    {/* new arrival title start */}
    <div className="new_arrival_title">
    <h2>New Arrivals</h2>
    </div>
    {/* new arrival title end */}

    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-12 col-md-12 col-sm-12">
        {/* new arrival slider main div start */}
        <div className="new_arrival_main_div_slider">
        {AllProductsData ? (
                <Slider {...settings}>
                  {AllProductsData.map((product) => (
                    <div className="product-card" key={product._id}>
                      <div
                        className="card-content"
                        onClick={() => handleViewProduct(product)}
                      >
                        <div className="image-wrapper">
                          <img
                            src={`${Api_Url}${product.ProductImage[0]}`}
                            alt={product.ProductName}
                            loading="lazy"
                          />
                           <div className="mobile_view_discount">
                                      Save {product.DiscountPercentage}%
                           </div>
                        </div>
                        <div className="product-details">
                          <h5>
                            {product?.ProductName?.length > 20
                              ? product?.ProductName.slice(0, 15) + "..."
                              : product?.ProductName}
                          </h5>
                          <div className="deals_txt_div">
                           <p>
                           {product?.ProductDescription?.length > 25
                              ? product?.ProductDescription.slice(0, 20) + "..."
                              : product?.ProductDescription}
                           </p>
                          </div>
                          <p>
                            <b>
                              ₹
                              {parseInt(
                                product.PriceAfterDiscount
                              ).toLocaleString()}
                            </b>
                            <span className="px-2">
                              ₹
                              {parseInt(
                                product.TotalPriceWithTax
                              ).toLocaleString()}
                            </span>
                            <b className="discount">
                              Save {product.DiscountPercentage}%
                            </b>
                          </p>

                          <div className="brand-name d-flex">
                            <div>Brand: </div>
                            <div
                              className="ps-1"
                              style={{
                                color: getShopColor(product.ShopNameSlug),
                              }}
                            >
                              {product.ShopName}
                            </div>
                          </div>
                          <div className="product-actions">
                            <button className="view-details">
                              View Product
                            </button>
                            <button
                              className="add-to-cart"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddToCart(product);
                              }}
                            >
                              <MdOutlineShoppingCart />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div>
                  <Loader />
                </div>
              )}
        </div>
        {/* new arrival slider main div end */}
      </div>
      {/* col end */}
    </div>
    {/* row end */}
  </div>
  {/* container end */}
   </section>
   {/* new arrival section end */}

   </>
  )
};
