import "./App.css";
import "./styles/responsive.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AboutUs } from "./pages/AboutUs";
import { Home } from "./pages/Home";
import { DashBoard } from "./pages/DashBoard";
import { SiteMap } from "./pages/SiteMap";
import { NotFound } from "./pages/NotFound";
import { TermsConditions } from "./pages/TermsConditions";
import { ReturnRefundPolicy } from "./pages/ReturnRefundPolicy";
import { Disclaimer } from "./pages/Disclaimer";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { ProductDetails } from "./pages/ProductDetails";
import { AuthRoute } from "./constant/AuthRoute";
import { Login } from "./pages/Login";
import { PrivateRoute } from "./constant/PrivateRoute";
import { UserProfile } from "./pages/UserProfile";
import { EditProfile } from "./pages/EditProfile";
import { EditAddress } from "./pages/EditAddress";
import { Cart } from "./pages/Cart";
import { Orders } from "./pages/Orders";
import { CheckOut } from "./pages/CheckOut";
import { WaterPurifier } from "./pages/WaterPurifier";
import { WaterDispensers } from "./pages/WaterDispensers";
import { HelmetProvider } from "react-helmet-async";
import { GSTNumber } from "./pages/GSTNumber";
import { CategoryPage } from "./pages/CategoryPage";
import OrderConfirmation from "./pages/OrderConfirmation ";
import { CartCheckout } from "./pages/CartCheckout";
import Invoice from "./components/Invoice/Invoice";
import Invoi from "./pages/Invoi";
import MyProvider from "./context/MyProvider";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
import VerifyPayment from "./pages/VerifyPayment";
import { PaymentSuccess } from "./paymentpages/PaymentSuccess";
import { ContactUs } from "./pages/ContactUs";
import OrderDetails from "./pages/OrderDetails";
import Raiseticket from "./pages/Raiseticket";
import Replyticket from "./pages/Replyticket";
import RaiseticketDetails from "./pages/RaiseticketDetails";
import RaiseticketHistory from "./pages/RaiseticketHistory";
import Breadcrum from "./components/breadcrum/Breadcrum";



function App() {
  return (
    <>
      <HelmetProvider>
        <Router>
      
        
          <MyProvider>
          <ScrollToTop/>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route path="/" element={<DashBoard />} />

                <Route
                  path="/pages/:CategoryNameSlug"
                  element={<CategoryPage />}
                />
                <Route path="/invoice" element={<Invoice />} />

<Route
                path="/paymentgateway/paymentverify"
                element={<VerifyPayment />}
              />




              <Route
                path="/:CategoryNameSlug"
                element={<WaterPurifier />}
              />
              <Route
                path="/:CategoryNameSlug/:SubCategoryNameSlug"
                element={<WaterPurifier />}
              />

                <Route
                  path="/:CategoryNameSlug/:SubCategoryNameSlug/product-details/:productId"
                  element={<ProductDetails />}
                />
                <Route
                  path="/:CategoryNameSlug/:SubCategoryNameSlug/product-details/:productId/checkout"
                  element={<CheckOut />}
                />

                {/* <Route path="/waterpurifiers" element={<WaterPurifier />} /> */}
                <Route path="/waterdispensers" element={<WaterDispensers />} />
                <Route
                  path="/order-confirmation"
                  element={<OrderConfirmation />}
                />

                <Route path="/product-details" element={<ProductDetails />} />

                <Route
                  path="/waterpurifier/product-details/:productId"
                  element={<ProductDetails />}
                />

                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/sitemap" element={<SiteMap />} />

                <Route path="/termsconditions" element={<TermsConditions />} />
                <Route
                  path="/returnrefundpolicy"
                  element={<ReturnRefundPolicy />}
                />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/NotFound" element={<NotFound />} />


                <Route path="/paymentsuccess" element={<PaymentSuccess />} />

                {/* User Routes */}

                <Route
                  path="/login"
                  element={
                    <AuthRoute>
                      <Login />
                    </AuthRoute>
                  }
                />

                <Route
                  path="/userprofile"
                  element={
                    <PrivateRoute>
                      <UserProfile />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/editprofile"
                  element={
                    <PrivateRoute>
                      <EditProfile />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/editaddress"
                  element={
                    <PrivateRoute>
                      <EditAddress />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/gstnumber"
                  element={
                    <PrivateRoute>
                      <GSTNumber />
                    </PrivateRoute>
                  }
                />

                {/* <Route
                  path="/cart"
                  element={
                    <PrivateRoute>
                      <Cart />
                    </PrivateRoute>
                  }
                /> */}
                <Route
                  path="/checkout/:id"
                  element={
                    <PrivateRoute>
                      <CartCheckout />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/orders"
                  element={
                    <PrivateRoute>
                      <Orders />
                    </PrivateRoute>
                  }
                />

<Route
                  path="/ordersdetails/:orderId"
                  element={
                    <PrivateRoute>
                      <OrderDetails />
                    </PrivateRoute>
                  }
                />

                  <Route
                  path="/raiseticket"
                  element={
                    <PrivateRoute>
                      <Raiseticket />
                    </PrivateRoute>
                  }
                />

                
            <Route
                  path="/replyticket"
                  element={
                    <PrivateRoute>
                      <Replyticket />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/raiseticketdetails"
                  element={
                    <PrivateRoute>
                      <RaiseticketDetails />
                    </PrivateRoute>
                  }
                />

                 <Route
                  path="/raisetickethistory"
                  element={
                    <PrivateRoute>
                      <RaiseticketHistory />
                    </PrivateRoute>
                  }
                />


                <Route
                  path="/orders/invoice/:invoiceId"
                  element={
                    <PrivateRoute>
                      <Invoice />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/waterpurifier/product-details/:id/checkout"
                  element={
                    <PrivateRoute>
                      <CheckOut />
                    </PrivateRoute>
                  }
                />
              </Route>
            </Routes>
          </MyProvider>
          
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
