import React from "react";
import { Link } from "react-router-dom";
import "../styles/Disclaimer.css";

export const TermsConditions = () => {
  return (
    <>
    

       {/* breadcrumb section start */}
                     <section className="breadcrumb_section">
                       {/* container start */}
                       <div className="container">
                         {/* row start */}
                         <div className="row">
                           {/* col start */}
                           <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                             {/* breadcrumb main div start */}
                             <div className="breadcrumb_main_div">
                               {/* breadcrumb nav div start */}
                               <nav className="breadcrum_nav">
                                 <ol className="breadcrumb">
                                   <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                   <li className="breadcrumb-item active" aria-current="page"> Terms & Conditions</li>
                                 </ol>
                               </nav>
                               {/* breadcrumb nav div end */}
                             </div>
                             {/* breadcrumb main div end */}
                           </div>
                           {/* col end */}
                         </div>
                         {/* row end */}
                       </div>
                       {/* container end */}
                     </section>

                     <section className="privacy_policy_section">
      <div className="container">
        <div className="privacy_policy_title_div">
          <h1>Terms & Conditions</h1>
          <p><strong>Last updated: 13 January 2025</strong></p>
        </div>
      <div className="row justify-content-center">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="privacy_policy_txt_div">
            <h2>Introduction</h2>
            <p>
              This Terms & Conditions describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>
            <p>
              We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Terms & Conditions.
            </p>

            
            <h3>Interpretation</h3>
            <p>
              The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>

            <h4>Definitions</h4>
            <ul>
              <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
              <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party.</li>
              <li><strong>Company</strong> (referred to as "the Company", "We", "Us", or "Our") refers to Balaji Traders.</li>
              <li><strong>Cookies</strong> are small files that are placed on Your computer or mobile device.</li>
              <li><strong>Country</strong> refers to: Delhi, India.</li>
              <li><strong>Device</strong> means any device that can access the Service such as a computer or mobile device.</li>
              <li><strong>Personal Data</strong> is any information that relates to an identifiable individual.</li>
              <li><strong>Service</strong> refers to the Website.</li>
              <li><strong>Service Provider</strong> means any person or entity who processes data on behalf of the Company.</li>
              <li><strong>Usage Data</strong> refers to data collected automatically.</li>
              <li><strong>Website</strong> refers to Balaji Traders, accessible from <a href="https://www.balajiwaterpurifier.com/">https://www.balajiwaterpurifier.com/</a></li>
              <li><strong>You</strong> means the individual or legal entity using the Service.</li>
            </ul>

            <h4>Collecting and Using Your Personal Data</h4>
            
            <h4>Types of Data Collected</h4>

            <h4>Personal Data</h4>
            <p>
              We may ask You to provide Us with certain personally identifiable information, including but not limited to email address, phone number, and Usage Data.
            </p>

            <h4>Usage Data</h4>
            <p>
              Usage Data is collected automatically when using the Service. It may include information like Your device's IP address, browser type, browser version, and other diagnostic data.
            </p>

            <h4>Tracking Technologies and Cookies</h4>
            <p>
              We use Cookies and similar tracking technologies to track activity on Our Service. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
            </p>

            <h4>Use of Your Personal Data</h4>
            <p>The Company may use Personal Data for purposes such as:</p>
            <ul>
              <li>To provide and maintain our Service</li>
              <li>To manage Your Account</li>
              <li>To contact You</li>
              <li>For business transfers</li>
              <li>For other purposes like data analysis</li>
            </ul>

            <h4>Retention of Your Personal Data</h4>
            <p>
              The Company will retain Your Personal Data only for as long as necessary for the purposes set out in this Terms & Conditions.
            </p>

            <h4>Transfer of Your Personal Data</h4>
            <p>
              Your information may be transferred to — and maintained on — computers located outside of Your jurisdiction where data protection laws may differ.
            </p>

            <h4>Delete Your Personal Data</h4>
            <p>
              You have the right to delete or request the deletion of Personal Data collected about You.
            </p>

            <h4>Disclosure of Your Personal Data</h4>
            <p>
              Your Personal Data may be disclosed for business transactions or for legal obligations.
            </p>

            <h4>Contact Us</h4>
            <p>If you have any questions about this Terms & Conditions, You can contact us:</p>
            <ul>
              <li>By email: helpdesk@balajiwaterpurifier.com</li>
            </ul>
          </div>
        </div>
      </div>
      </div>
    </section>
    </>
  );
};
