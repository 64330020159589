import React, { useEffect, useState } from "react";
import "../styles/EditProfile.css";
import { IoCamera } from "react-icons/io5";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import { GetUserProfile, UpdateUserProfile } from "../api/UserApi";
import { getDecryptedCookie } from "../utils/Utils";
import { LuUser2 } from "react-icons/lu";
import { Api_Url } from "../constant/Url";
import { Link, Navigate, useNavigate } from "react-router-dom";
// import user from "../assets/images/icon/user.png";

export const EditProfile = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [profilePreview, setProfilePreview] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const FetchUserProfile = async () => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const token = userData?.token;
      const mobile = userData?.mobile; // Use mobile instead of userId

      if (!token) {
        console.log("Token not found in cookies");
        return;
      }

      // Call GetUserProfile API with mobile and token
      const res = await GetUserProfile(mobile, token);
      if (res?.status === "success") {
        setUserData(res?.data);
        setProfilePreview(
          res?.data?.ProfilePhoto ? `${Api_Url}${res.data.ProfilePhoto}` : null
        );
      } else {
        console.error("Failed to fetch user user addresses", res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchUserProfile();
  }, []);

  // Update profilePreview based on userData.ProfilePhoto
  useEffect(() => {
    if (userData?.ProfilePhoto) {
      setProfilePreview(`${Api_Url}${userData.ProfilePhoto}`);
    }
  }, [userData]);

  // Edit Profile

  const editChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const initialValues = {
    name: userData?.name || "",
    email: userData?.email || "",
    mobile: userData?.mobile || "",
    ProfilePhoto: null, // For image
  };

  const validateEmail = (value) => {
    const emailRegex =
      /^[a-zA-Z0-9]+([._-]?[a-zA-Z0-9])*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!value) {
      return "Email is required.";
    } else if (!emailRegex.test(value)) {
      return "Invalid email format. Please follow the allowed character rules.";
    } else if (value.length > 20) {
      return "Email cannot exceed 20 characters.";
    }
    return undefined; // No error
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
      .required("Name is required"),
    email: Yup.string()
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Email can only include letters, numbers and '@' "
      )
      .max(20, "Email cannot exceed 20 characters")
      .required("Email is required"),
    mobile: Yup.string().required("Mobile is required"),
    ProfilePhoto: Yup.mixed().nullable(),
  });

  const handleFormSubmit = async (values, actions) => {
    try {
      const formData = new FormData();

      // Append form data
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("mobile", values.mobile);
      if (profileImage) {
        formData.append("ProfilePhoto", profileImage); // Append the image file
      }

      const res = await UpdateUserProfile(formData);
      if (res?.status === "success") {
        toast.success("Profile Updated successfully!", { duration: "3000" });
        navigate("/");
        FetchUserProfile();
        actions.resetForm();
      }

      // Show success toast

      // You can also reset the form if needed
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong!");
    }

    actions.setSubmitting(false);
  };

  return (
    <>
      <Toaster />

      {/* breadcrumb section start */}
      <section className="breadcrumb_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/* breadcrumb main div start */}
              <div className="breadcrumb_main_div">
                {/* breadcrumb nav div start */}
                <nav className="breadcrum_nav">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {" "}
                      Edit Profile
                    </li>
                  </ol>
                </nav>
                {/* breadcrumb nav div end */}
              </div>
              {/* breadcrumb main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>

      {/* edit profile section start */}
      <section className="edit_profile_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row justify-content-center">
            {/* col start */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              {/* edit profile main div start */}
              <div className="edit_profile_main_div">
                {/* edit profile form user image start */}
                <div className="edit_input_userimg_div_main">
                  {profilePreview ? (
                    <img src={profilePreview} alt="Profile" />
                  ) : (
                    <LuUser2 style={{ fontSize: "105px" }} />
                  )}
                  {/* user upload image input start */}
                  <div className="upload_div_main">
                    <div className="input_usr_updd">
                      <input
                        type="file"
                        name="profile"
                        id="imageInput"
                        accept="image/*"
                        onChange={editChange}
                        style={{ display: "none" }}
                      />
                      <label htmlFor="imageInput" className="uploadLabel">
                        <IoCamera className="icon_camera" />
                      </label>
                    </div>
                  </div>
                  {/* user upload image input end */}
                </div>
                {/* edit profile form user image end */}

                {/* form inputs main div start  */}
                <div className="form_inputs_main_div">
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="">
                          <div className="row d-flex justify-content-between">
                            <div className="col-md-12 mt-1">
                              <div className="form_input_div">
                                <label>
                                  Name <span>*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="name"
                                  placeholder="Enter your name"
                                  className="Form_Field form-control"
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^A-Za-z\s]/g,
                                      ""
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-danger form-control"
                                />
                              </div>
                            </div>

                            <div className="col-md-12 mt-1">
                              <div className="form_input_div">
                                <label>
                                  Email <span>*</span>
                                </label>
                                <Field
                                  type="email"
                                  name="email"
                                  placeholder="Enter your email"
                                  className="Form_Field form-control"
                                  validate={validateEmail}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            <div className="col-md-12 mt-1">
                              <div className="form_input_div">
                                <label>
                                  Mobile <span>*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="mobile"
                                  placeholder="Enter your phone number"
                                  maxLength={10}
                                  className="Form_Field form-control"
                                  readOnly
                                />
                                <ErrorMessage
                                  name="mobile"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            <div className="edit_profile_btn">
                              <button
                                type="submit"
                                className="btn btn-md"
                                disabled={isSubmitting}
                              >
                                Update Profile
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                {/* form inputs main div end  */}
              </div>
              {/* edit profile main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* edit profile section end */}
    </>
  );
};
