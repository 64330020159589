import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../styles/ProductDetails.css";
import "../styles/FAQ.css";
import { CiCircleMinus } from "react-icons/ci";
import { CiCirclePlus } from "react-icons/ci";
import {
  AddToCart,
  CheckPincodeDeliveryProductsApi,
  GetSingleProductsApi,
} from "../api/ProductsApi";

import { Helmet } from "react-helmet-async";
import { MdOutlineShoppingCart } from "react-icons/md";
import { GiElectric } from "react-icons/gi";
import toast, { Toaster } from "react-hot-toast";
import { getDecryptedCookie } from "../utils/Utils";
import { WriteReview } from "./WriteReview";
import { SimilarProducts } from "./SimilarProducts";
import { Api_Url } from "../constant/Url";
import { Loader } from "../components/layout/Loader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CartProductCheckoutApi } from "../api/CartApi";
import MyContext from "../context/myContext";
import reviewimg from "../assets/images/icons/loginicon.png";


export const ProductDetails = () => {

  
  // start reviwe Write
  const [ratingreview, setRatingreview] = useState(0);

  const handleClickrating = (index) => {
    setRatingreview(index + 1);
  };

  // start reviwe Write end

  
  // start reviwe Write
  const [writeRating, setWriterating] = useState(0);

  const handleClickwrite = (index) => {
    setWriterating(index + 1);
  };

  // start reviwe Write end

  const [active, setActive] = useState(null);
  const handleToggle = (index) => setActive(active === index ? null : index);
  const navigate = useNavigate();
  const { handleAddtoCart } = useContext(MyContext);
  const [deliveryStatus, setDeliveryStatus] = useState();

  const { productId } = useParams();
  const [productdetails, setProductDetails] = useState(null); // Initialize as null to check loading state
  const [quantity, setQuantity] = useState(1);
  const [FAQ, setFAQ] = useState(null);
  // console.warn(quantity, 'quantityquantityquantityquantity')
  // Zoom Hover Effects

  const imageContainerRef = useRef(null);
  const zoomRef = useRef(null);
  const [activeImage, setActiveImage] = useState();
  const [cursorStyle, setCursorStyle] = useState({
    display: "none",
    top: 0,
    left: 0,
  });
  
  const [zoomStyle, setZoomStyle] = useState({
    // display: "none",
    // position: "absolute",
    // top: "0px",
 
    // width: "100%",
    // height: "350px",
    // overflow: "hidden",
    // zIndex: -10,
    // border: "1px solid #ddd",
    // borderRadius: "5px",
    // boxShadow: "0px 0px 10px rgba(112, 108, 108, 0.2)",
  });

  const handleMouseEnter = () => {
    setCursorStyle((prev) => ({ ...prev, display: "block" }));
    setZoomStyle((prev) => ({ ...prev, display: "block" }));
  };

  const handleMouseLeave = () => {
    setCursorStyle({ display: "none", top: 0, left: 0 });
    setZoomStyle({ display: "none", backgroundPosition: "0px 0px" });
  };
  function preparePayload(inputData) {
    // Transform the input data into the desired payload format
    return {
      userId: inputData.userId,
      products: inputData.products.map((item) => ({
        productId: item.product._id,
        quantity: item.quantity,
      })),
    };
  }
  async function handlePreCheckout() {

    const products = [{ productId, quantity }];

    const userId = getDecryptedCookie("UserData")?.userId;

    const reqSend = { userId, products };
    const reqSend2 = reqSend
    try {
      const response = await CartProductCheckoutApi(reqSend2);
      console.error("Checkout Response:", response);
      if (response?.status === "success") {
        toast.success(response?.message);
        navigate(`/checkout/${response?.data?.checkoutId}`);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Checkout Error:", error);
      alert("Something went wrong!");
    }
  }

  const handleMouseMove = (e) => {
    const container = imageContainerRef.current;
    const zoom = zoomRef.current;
    if (!container || !zoom) return;

    const rect = container.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (x < 0 || y < 0 || x > rect.width || y > rect.height) {
      // Disable zoom if cursor is outside the image
      handleMouseLeave();
      return;
    }

    const cursorSize = 100;
    const cursorHalf = cursorSize / 2;

    const cursorX = Math.max(
      0,
      Math.min(x - cursorHalf, rect.width - cursorSize)
    );
    const cursorY = Math.max(
      0,
      Math.min(y - cursorHalf, rect.height - cursorSize)
    );

    setCursorStyle({
      display: "block",
      top: `${cursorY}px`,
      left: `${cursorX}px`,
    });

    const zoomBackgroundX = -(x * 2.5 - zoom.offsetWidth / 2);
    const zoomBackgroundY = -(y * 2.5 - zoom.offsetHeight / 2);

    setZoomStyle({
      display: "block",
      backgroundImage: `url(${activeImage})`,
      backgroundSize: "800px 800px",
      backgroundPosition: `${zoomBackgroundX}px ${zoomBackgroundY}px`,
    });
  };

  // User Data
  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;

  // Fetch product details by ID
  const getSingleProduct = async () => {
    try {
      const res = await GetSingleProductsApi(productId);
      if (res?.status === "success") {
        setProductDetails(res?.data);
        setFAQ(res?.data?.faq);
        setActiveImage(`${Api_Url}${res.data.ProductImage[0]}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProduct();
  }, [productId]);

  // Generate unique order ID
  const generateUniqueOrderId = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  };

  const [orderId, setOrderId] = useState(generateUniqueOrderId());

  useEffect(() => {
    generateUniqueOrderId();
  }, []);

  const handleCheckPincodeDeliveryProductsApi = async (values, actions) => {
    try {
      const res = await CheckPincodeDeliveryProductsApi(
        productId,
        values.postalCode
      );

      if (res.status === "success") {
        setDeliveryStatus({
          message: res.message,
          isAvailable: res.data.isAvailable,
        });
      } else {
        setDeliveryStatus({
          message: res.message,
          isAvailable: null,
        });
      }
    } catch (error) {
      console.error("Error checking delivery:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const initialValues = {
    postalCode: "",
  };

  const validationSchema = Yup.object().shape({
    postalCode: Yup.string()
      .required("Postal code is required")
      .matches(/^\d{6}$/, "Postal code must be 6 digits"),
  });

  if (!productdetails) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
        {/* changes  */}
      </div>
    ); // Render loading state until data is available
  }

  // Handle quantity increase or decrease
  const handleQuantityChange = (action) => {
    if (action === "increase") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    } else if (action === "decrease" && quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const totalPrice = productdetails?.PriceAfterDiscount*quantity
console.log(productdetails)
  const HandleAddtoCart = async () => {
    try {
      // if (userId === undefined) {
      //   navigate("/login");
      // }

      const data = {
        productId: productId,
        quantity: quantity,
        userId: userId,
        token:token
      };

     await handleAddtoCart(data);

     
    } catch (error) {
      console.log(error);
    }
  };

//   const handleThumbnailScroll = (e) => {
//     const container = e.currentTarget;
//     const { clientY } = e;
//     const containerRect = container.getBoundingClientRect();
//     const offset = 60; // Scroll trigger zone size

//     if (clientY - containerRect.top < offset) {
//         container.scrollTop -= 10; // Scroll up
//     } else if (containerRect.bottom - clientY < offset) {
//         container.scrollTop += 10; // Scroll down
//     }
// };

const handleArrowScroll = (direction) => {
  const container = document.querySelector(".products_relative_details_thumbnailimage");
  if (direction === "up") {
      container.scrollTop -= 50; // Adjust scroll speed if needed
  } else if (direction === "down") {
      container.scrollTop += 50;
  }
};

const shouldShowScrollButtons = productdetails?.ProductImage?.length > 4;

  return (
    <>
      <Toaster />
      {/* product details section start */}
      <section className="products_details_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">

            {/* col start */}
            <div className="col-lg-4 col-md-5 col-sm-12 col-12">
              {/* products details Relative DIV main Left side start */}
              <div className="products_details_relative_div_main_left_side">
              {/* MAIN IMAGE FLEX DIV START */}
              <div className="main_flex_div_flex_img_pro_dt">
              {/*thumbnail products details image main div start */}
              <div className="products_details_thumbnail_main_div">
                {/*thumbnail products deatils image box start */}
                       {/* Up Arrow */}
                  {shouldShowScrollButtons && (
                     <button className="scroll-arrow up-arrow fa fa-angle-up" onClick={() => handleArrowScroll("up")}></button>
                     )}
                <div className="products_relative_details_thumbnailimage" >
            
                  {productdetails?.ProductImage?.map((image, index) => (
                    <img key={index} src={`${Api_Url}${image}`} alt={`Thumbnail ${index + 1}`} className={`img-fluid ${activeImage === `${Api_Url}${image}` ? "active" : ""  }`} onClick={() => setActiveImage(`${Api_Url}${image}`)}/>
                  ))}
                </div>
                {/*thumbnail products deatils image box end */}
                {shouldShowScrollButtons && (
              <button className="scroll-arrow down-arrow fa fa-angle-down" onClick={() => handleArrowScroll("down")} > </button>
              )}
              </div>
              
              {/*thumbnail products details image main div end */}

            {/* products details image main div start */}
          <div className="products_details_image_main_div">
          {/* products relative div thumbnail start */}
             <div className="products_relative_details_image">

                {/* products deatils image box start */}
                <div className="products_details_image_box">

                  {/* big image start */}
                <div className="big_image_view" ref={imageContainerRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove}>
                  <Link to={activeImage} data-fancybox="gallery"><img src={activeImage} alt="Main View" className="img-fluid"/></Link>
                  <div className="zoom-cursor" style={cursorStyle}></div>
                </div>
                </div>
                  {/* big image end */}

               {/* big zoom area start */}
               <div className="zoom-viewer" ref={zoomRef} style={zoomStyle} ></div>
               {/* big zoom area end */}

                 {/* products relative div thumbnail end */}
              </div>
                {/* products deatils image box end */}
              </div>
              {/* products details image main div end */}
              </div>
              {/* MAIN IMAGE FLEX DIV end */}

              {/* products cart and buy now div start */}
              <div className="products_cart_buy_now_div">
            
              <button className="add_cart_btn" onClick={HandleAddtoCart}> <span className="fa fa-cart-plus"></span> Add to Cart </button>
              <button className="buy_now_btn" onClick={handlePreCheckout}> <span className="fa fa-bolt"></span>    Buy Now </button>
              </div>
              {/* products cart and buy now div end */}
              </div>
              {/* products details Relative DIV main Left side start */}

            </div>
            {/* col end */}
   {/* col start */}
   <div className="col-lg-8 col-md-7 col-sm-12 col-12">
    {/* products details div start */}
    <div className="products_details_main_div">

      {/* products dt title div start */}
      <div className="products_dt_title_div">
      <h1>Royal Aquafresh Copper Audy 12 L RO + UV + UF + TDS Water Purifier with Prefilter  (White-Blue)</h1>
      </div>
      {/* products dt title div end */}

      {/* products brand name div start */}
      <div className="products_brand_name_div">
        <p>Brand: <span>Grand Plus</span></p>
      </div>
      {/* products brand name div end */}

      {/* products dt rating div start */}
      <div className="products_dt_rating_div">
        <p className="rating_div_dt">4.5 <span className="fa fa-star"></span></p>
        <p className="rating_review_div_dt"><b>177 Ratings & 15 Reviews</b></p>
      </div>
      {/* products dt rating div end */}

      {/* product details price div start */}
      <div className="product_details_price_div">
        <h2>Special Price</h2>
        <h3>
       <b className="price_div">₹{" "}
                    {parseInt(
                     totalPrice
                    ).toLocaleString()}{" "}</b>
        <b className="discount_price_div">2,500</b>
        <b className="price_off_div">5% off</b>
        </h3>
      </div>
      {/* product details price div end */}

       {/* Total Capacity div start */}
       <div className="total_capacity_div">
        <h3>Total Capacity <b>12L</b></h3>
      </div>
      {/* Total Capacity div end */}

      {/* products Delivery div end */}
      <div className="products_delivery_pin_code_div">
        <h4>Delivery</h4>
        <form action="">
        <div className="input-groupp">
        <input type="text" className="form-control" placeholder="Enter Deliver Pincode" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button className="btn btn-outline-secondary" type="button" id="button-addon2">Check</button>
      </div>
        </form>
      </div>
      {/* products Delivery div start */}

     {/* Specifications div start */}
     <div className="product_specification_div">
      <h4>Specifications:</h4>
      {/* specification table div start */}
      <div className="specification_table_div">
        <table className="table table-bordered">
          <tr>
            <th colSpan={2} className="general_txt">General</th>
          </tr>
          <tr>
            <th>Model Name:</th>
            <td>Copper Audy</td>
          </tr>

<tr>
  <th>Series:</th>
  <td>Royal Aquafresh</td>
</tr>


<tr>
  <th>Color:</th>
  <td>White-Blue </td>
</tr>


<tr>
  <th>Total Capacity:  </th>
  <td>12 L</td>
</tr>


<tr>
  <th>Purifying Technology:  </th>
  <td>  RO + UV + UF + TDS</td>
</tr>


<tr>
  <th>In The Box:  </th>
  <td>Main Unit, Free Introductory Pre-Filter worth Rs-1199, Power Supply, , Plumbing Kit,, Diverter Valve Assembly, 2 Way Connector  </td>
</tr>


<tr>
  <th>Power Requirement:  </th>
  <td>Single Phase 220 Â± 10 V AC, 50 Hz  </td>
</tr>


<tr>
  <th>Operating Voltage:  </th>
  <td>24 V  </td>
</tr>

<tr>
  <th>Power Consumption:  </th>
  <td>62 W  </td>
</tr>

<tr>
  <th>Electrical Type:  </th>
  <td>Yes</td>
</tr>

<tr>
  <th>Purification Features:  </th>
  <td>RO UV UF TDS  </td>
</tr>

        </table>
      </div>
      {/* specification table div end */}

      {/* Product Details table div start*/}
      <div className="product_details_table_main_div">

        {/* products details title div start */}
        <div className="product_details_title_div">
          <h4>Product Details:</h4>
        </div>
        {/* products details title div end */}

        {/* products deatils table div start */}
        <div className="product_details_table_div">
        <table className="table table-bordered">
             <tr>
              <th>Detachable Storage Tank:</th>
              <td>Yes</td>
             </tr>

<tr>
  <th>Auto Shut Off:  </th>
  <td>Yes</td>
</tr>

<tr>
  <th>Auto Start:  </th>
  <td>Yes</td>
</tr>


<tr>
  <th>Overflow Protection:  </th>
  <td>Yes</td>
</tr>

<tr>
  <th>Child Lock:  </th>
  <td>No</td>
</tr>

<tr>
  <th>Filter Life:  </th>
  <td>6000 L  </td>
</tr>

<tr>
  <th>Filter Type:  </th>
  <td>Pre Sediment Filter 0.1 micron, Carbon 0.1 micron RO Membrane 0.1 Ultra Violet Filter  </td>
</tr>

<tr>
  <th>Storage Tank Material:  </th>
  <td>ABS Grade Food Plastic with Germ Block Technology  </td>
</tr>

<tr>
  <th>Other Convenience Features:  </th>
  <td>Our high quality fittings come with stainless steel teeth for a guaranteed grip, and special o-ring provides a leak-proof seal. No extra clipping parts and leak detector needed. For your peace of mind  </td>
</tr>


            </table>
        </div>
        {/* products deatils table div end */}
        
      </div>
      {/* Product Details table div end*/}
     </div>
     {/* Specifications div end */}

     
      {/* product description div start */}
      <div className="product_description_div">
      <h3>Description:</h3>
        <p> <strong>Royal Aquafresh</strong> ro system in india is a leader in uv water purification technology and has designed the  <strong>Royal Aquafresh</strong> online best water purifier system in india to meet demanding ISO 9001:2015 standards with the following features: award winning design: the  <strong>Royal Aquafresh</strong> ro water purifier system's award winning design with low price list in delhi ncr makes it the perfect solution to provide safe, pure water whenever and wherever needed, from the dinner table to your next camping adventure, you can be sure that your family's health is our first priority. Bacteria and virus free:  <strong>Royal Aquafresh</strong> ro uv technology destroys microbes, bacteria, and viruses.</p>
       
      </div>
      {/* product description div end */}

      
      {/* products warranty div start */}
      <div className="products_warranty_div">
        <h3>Warranty</h3>
        <p>Warranty on MOTOR/SMPS available from Manufacture. But Installation amount will be paid by customer 450-600/- . If any problem occurs during warranty period Then buyer will make courier at Manufacture or service center address after that Manufacture will replace MOTOR/SMPS (Manufacture will courier parts and it will get deliver to customer's address within maximum 4-6 days(working hours) , and every visit will be chargeable after the installation (RS300/-) Ã¢?Â¢ All service will chargeable.</p>
      </div>
      {/* products warranty div end */}

     

        {/* products heighlight div start */}
        <div className="products_heightlight_description_div">
        <h4>Highlights</h4>
        <ul>
          <li>Electrical & Storage : Electric purification - suitable for areas with water shortage</li>
          <li>12 L : More the capacity, more the users can be served with drinking water</li>
          <li>RO + UV + UF + TDS : Uses many filters to remove salts & microbes in multiple stages</li>
        </ul>
      </div>
      {/* products heighlight div end */}



     
    </div>
    {/* products details div end */}

    {/* nested row start rating */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
      <div className="rating_borer_min_di">
  <div className="rating_div_ti">
  <h4>Rating & Review</h4>
  </div>
  <div className="rating_flx_main_div">
    {/*  */}
  <div className="rating_div_txt_main">
   
    <h5>4.91</h5>
    <div className='rating_div'>
    {[...Array(5)].map((star, index) => {
                return (
                    <span
                        key={index}
                        className={`fa fa-star ${index < writeRating ? 'checked' : ''}`}
                        onClick={() => handleClickwrite(index)}
                    ></span>
                );
            })}
     <h6><span className='fa fa-user'></span> 5,900</h6>
</div>
  </div>
  {/*  */}

{/*  */}
<div className="progress_bar_flex_main_div">
{/*  progress*/}
<div className="progress_div">
<b>5</b>
<div className="progress">
  <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: '90%'}}>
  <div className='userrating_bar'><span class="fa fa-user"></span> 5,000</div>
  </div>
</div>
</div>
{/* progress end */}

{/*  progress*/}
<div className="progress_div">
<b>4</b>
<div className="progress">
  <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: '80%'}}>
  <div className='userrating_bar'><span class="fa fa-user"></span> 500</div>
  </div>
</div>
</div>
{/* progress end */}


{/*  progress*/}
<div className="progress_div">
<b>3</b>
<div className="progress">
  <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: '60%'}}>
  <div className='userrating_bar'><span class="fa fa-user"></span> 250</div>
  </div>
</div>
</div>
{/* progress end */}


{/*  progress*/}
<div className="progress_div">
<b>2</b>
<div className="progress">
  <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: '40%'}}>
  <div className='userrating_bar'><span class="fa fa-user"></span> 100</div>
  </div>
</div>
</div>
{/* progress end */}


{/*  progress*/}
<div className="progress_div">
<b>1</b>
<div className="progress">
  <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: '30%'}}>
  <div className='userrating_bar'><span class="fa fa-user"></span> 50</div>
  </div>
</div>
</div>
{/* progress end */}


</div>
{/*  */}
</div>
  </div>

{/* write review start */}
<div className="write_review_div_main">
  <form action="">
<h3>Write Review</h3>
<div className="write_review">
{[...Array(5)].map((star, index) => {
                return (
                    <span
                        key={index}
                        className={`fa fa-star ${index < ratingreview ? 'checked' : ''}`}
                        onClick={() => handleClickrating(index)}
                    ></span>
                );
            })}
</div>
<p>Describe Your Experience</p>
<textarea name="" id="" rows={4} className='form-control'></textarea>

<div class="d-grid gap-2" disabled={true}>
  <button class="btn btn-primary" type="button">Submit</button>
</div>
</form>
</div>
{/* write review  end */}
      </div>
      {/* col end */}


       {/* col start */}
       <div className="col-lg-6 col-md-12 col-sm-12 col-12">
       <div className="all_reviews_show_main_div">

{/*  */}
<div className="all_review_media_div">
<div className="d-flex">
<div className="flex-shrink-0">
  <img src={reviewimg} alt="" className='img-fluid'/>
</div>
<div className="flex-grow-1 ms-2">
 <div className="all_revie_txt_div">
  <h4>8976XXXXXX</h4>
  <p>
    <span>2024-03-13</span> | <b>5.0</b>
   <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
   <span class="fa fa-star"></span></p>
   <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo, reiciendis neque harum cumque....</p>
 </div>
</div>
</div>
</div>
{/*  */}

{/*  */}
<div className="all_review_media_div">
<div className="d-flex">
<div className="flex-shrink-0">
  <img src={reviewimg} alt="" className='img-fluid'/>
</div>
<div className="flex-grow-1 ms-2">
 <div className="all_revie_txt_div">
  <h4>8976XXXXXX</h4>
  <p>
    <span>2024-03-13</span> | <b>5.0</b>
   <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
   <span class="fa fa-star"></span></p>
   <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo, reiciendis neque harum cumque....</p>
 </div>
</div>
</div>
</div>
{/*  */}


{/*  */}
<div className="all_review_media_div">
<div className="d-flex">
<div className="flex-shrink-0">
  <img src={reviewimg} alt="" className='img-fluid'/>
</div>
<div className="flex-grow-1 ms-2">
 <div className="all_revie_txt_div">
  <h4>8976XXXXXX</h4>
  <p>
    <span>2024-03-13</span> | <b>5.0</b>
   <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
   <span class="fa fa-star"></span></p>
   <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo, reiciendis neque harum cumque....</p>
 </div>
</div>
</div>
</div>
{/*  */}

{/*  */}
<div className="all_review_media_div">
<div className="d-flex">
<div className="flex-shrink-0">
  <img src={reviewimg} alt="" className='img-fluid'/>
</div>
<div className="flex-grow-1 ms-2">
 <div className="all_revie_txt_div">
  <h4>8976XXXXXX</h4>
  <p>
    <span>2024-03-13</span> | <b>5.0</b>
   <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
    <span class="fa fa-star checked"></span>
   <span class="fa fa-star"></span></p>
   <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo, reiciendis neque harum cumque....</p>
 </div>
</div>
</div>
</div>
{/*  */}

</div>
       </div>
      {/* col end */}

    </div>
    {/* nested row end rating */}

   </div>
            {/* col end */}

           
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* product details section end */}

      <SimilarProducts/>
    </>
  );
};
