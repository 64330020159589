import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDecryptedCookie, isAuthenticated } from "../utils/Utils";
import toast, { Toaster } from "react-hot-toast";
import { AddNewAddress, GetAllUserAddresses } from "../api/UserApi";
import { createOrderApi } from "../api/OrderApi";
import axios from "axios";
import { Api_Url } from "../constant/Url";
import logoWhite from "../assets/images/logo/logo-white.png";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { indianStates } from "../Data/IndianStatesData";
import { cartPreCheckoutApi } from "../api/CartApi";
import "../styles/CheckOut.css";

export const CartCheckout = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [ProductDetails, setProductDetails] = useState(null);
  const [checkoutDetails, setCheckoutDetails] = useState(null);
  const [UserAddressData, SetUserAddressData] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState("");
  console.error(checkoutDetails,'selectedAddressIdselectedAddressIdselectedAddressId')
  const [paymentMethod, setPaymentMethod] = useState("COD");
  const [loader, setLoader] = useState(false); // To manage loader visibility

 

  const FetchAllUserAddresses = async () => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const token = userData?.token;
      const mobile = userData?.mobile;
      const requestedData = {
        mobile: mobile,
      };

      const res = await GetAllUserAddresses(requestedData);
      if (res?.status === "success") {
        SetUserAddressData(res?.data);
        setSelectedAddressId(res?.data?.primaryAddress?._id || "");
      }
    } catch (error) {
      setLoader(false); // Hide loader on error
      console.log(error);
    }
  };

  function preparePayload(inputData) {
    return {
      userId: inputData.userId,
      orderItems: inputData.orderItems.map((item) => ({
        ...item,
        productId: item.product, // Replace product key with sendProductId
        product: undefined, // Remove the original product key
      })),
      paymentDetails: inputData.paymentDetails,
      shippingAddress: inputData.shippingAddress,
      totalPrice: inputData.totalPrice,
    };
  }

  const Productcheckout = async (price) => {
    try {
      const userData = getDecryptedCookie("UserData");
      if (!userData || !userData?.userId) {
        throw new Error("User data is missing or invalid");
      }

      // Fetch the Razorpay key from the payment gateway
      const {
        data: { key },
      } = await axios.get(`${Api_Url}/paymentgateway/getkey`);

      // Prepare the order data for payment gateway
      const {
        data: { order },
      } = await axios.post(`${Api_Url}/paymentgateway/checkout`, { price });

      const options = {
        key, // Razorpay key
        amount: order.amount, // Amount in currency subunits (INR - paise)
        currency: "INR", // Currency type
        name: "Balaji Water Purifier", // Name of the business or website
        description: "Test Transaction",
        image: { logoWhite }, // Logo or icon for Razorpay popup
        order_id: order.id, // Order ID from the backend
         callback_url: `${Api_Url}/paymentVerify`, // Backend URL for payment verification
        // callback_url: `${Api_Url}/image/right_image.gif`, // Backend URL for payment verification
        prefill: {
          name: userData?.name || "User Name",
          email: userData?.email || "example@example.com",
          contact: userData?.mobile || "9876543210",
        },
        // ded 
        notes: {
          address: "Nawada" || "Not provided", // Dynamically use the user's address
          userId: userData?._id, // Send userId
         
        },
        theme:{
          color: "#121212", // Customize theme color
        },
        handler: async function (response) {
          try {
            // Validate selected address
            const selectedAddress = UserAddressData.allAddresses.find(
              (addr) => addr._id === selectedAddressId
            );
        
            if (!selectedAddress) {
              toast.error("Selected address is invalid.");
              return;
            }
        
            // Prepare order payload
            const payload = {
              userId: getDecryptedCookie("UserData")?.userId,
              shippingAddress: {
                fullName: selectedAddress.fullName,
                addressLine1: selectedAddress.addressLine1,
                addressLine2: selectedAddress.addressLine2 || "",
                city: selectedAddress.city,
                state: selectedAddress.state,
                country: selectedAddress.country,
                postalCode: selectedAddress.postalCode,
              },
              orderItems: checkoutDetails?.data?.products,
              paymentDetails: {
                method: paymentMethod,
                status: "Completed", // Set as "Pending" initially
              },
              totalPrice: checkoutDetails?.data?.totalDiscountedPrice,
            };
        
            // Prepare final payload (if additional processing is needed)
            const finalPayloadForOrder = preparePayload(payload);
        console.log(finalPayloadForOrder)
            // Make a POST request to verify payment and create an order
            const verifyResponse = await axios.post(`${Api_Url}/paymentgateway/paymentverify`, {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              ...finalPayloadForOrder, 
            });
            console.log(verifyResponse)
        
            if (verifyResponse.status === 200 && verifyResponse.data.success) {
              console.log("Payment verification and order creation successful:", verifyResponse.data);
        
              // Redirect to the payment success page
              window.location.href = "/paymentsuccess";
            } else {
              console.error("Payment verification or order creation failed:", verifyResponse.data);
              toast.error("Payment verification failed. Please contact support.");
            }
          } catch (error) {
            console.error("Error verifying payment:", error);
            toast.error("An error occurred during payment verification. Please try again later.");
          }
        },


        modal: {
          ondismiss: function () {
            setLoader(false); // Hide loader on error
            // This function is executed when the Razorpay popup is closed
            console.log("Razorpay popup closed");
          },
        },
        
      };

      // Open Razorpay for payment
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error("Error during Razorpay checkout:", error);
      alert("An error occurred during checkout. Please try again later.");
    }
  };

  const createOrder = async () => {
    setLoader(true); // Show loader before initializing Razorpay
    if(!isAuthenticated){
      return //  navigate("/login");;
    }
    if (!selectedAddressId) {
      toast.error("Please select an address and ensure product details are loaded.");
      handleAddAddressShow(); // Call the function directly
      return;
    }

    const selectedAddress = UserAddressData.allAddresses.find(
      (addr) => addr._id === selectedAddressId
    );

    if (!selectedAddress) {
      toast.error("Selected address is invalid.");
      return;
    }

    const payload = {
      userId: getDecryptedCookie("UserData")?.userId,
      shippingAddress: {
        fullName: selectedAddress.fullName,
        addressLine1: selectedAddress.addressLine1,
        addressLine2: selectedAddress.addressLine2 || "",
        city: selectedAddress.city,
        state: selectedAddress.state,
        country: selectedAddress.country,
        postalCode: selectedAddress.postalCode,
      },
      orderItems: checkoutDetails?.data?.products,
      paymentDetails: {
        method: paymentMethod,
        status: "Pending",
      },
      totalPrice: checkoutDetails?.data?.totalDiscountedPrice,
    };
    const finalPayloadForOrder=preparePayload(payload);

    try {

      if (paymentMethod == "Online Payment") {
        // If payment method is UPI, proceed with checkout
        const price = parseInt(checkoutDetails?.data?.totalDiscountedPrice); // Get the total price from the order creation response
        await Productcheckout(price); // Pass the price and order ID to Productcheckout function for payment
      } 
      else if (paymentMethod == "COD") {

        const res = await createOrderApi(finalPayloadForOrder);
        if (res?.status === "success") {
          toast.success("Order created successfully!");
          // navigate("/order-success"); // Navigate to a success page
        } else {
          toast.error("Failed to create order.");
        }
        // If payment method is cash on delivery, confirm the order and show confirmation
        toast.success("Order Placed Successfully through COD");
        navigate("/order-confirmation");


      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while creating the order.");
    }
  };


  useEffect(() => {
   
    FetchAllUserAddresses();
    getAllPreCheckout()
  }, []);
  async  function getAllPreCheckout(){
    try {
      
      const userData = getDecryptedCookie("UserData");
   
      const userId = userData?.userId;
      const Id=id;
      const res = await cartPreCheckoutApi({userId:userId,preCheckoutId:id});
      console.log(res,'resygwdfyegfgyef')
      if (res?.status === "success") {
        setCheckoutDetails(res);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const [showAddAddress, setShowAddAddress] = useState(false);

  const handleAddAddressClose = () => setShowAddAddress(false);
  const handleAddAddressShow = () => setShowAddAddress(true);

  const initialValues = {
    mobile: "",
    address: {
      mobile: "",
      fullName: "",
      addressLine1: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    },
  };

  const validationSchema = Yup.object().shape({
    address: Yup.object().shape({
      contactnumber: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]{10}$/, "Invalid mobile number"),
      fullName: Yup.string().required("Full name is required"),
      addressLine1: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      country: Yup.string().required("Country is required"),
      postalCode: Yup.string()
        .required("Postal code is required")
        .matches(/^\d{6}$/, "Postal code must be 6 digits"),
    }),
  });

  const handleAddAddressFormSubmit = async (values, actions) => {
    try {
      const userData = getDecryptedCookie("UserData"); // Decrypted user data
      const userMobile = userData?.mobile;

      const formData = {
        mobile: userMobile, // User's main mobile number
        address: values.address, // Use the `address` object directly
      };

      const res = await AddNewAddress(formData, userMobile);

      if (res?.status === "success") {
        toast.success("Address added successfully!");
        actions.resetForm();
        handleAddAddressClose();
        window.location.reload();
      } else {
        toast.error(res?.message || "Failed to add address.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Toaster />

        {/* breadcrumb section start */}
            <section className="breadcrumb_section">
              {/* container start */}
              <div className="container">
                {/* row start */}
                <div className="row">
                  {/* col start */}
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    {/* breadcrumb main div start */}
                    <div className="breadcrumb_main_div">
                      {/* breadcrumb nav div start */}
                      <nav className="breadcrum_nav">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                          <li className="breadcrumb-item active" aria-current="page"> Checkout</li>
                        </ol>
                      </nav>
                      {/* breadcrumb nav div end */}
                    </div>
                    {/* breadcrumb main div end */}
                  </div>
                  {/* col end */}
                </div>
                {/* row end */}
              </div>
              {/* container end */}
            </section>
            {/*  */}
     
  {/* checkout section start */}
  <section className="checkout_section">
    {/* container start */}
    <div className="container">
      {/* row start */}
      <div className="row">

        {/* col start */}
        <div className="col-lg-7 col-md-6 col-ms-12 col-12">

         {/* checkout product main div start */}
        <div className="checkout_product_main_div">

         {/* add new address div start */}
         <div className="checkout_add_more_address_div">
      <Link to="#" onClick={handleAddAddressShow}>
        <span className="fa fa-plus"></span> Add a new address
      </Link>
    </div>
  
       {/* add new address div end */}

       {/* add new address list div main start */}
       <div className="checkout_add_new_address_list_div_main">
        <h2><span>{UserAddressData?.allAddresses?.length}</span> Delivery Address</h2>
        {/* checkout address flex lists div start */}
       {UserAddressData?.allAddresses?.length>0 && UserAddressData?.allAddresses?.map((ele,index)=>
         <div className="checkout_address_flex_list_div">

         {/* checkout address list checkbox div start */}
         <div className="checkout_address_list_checkbox_div">
        <div className="form-check">
        <input
  className={`form-check-input ${selectedAddressId === ele._id ? "border-primary" : ""}`}
  type="radio"
  name="flexRadioDefault"
  id="flexRadioDefault1"
  checked={selectedAddressId === ele._id}
  onChange={() => setSelectedAddressId(ele._id)} // Ensure this updates the selected ID
/>
      </div>
      </div>
         {/* checkout address list checkbox div end */}
        
          {/* checkout address list div start */}
          <div className="checkout_address_list_div">
          <label className="form-check-label" htmlFor="flexRadioDefault1">
          <p>Deliver to: <b>{ele?.fullName}, {ele?.postalCode}</b> <span>--</span></p>
          <h5>{ele?.city}, {ele?.state}, {ele?.country}</h5>
          </label>
          {/* deliver here button start */}
          <div className="deliver_here_button">
          <button type="button">Deliver Here</button>
          </div>
          {/* deliver here button end */}
          </div>
         {/* checkout address list div end */}

        {/* checkout edit btn div start */}
         <div className="checkout_edit_btn_div">
          <button className="btn btn-md" disabled>Edit</button>
         </div>
         {/* checkout edit btn div end */}

        </div>)}
        {/* checkout address flex lists div end */}

        


       </div>
       {/* add new address list div main end */}
      
      </div>
     {/* checkout product main div end */}
        </div>
        {/* col end */}

         {/* col start */}
         <div className="col-lg-5 col-md-6 col-ms-12 col-12">
           {/* checkout products Price details div start */}
       <div className="checkout_product_price_details_main_div">
        <h1>Price details</h1>
       {/* checkout product price flex div start */}
       <div className="checkout_product_price_flex_div">

        {/* product item div start */}
        <div className="checkout_product_item_div">
          <p>Price (5 items)</p>
          <b>{parseInt(checkoutDetails?.data?.totalDiscountedPrice).toLocaleString()}</b>
        </div>
        {/* product item div end */}

        



         {/* product item div start */}
         <div className="checkout_product_item_div">
          <p>Delivery Charges</p>
          <b> <span className="checkout_deliver_charge">₹80</span> <span className="checkout_delivery_free_charge">Free</span></b>
        </div>
        {/* product item div end */}

         {/* product item div start */}
         <div className="checkout_product_totalprice_div">
          <h3>Total Amount</h3>
          <h5>{parseInt(checkoutDetails?.data?.totalDiscountedPrice).toLocaleString()}</h5>
        </div>
        {/* product item div end */}

        {/* product item div start */}
        <div className="checkout_product_saveprice_div">
          <h3>You will save ₹80 on this order</h3>
        </div>
        {/* product item div end */}


        {/* checkout payment option div start */}
        <div className="checkout_payment_option_div">
          <h4>Payment Options</h4>
          <label htmlFor="paymentMethod" className="form-label">
                        Payment Method
                      </label>
                      <select
                        id="paymentMethod"
                        className="form-select"
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <option value="COD">Cash on Delivery</option>
                        <option value="Online Payment">Online Payment</option>
                      </select>
        <button type="submit" className="btn btn-md"  onClick={createOrder}>Proceed to pay</button>

        </div>
        {/* checkout payment option div end */}

       </div>
       {/* checkout product price flex div end */}



       </div>
       {/* checkout products Price details div end */}
         </div>
        {/* col end */}

      </div>
      {/* row end */}
    </div>
    {/* container end */}
   </section>
   {/* checkout section end */}

   <div>
                  <Modal
                    show={showAddAddress}
                    onHide={handleAddAddressClose}
                    backdrop="static"
                    keyboard={false}
                    size="l"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Add New Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={handleAddAddressFormSubmit}
                        >
                          {({ isSubmitting }) => (
                            <Form>
                              <div className="form_main_div">
                                <div className="form_input_div">
                                  <label>Full Name</label>
                                  <Field
                                    type="text"
                                    name="address.fullName"
                                    placeholder="Enter your full name"
                                    className="Form_Field"
                                  />
                                  <ErrorMessage
                                    name="address.fullName"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>

                                <div className="form_input_div">
                                  <label style={{ fontWeight: "600" }}>
                                    Mobile{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="address.contactnumber"
                                    className="p-0 px-2 m-0"
                                    placeholder="Mobile Number"
                                    length={10}
                                    style={{ height: "40px", width: "100%" }}
                                  />
                                  <ErrorMessage
                                    name="address.contactnumber"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>

                                <div className="form_input_div">
                                  <label>Address Line 1</label>
                                  <Field
                                    type="text"
                                    name="address.addressLine1"
                                    placeholder="Enter your address"
                                    className="Form_Field"
                                  />
                                  <ErrorMessage
                                    name="address.addressLine1"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>

                                <div className="form_input_div">
                                  <label>City</label>
                                  <Field
                                    type="text"
                                    name="address.city"
                                    placeholder="Enter your city"
                                    className="Form_Field"
                                  />
                                  <ErrorMessage
                                    name="address.city"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>

                                <div className="form_input_div">
                                  <label>State</label>
                                  <Field
                                    as="select"
                                    name="address.state"
                                    className="Form_Field"
                                  >
                                    <option value="">Select a State</option>
                                    {indianStates?.map((state) => (
                                      <option key={state} value={state}>
                                        {state}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name="address.state"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>

                                <div className="form_input_div">
                                  <label>Country</label>
                                  <Field
                                    type="text"
                                    name="address.country"
                                    placeholder="Enter your country"
                                    className="Form_Field"
                                  />
                                  <ErrorMessage
                                    name="address.country"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>

                                <div className="form_input_div">
                                  <label>Postal Code</label>
                                  <Field
                                    type="text"
                                    name="address.postalCode"
                                    placeholder="Enter your postal code"
                                    className="Form_Field"
                                  />
                                  <ErrorMessage
                                    name="address.postalCode"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>

                                <button
                                  type="submit"
                                  className="common_button bg-white"
                                  disabled={isSubmitting}
                                >
                                  Create Address
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>

   

      
    </>
  );
};
