import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import {
  FetchAllBanners,
  GetFilteredBanners,
} from "../../api/HomePageBannersApi";
import { Api_Url } from "../../constant/Url";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";

import "../../styles/HomePageBanners.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const HomePageBanners = () => {
  const [DesktopBanners, SetDesktopBanners] = useState();
  const [MobileBanners, SetMobileBanners] = useState();
  const desktopSliderRef = useRef(null);
  const mobileSliderRef = useRef(null);

  const fetchDesktopFilteredBanners = async () => {
    try {
      const filterData = {
        title: "",
        titleSlug: "",
        type: "homepage",
        platform: "desktop",
        status: "active",
      };

      const res = await GetFilteredBanners(filterData);
      if (res?.status === "success") {
        SetDesktopBanners(res?.data);
      } else {
        console.error("Failed to fetch products:", res);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchMobileFilteredBanners = async () => {
    try {
      const filterData = {
        title: "",
        titleSlug: "",
        type: "homepage",
        platform: "mobile",
        status: "active",
      };

      const res = await GetFilteredBanners(filterData);
      if (res?.status === "success") {
        SetMobileBanners(res?.data);
      } else {
        console.error("Failed to fetch products:", res);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchMobileFilteredBanners();
    fetchDesktopFilteredBanners();
  }, []);

  const banners = async () => {
    try {
      const response = await FetchAllBanners();
      if (response?.status === "success") {
        // SetDesktopBanners(response?.data[0]?.DesktopBanners);
        // SetMobileBanners(response?.data[0]?.MobileBanners);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    banners();
  }, []);

  const sliderSettings = {
    dots: true, // Enable custom dots
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // autoplay: true,
    // autoplaySpeed: 3000,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          zIndex: 3,
        }}
      >
        <ul style={{ margin: 0, padding: 0, display: "flex", gap: "1px" }}>
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => <div className="custom-dot-styling"></div>,
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {/* Desktop View */}
      <div className="desktop-view">
        {DesktopBanners?.length > 0 ? (
          <Slider ref={desktopSliderRef} {...sliderSettings}>
            {DesktopBanners.map((ele, index) => (
              <div
                key={index}
                className="img_box_pro_dt d-flex align-items-center justify-content-center"
              >
                <a href={ele?.titleSlug}>
                  <img
                    src={`${Api_Url}${ele?.BannerImage}`}
                    alt={`${Api_Url}${ele?.BannerImage}`}
                    className="img-fluid"
                    loading="lazy"
                  />
                </a>
              </div>
            ))}
          </Slider>
        ) : (
         <Skeleton height={600} width="100%" style={{ margin: "10px 0" }} />
        )}
      </div>


      {/* Mobile View */}
      <div className="mobile-view">
        {MobileBanners?.length > 0 ? (
          <Slider ref={mobileSliderRef} {...sliderSettings} className="px-0">
            {MobileBanners.map((ele, index) => (
              <div
                key={index}
                className="img_box_pro_dt d-flex align-items-center justify-content-center"
              >
                <a href={ele?.titleSlug}>
                  <img
                    src={`${Api_Url}${ele?.BannerImage}`}
                    alt={`${Api_Url}${ele?.BannerImage}`}
                    className="img-fluid"
                    loading="lazy"
                  />
                </a>
              </div>
            ))}
          </Slider>
        ) : (
         <Skeleton height={600} width="100%" style={{ margin: "10px 0" }} />
        )}

      </div>
    </div>
  );
};
