import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetFilteredProducts, AddToCart, GetFilteredProductsCategorywise } from "../../api/ProductsApi";
import { getDecryptedCookie } from "../../utils/Utils";
import toast, { Toaster } from "react-hot-toast";
import { MdOutlineShoppingCart } from "react-icons/md";
import image1 from "../../assets/images/image/off1.jpg";
import { Api_Url } from "../../constant/Url";
import Slider from "react-slick";
import { Loader } from "../layout/Loader";
import { getAllCategories } from "../../api/CategoryApi";
import MyContext from "../../context/myContext";

export const HomeWaterPurifiers = () => {
  const navigate = useNavigate();
  const { handleAddtoCart } = useContext(MyContext);
  const [CategoryData, SetCategoryData] = useState([]);
  const [CategoriesWithProducts, setCategoriesWithProducts] = useState([]);

  const [AllProductsData, setAllProductsData] = useState();

  const fetchFilteredProducts = async () => {
    const filterData = {
      CategoryName: "Water Purifiers",
      SubCategoryName: "",
      priceMin: "",
      priceMax: "",
      shopName: "",
      order: "",
    };

    try {
      const res = await GetFilteredProducts(filterData);
      if (res?.status === "success") {
        // setAllProductsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFilteredProducts();
  }, []);

  // Fetch all categories
  const FetchAllCategories = async () => {
    try {
      const response = await getAllCategories();
      console.log(response?.data, "getAllCategoriesgetAllCategories");
      if (response?.status === "success") {
        SetCategoryData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Fetch products for each category
  const fetchProductsForCategories = async () => {
    try {
      const results = await Promise.all(
        CategoryData.map(async (category) => {
          const filterData = {
            CategoryNameSlug: category.CategoryNameSlug, // Use slug for filtering
            SubCategoryName: "",
            priceMin: "",
            priceMax: "",
            shopName: "",
            order: "",
          };
          console.log(filterData, "filterDatafilterData");
          const response = await GetFilteredProductsCategorywise(filterData);
          if (response?.status === "success" && response?.data?.length > 0) {
            setAllProductsData(response?.data)
            return {
              category,
              products: response.data,
            };
          }
          return null;
        })
      );

      // Filter out categories without products
      const filteredCategories = results.filter((item) => item !== null);

      setCategoriesWithProducts(filteredCategories);
    } catch (error) {
      console.error("Error fetching products for categories:", error);
    }
  };

  useEffect(() => {
    FetchAllCategories();
  }, []);

  useEffect(() => {
    if (CategoryData.length > 0) {
      fetchProductsForCategories();
    }
  }, [CategoryData]);

  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Tablets and small desktops
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const HandleAddToCart = async (productData) => {
    try {
      if (userId === undefined) {
        // //  navigate("/login");;
      }

      const data = {
        productId: productData._id,
        quantity: 1,
        userId: userId,
        token: token,
      };

      await handleAddtoCart(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewProduct = (productId) => {
    navigate(
      `${productId?.CategoryNameSlug}/${productId?.SubCategoryNameSlug}/product-details/${productId?._id}`
    );
  };

  const [wordLimit, setWordLimit] = useState(400);

  // Update word limit based on screen size
  useEffect(() => {
    const updateWordLimit = () => {
      const width = window.innerWidth;

      if (width > 1400) {
        setWordLimit(830);
      } else if (width > 1200 && width <= 1400) {
        setWordLimit(770);
      } else if (width > 1100 && width <= 1200) {
        setWordLimit(335);
      } else if (width > 991 && width <= 1100) {
        setWordLimit(335);
      } else {
        setWordLimit(200);
      }
    };

    // Initialize word limit based on current screen size
    updateWordLimit();

    // Add resize event listener
    window.addEventListener("resize", updateWordLimit);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWordLimit);
    };
  }, []);

  const getShopColor = (shopNameSlug) => {
    const colors = {
      keel: "rgb(34,179,84)",
      aquafresh: "rgb(0,125,189)",
      "grand-plus": "rgb(104,208,247)",
      "royal-aquafresh": "rgb(254,0,0)",
    };

    // Default RGBA color if slug not found
    return colors[shopNameSlug] || "rgba(0, 0, 0, 1)"; // Black as fallback
  };

  return (
    <>
      <Toaster />

      {/* categpry information section start */}
      {CategoriesWithProducts.length > 0 &&
        CategoriesWithProducts.map(({ category, products }, index) => (
          <>
            <section
              className="category_information_section"
              key={category._id}
            >
              {/* conatiner start */}
              <div className="container">
                {/* row start */}
                <div className="row">
                  {index % 2 === 0 ? (
                    <>
                      {/* col start */}
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="category_information_img_box_main">
                          <img
                            src={`${Api_Url}${category?.CategoryImage?.[0]}`}
                            alt=""
                            loading="lazy"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      {/* col end */}

                      {/* col start */}
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="category_information_txt_div_main">
                          <h2>
                            <a href={`/${category?.CategoryNameSlug}`}>
                              {" "}
                              {category.CategoryName}
                            </a>
                          </h2>
                          <p>
                            {category?.CategoryDescription?.slice(0, wordLimit)}
                            ...
                            <a
                              href={`/pages/${category?.CategoryNameSlug}`}
                              className="ps-1 text-primary"
                            >
                              read more
                            </a>
                          </p>
                          <div className="shopnow_btn_div">
                            <a href={`/${category?.CategoryNameSlug}`}>
                              <button className="shop_now_btn">Shop Now</button>{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* col end */}
                    </>
                  ) : (
                    <>
                      {/* col start */}
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="category_information_txt_div_main">
                          <h2>
                            <a href={`/${category?.CategoryNameSlug}`}>
                              {" "}
                              {category.CategoryName}
                            </a>
                          </h2>
                          <p>
                            {category?.CategoryDescription?.slice(0, wordLimit)}
                            ...
                            <a
                              href={`/pages/${category?.CategoryNameSlug}`}
                              className="ps-1 text-primary"
                            >
                              read more
                            </a>
                          </p>
                          <div className="shopnow_btn_div">
                            <a href={`/${category?.CategoryNameSlug}`}>
                              <button className="shop_now_btn">Shop Now</button>{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* col end */}

                      {/* col start */}
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="category_information_img_box_main">
                          <img
                            src={`${Api_Url}${category?.CategoryImage?.[0]}`}
                            alt=""
                            loading="lazy"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      {/* col end */}
                    </>
                  )}
                </div>
                {/* row end */}
              </div>
              {/* conatiner end */}
            </section>
            {/*categpry information section end */}

            {/* category silder start */}
            <section className="category_silder_main_section">
              {/* conatiner start */}
              <div className="container">
                {/* category slider title start*/}
                <div className="category_title_div">
                  <h2>Buy {category.CategoryName}</h2>
                </div>
                {/* category slider title start*/}

                {/* row start */}
                <div className="row">
                  {/* col start */}
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    {/* category slide main div start */}
                    <div className="category_slider_main_div">
                      {products ? (
                        <Slider {...settings}>
                          {products.map((product) => (
                            <div className="product-card" key={product._id}>
                              <div
                                className="card-content"
                                onClick={() => handleViewProduct(product)}
                              >
                                <div className="image-wrapper">
                                  <img
                                    src={`${Api_Url}${product.ProductImage[0]}`}
                                    loading="lazy"
                                    alt=""
                                  />
                                  <div className="mobile_view_discount">
                                    Save {product.DiscountPercentage}%
                                  </div>
                                </div>
                                <div className="product-details">
                                  <h5 className="">
                                    {product?.ProductName?.length > 25
                                      ? product?.ProductName?.slice(0, 20) +
                                        "..."
                                      : product?.ProductName}
                                  </h5>
                                  <div className="deals_txt_div">
                                    <p>
                                      {" "}
                                      {product?.ProductDescription?.length > 25
                                        ? product?.ProductDescription?.slice(
                                            0,
                                            20
                                          ) + "..."
                                        : product?.ProductDescription}
                                    </p>
                                  </div>
                                  <p>
                                    <b>
                                      ₹
                                      {parseInt(
                                        product.PriceAfterDiscount
                                      ).toLocaleString()}
                                    </b>
                                    <span className="px-2">
                                      ₹
                                      {parseInt(
                                        product.TotalPriceWithTax
                                      ).toLocaleString()}
                                    </span>
                                    <b className="discount">
                                      Save {product.DiscountPercentage}%
                                    </b>
                                  </p>

                                  <div className="brand-name-flex">
                                    <div className="brand_name_title">
                                      Brand:{" "}
                                    </div>
                                    <div
                                      className="deal_brand_name_div_txt"
                                      style={{
                                        color: getShopColor(
                                          product.ShopNameSlug
                                        ),
                                      }}
                                    >
                                      {product.ShopName}
                                    </div>
                                  </div>
                                  <div className="product-actions">
                                    <button className="view-details">
                                      View Product
                                    </button>
                                    <button
                                      className="add-to-cart"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        HandleAddToCart(product);
                                      }}
                                    >
                                      <MdOutlineShoppingCart />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <div>
                          <Loader />
                        </div>
                      )}
                    </div>
                    {/* category slide main div end */}
                  </div>
                  {/* col end */}
                </div>
                {/* row end */}
              </div>
              {/* conatiner end */}
            </section>
            {/* category silder section end */}
          </>
        ))}
    </>
  );
};
