import React from 'react'
import { Link } from 'react-router-dom'
import "../styles/Raiseticket.css";
import chatimg from "../assets/images/image/success01.avif";
import chatimg2 from "../assets/images/image/pagenotfound07.avif";
import chaticonimg from "../assets/images/image/chaticon.png";
import chaticonimg2 from "../assets/images/image/chaticon2.png";

const Raiseticket = () => {
  return (
    <>

{/* breadcrumb section start */}
<section className="breadcrumb_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/* breadcrumb main div start */}
              <div className="breadcrumb_main_div">
                {/* breadcrumb nav div start */}
                <nav className="breadcrum_nav">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                   
                    <li className="breadcrumb-item active" aria-current="page">
                      Raise Ticket</li>
                  </ol>
                </nav>
                {/* breadcrumb nav div end */}
              </div>
              {/* breadcrumb main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/*  */}

         {/* Create Ticket section start tckt  */}
         <section className="create_ticket_section">
        <div className="container">
          {/* row start */}
          <div className="row justify-content-center">
            {/* col start */}
            <div className="col-lg-6 col-md-12 col-sm-12">
              {/* craete ticket main div start */}
              <div className="create_ticket_main_div">
                <h1>add ticket</h1>
                <form action="">
                    {/* input box div start */}
                    <div className="input_box_div">
                    <label htmlFor="">Subject*</label>
                   <select className="form-select" aria-label="Default select example">
                     <option selected>Select your issue</option>
                     <option value={1}>Payment Related</option>
                     <option value={2}>Technical issue</option>
                </select>
                </div>
                    {/* input box div end */}

                     {/* input box div start */}
                     <div className="input_box_div">
                     <label htmlFor="">Description *</label>
                   <textarea className='form-control' name="" rows={7}></textarea>
                   </div>
                    {/* input box div end */}

                     {/* input box button div start */}
                     <div className="input_box_btn_div">
                        <button type='submit' className='btn btn-md'>Create Ticket</button>
                     </div>
                     {/* input box button div end */}



                </form>
              </div>
              {/* craete ticket main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
      </section>
      {/* Create Ticket section end tckt  */}
    </>
  )
}

export default Raiseticket
