import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import orderimg from "../assets/images/image/keelproduct4.jpeg";
import { getSingleOrderApi } from '../api/UserApi';
import { Api_Url } from '../constant/Url';

const OrderDetails = () => {
  const {orderId} = useParams();
    const [currentStep, setCurrentStep] = useState(2);
    const [orderDetailsData, setOrderDetailsData] = useState(null);
console.log(orderDetailsData,'orderDetailsDataorderDetailsDataorderDetailsData')
const steps = ["Order Confirmed", "Shipped", "Out for delivery", "Delivery"];
const stptime = ["Sun, 19th Jan", "Thu, 23rd Jan", "", "Expected by Sun, 26th"];

// mobile view order tracker start
const [mobilecurrentStep, setmobileCurrentStep] = useState(1); // Change this to set the current status

   const mobilesteps = ["Order Confirmed", "Shipped", "Out for delivery", "Delivery"];
   const mobilestptime = ["Sun, 19th Jan", "Thu, 23rd Jan", "", "Expected by Sun, 26th"];

async function getSingleorderApi(orderId) {
  const res=await getSingleOrderApi(orderId);
  setOrderDetailsData(res?.data);
}
useEffect(() => {
    
    getSingleorderApi(orderId);
}, []);
return (
<>

{/* breadcrumb section start */}
<section className="breadcrumb_section">
{/* container start */}
<div className="container">
{/* row start */}
<div className="row">
{/* col start */}
<div className="col-lg-12 col-md-12 col-sm-12 col-12">
{/* breadcrumb main div start */}
<div className="breadcrumb_main_div">
{/* breadcrumb nav div start */}
<nav className="breadcrum_nav">
    <ol className="breadcrumb">
    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
    <li className="breadcrumb-item"><Link to="">My Account</Link></li>
    <li className="breadcrumb-item"><Link to="">My Orders</Link></li>
    <li className="breadcrumb-item active" aria-current="page">OBT333414100876541001 </li>
    </ol>
</nav>
{/* breadcrumb nav div end */}
</div>
{/* breadcrumb main div end */}
</div>
{/* col end */}
</div>
{/* row end */}
</div>
{/* container end */}
</section>
{/*  */}


{/* order deatils sectyion start */}
<section className="order_details_section">
{/* container start */}
<div className="container">
{/* row start */}
<div className="row">


{/* col start */}
<div className="col-lg-12 col-md-12 col-sm-12 col-12">
    {/* order details delivery tracker div start */}
    <div className="order_details_delivery_tracker_main_div">
        {/* order details delivery tracker flex div start */}
      <div className="order_details_delivery_tracker_flex_div">

            {/* order deatils products media object main div start */}
          <div className="order_details_products_media_object_main_div">
          
           { orderDetailsData?.orderItems?.map((ele)=>
           <div className="d-flex">
                <div className="flex-shrink-0">
                   <img src={`${Api_Url}${ele?.image[0]}`} alt="order image" className='img-fluid'/>
               </div>
          <div className="flex-grow-1 ms-2">
           <div className="order_dt_delivery_main_div">
           <h3> <Link to="">{ele?.name}</Link></h3>
           <p>quantity:{ele?.quantity}</p>
           <h5>₹{ele?.totalItemPrice}</h5>
           </div>
        </div>
        
       </div>)}
            {/* multiple products list end */}
           
            
     
   
        </div>
        
        
    {/* order deatils products media object main div end */}
    
     
     {/* order tracker status div start */}
     <div className="order_tracker_status_div desktop_tracker_div">
     <div className="order-tracker">
      <div className="tracker-container">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`tracker-step ${
              index <= currentStep ? "completed" : ""
            }`}
          >
            <div className="tracker-circle"></div>
            <div className="tracker-label2">{stptime[index]}</div>
            <div className="tracker-label">{step}</div>
          </div>
        ))}
      </div>
    </div>
     </div>
     {/* order tracker status div end */}

      {/* Mobile order tracker status div start */}
<div className="mobile_tracker_div">
  <div className="mobileorder-tracker">
    {mobilesteps.map((step, index) => (
      <div key={index} className="mobilestep">
        <div
          className={`mobilecircle ${index <= mobilecurrentStep ? "mobileactive" : ""}`}
        >
          {/* {index + 1} */}
        </div>
        <div className="mobileinfo">
          <div
            className={`mobilelabel ${index <= mobilecurrentStep ? "mobileactive-label" : ""}`}
          >
            {step}
          </div>
          <div  className={`mobiletime ${index <= mobilecurrentStep ? "mobileactive-time" : ""}`}>
            {stptime[index]} 
          </div>
        </div>
        {index < mobilesteps.length - 1 && (
          <div
            className={`mobileline ${index < mobilecurrentStep ? "mobileactive-line" : ""}`}
          ></div>
        )}
      </div>
    ))}
  </div>
</div>
{/* Mobile order tracker status div end */}

      {/* order change cancel div start */}
      <div className="order_change_cancel_div">
        <Link to=""> <span className='fa fa-truck'></span> Change Date</Link>
        <Link to=""> <span className='fa fa-x'></span> Cancel</Link>
        <Link to=""> <span className='fa fa-comments'></span> Chat with us</Link>
     </div>
     {/* order  change cancel div end */}


        </div>
        
        
        {/* order details delivery tracker flex div end */}
    </div>
    {/* order details delivery tracker div end */}
</div>
{/* col end */}


{/* col start */}
<div className="col-lg-6 col-md-12 col-sm-12 col-12">
    {/* order deatils delivery address div start */}
    <div className="order_details_delivery_address_div">
        {/* order_details_title_div delivery div start */}
        <div className="order_details_title_div_delivery">
            <h2>Delivery Address</h2>
        </div>
        {/* order_details_title_div delivery div end */}

        {/* order deatils delivery address div start */}
        <div className="order_details_delivery_address_flex_main_div">
        {/* order flex address txt div start*/}
        <div className="order_details_delivery_address_flex_txt_div">
        <h4>{orderDetailsData?.shippingAddress?.fullName}</h4>
        <p>{orderDetailsData?.shippingAddress?.city},{orderDetailsData?.shippingAddress?.city},{orderDetailsData?.shippingAddress?.country}</p>
        </div>
        {/* order flex address txt div end*/}

        {/* order  address change address div start*/}
        <div className="order_address_change_btn_div">
        <Link to="">Change</Link>
        </div>
        {/* order  address change address div end*/}

        </div>
        {/* order deatils delivery address div end */} 

        {/* order details delivery number div start */}
        <div className="order_details_delivery_number_flex_main_div">

            {/* order deatils numbers div start */}
            <div className="order_details_delivery_number_flex_txt_div">
            <h5>Phone number</h5>
            <p>{orderDetailsData?.userMobile}</p>
            </div>
            {/* order deatils numbers div end */}

             {/* order   change number div start*/}
        <div className="order_number_change_btn_div">
        <Link to="">Change</Link>
        </div>
        {/* order   change number div end*/}

        </div>
        {/* order details delivery number div end */}
       
       {/* order tracked by div start */}
       <div className="order_tracked_by_div">
        <p>This order is also tracked by <b>{orderDetailsData?.orderId}</b> </p>
       </div>
       {/* order tracked by div end */}
        
    </div>
    {/* order deatils delivery address div end */}
</div>
{/* col end */}

</div>
{/* row end */}
</div>
{/* container end */}
</section>
{/* order deatils sectyion end */}
</>
)
}

export default OrderDetails
