import React from 'react'

const VerifyPayment = () => {
  return (
    <div>
        VerifyPaymentVerifyPaymentVerifyPayment
      
    </div>
  )
}

export default VerifyPayment
