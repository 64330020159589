import { Api_Url } from "../constant/Url";

export const FetchAllCategoriesWithSubCategories = async () => {
  try {
    const res = await fetch(
      `${Api_Url}/category/getcategorieswithsubcategories`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
  }
};

export const FetchAllSubCategoriesWithCategories = async (data) => {
  try {
    const res = await fetch(
      `${Api_Url}/subcategory/getSubCategoriesByCategory`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // Ensure data is being passed correctly
      }
    );

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
  }
};


export const getAllCategories = async (data) => {
  try {
    const res = await fetch(
      `${Api_Url}/category/getallcategory`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // Ensure data is being passed correctly
      }
    );

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
  }
};


export const GetSingleCategoryApi = async (CategoryNameSlug) => {
  try {
    const res = await fetch(
      `${Api_Url}/category/getsingleCategorySlug/${CategoryNameSlug}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message: "An error occurred while fetching the Products.",
    };
  }
};
