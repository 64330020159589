import React from "react";
import { LatestBlogs } from "../components/homePageComponents/LatestBlogs";
import { OtherWayToBuy } from "../components/homePageComponents/OtherWayToBuy";
import { OurBrands } from "../components/homePageComponents/OurBrands";
import { Testimonials } from "../components/homePageComponents/Testimonials";
import { ShopBanners } from "../components/homePageComponents/ShopBanners";
import { NewArrivals } from "../components/homePageComponents/NewArrivals";
import { HomePageBanners } from "../components/homePageComponents/HomePageBanners";
import { ShopByCategory } from "../components/homePageComponents/ShopByCategory";
import { HomeWaterPurifiers } from "../components/homePageComponents/HomeWaterPurifiers";
import { OurProductsCategory } from "../components/homePageComponents/OurProductsCategory";
import { Deals } from "./Deals";

export const DashBoard = () => {
  return (
    <div>
      <HomePageBanners />
      <ShopByCategory />
      <Deals />
      <HomeWaterPurifiers />
      <OtherWayToBuy />
      <ShopBanners />
      <NewArrivals />
      <ShopBanners />
      <OurProductsCategory />
      <OurBrands />
      <Testimonials />
      {/* <LatestBlogs /> */}
    </div>
  );
};
