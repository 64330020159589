import React, { useEffect, useState } from "react";
import "../styles/Payment.css";
import { useNavigate } from "react-router-dom";

export const PaymentSuccess = () => {
  const [countdown, setCountdown] = useState(5); // Countdown starts from 5 seconds
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer); // Clear the timer when it reaches 1
          navigate("/orders"); // Redirect to the order page
          return 0;
        }
        return prev - 1; // Decrease the countdown
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup the timer on component unmount
  }, [navigate]);

  return (
    <div className="payment_div_div">
      <div
        style={{ height: "80vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div>
          <div
            className="swal2-icon swal2-success swal2-animate-success-icon"
            style={{ display: "flex" }}
          >
            <div
              className="swal2-success-circular-line-left"
              style={{ backgroundColor: "rgb(255, 255, 255)" }}
            ></div>
            <span className="swal2-success-line-tip"></span>
            <span className="swal2-success-line-long"></span>
            <div className="swal2-success-ring"></div>
            <div
              className="swal2-success-fix"
              style={{ backgroundColor: "rgb(255, 255, 255)" }}
            ></div>
            <div
              className="swal2-success-circular-line-right"
              style={{ backgroundColor: "rgb(255, 255, 255)" }}
            ></div>
          </div>

          <div className="d-flex justify-content-center">
            <h3 className="text-gray-600 my-2">
              Thank you for completing your secure online payment.
            </h3>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <p className="text-muted">
              Redirecting to your order page in {countdown} second
              {countdown > 1 ? "s" : ""}.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
