import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetSingleCategoryApi } from "../api/CategoryApi";
import { Api_Url } from "../constant/Url";

export const CategoryPage = () => {
  const { CategoryNameSlug } = useParams();

  const [CategoryData, SetCategoryData] = useState([]);

  console.log(CategoryNameSlug);

  const fetchCategoryDetails = async () => {
    try {
      const res = await GetSingleCategoryApi(CategoryNameSlug);
      if (res?.status === "success") {
        SetCategoryData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategoryDetails();
  }, []);

  return (
    <>
    {/* category page details section start */}
    <div className="category_page_details_section">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row">
          {/* col start */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            {/* category page detail img box start */}
            <div className="category_page_detail_img_box">
               <img src={`${Api_Url}/${CategoryData?.CategoryImage?.[0]}`} alt={CategoryData?.CategoryName} className="img-fluid"/>
            </div>
            {/* category page detail img box end */}
          </div>
          {/* col end */}

            {/* col start */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              {/* category page detail content box start */}
              <div className="category_page_detail_content_box">
                <h1>{CategoryData?.CategoryName}</h1>
                <p>{CategoryData?.CategoryDescription}</p>
              </div>
              {/* category page detail content box end */}
            </div>
          {/* col end */}

        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </div>
    {/* category page details section end */}
    </>
  );
};
