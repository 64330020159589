import React from 'react'
import { Link } from 'react-router-dom'
import "../styles/Replyticket.css";
import chatimg from "../assets/images/image/success01.avif";
import chatimg2 from "../assets/images/image/pagenotfound07.avif";
import chaticon3 from "../assets/images/image/chaticon3.png";
import chaticonimg2 from "../assets/images/image/chaticon2.png";

const Replyticket = () => {
  return (
    <>

{/* breadcrumb section start */}
<section className="breadcrumb_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/* breadcrumb main div start */}
              <div className="breadcrumb_main_div">
                {/* breadcrumb nav div start */}
                <nav className="breadcrum_nav">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                   
                    <li className="breadcrumb-item active" aria-current="page">
                      Reply Ticket</li>
                  </ol>
                </nav>
                {/* breadcrumb nav div end */}
              </div>
              {/* breadcrumb main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/*  */}

         {/* chat box section start tckt  */}
         <section className="tckt_chat_box">
        <div className="container">
          {/* row start */}
          <div className="row justify-content-center">
            {/* col start */}
            <div className="col-lg-10 col-md-12 col-sm-12">
              <div className="fixed_main_div">
                {/*  */}
                <div className="tckt_chat_box_main_div">
                  {/* tckt msg recevied start */}
                  <div className="tckt_msg_recevied_main_div">
                    <div className="receviedmsg_txt">
                    Hello, This side Rinku Singh
                    </div>
                  </div>
                  {/* tckt msg recevied end */}

                  {/* tckt msg recevied start */}
                  <div className="tckt_msg_recevied_main_div">
                    <div className="receviedmsg_img">
                      <img src={chatimg} alt="" className="img-fluid" />
                    </div>
                  </div>
                  {/* tckt msg recevied end */}

                  {/* tckt msg send start */}
                  <div className="tckt_msg_send_main_div">
                    <div className="sendmsg_txt">Where do you live</div>
                  </div>
                  {/* tckt msg send end */}

                  {/* tckt msg send start */}
                  <div className="tckt_msg_send_main_div">
                    <div className="sendmsg_img">
                    <img src={chatimg2} alt="" className="img-fluid" />

                    </div>
                  </div>
                  {/* tckt msg send end */}
                </div>

              {/* chat footer input */}
<div className="chat-footer">
  <input type="text" className="form-control" placeholder="Type a message"/>
  <input type="file" id="imageUpload" className='image_upload_input' accept="image/*"/>
  <label htmlFor="imageUpload" className='image_upload_label'>
  <img src={chaticonimg2} alt="" className='img-fluid'/>
  </label>
  <button type="submit">Send</button>
</div>
{/* chat footer input */}

              </div>
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
      </section>
      {/* chat box section end tckt  */}
    </>
  )
}

export default Replyticket
