import React, { useState } from "react";
import Slider from "react-slick";
import amazon from "../../assets/images/online/amazon.png";
import flipkart from "../../assets/images/online/flipkart.png";
import royalaquafresh from "../../assets/images/brands/royalaquafresh.png";
import aquafresh from "../../assets/images/brands/aquafresh.png";
import keel from "../../assets/images/brands/keel.png";
import grandplus from "../../assets/images/brands/grandplus.png";

export const OtherWayToBuy = () => {
  const [activeSlide, setActiveSlide] = useState("Amazon");

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,

    beforeChange: function (currentSlide, nextSlide) {
      const nextActive = nextSlide === 0 ? "Amazon" : "Flipkart";
      setActiveSlide(nextActive);
    },
  };

  return (
   <>
   {/* other way to buy section start */}
   <section className="otherwayto_section">
    {/* container start */}
    <div className="container">

      {/* other way buy title start */}
      <div className="otherwayto_title">
       <h2>Other Way To Buy Via Brands</h2>
      </div>
      {/* other way buy title end */}

      {/* row start */}
      <div className="row">
        {/* col start */}
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          {/* main div slider otherway buy start */}
          <div className="otherway_buy_slider_main_div">
            {/* slider start */}
            <Slider {...settings}>
              {/* slider row start */}
               <div className="row">
                {/* flex slider div start */}
                <div className="flex_slider_main_div">
                {/* slider col start */}
                <div className="col">
                  <div className="otherbuy_online_logo">
                   <img src={amazon} alt="Amazon" loading="lazy" className="img-fluid"/>
                  </div>
                </div>
                {/* slider col end */}

               {/* slider col start */}
                 <div className="col">
                  <div className="otherbuy_balaji_logo">
                  <a href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank" rel="noopener noreferrer">
                    <img src={aquafresh} alt="Aqua Fresh" loading="lazy" className="img-fluid aquafresh"/>
                  </a>
                  </div>
                </div>
                {/* slider col end */}

                 {/* slider col start */}
                 <div className="col">
                  <div className="otherbuy_balaji_logo">
                  <a href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank" rel="noopener noreferrer">
                    <img src={grandplus} alt="grandplus" loading="lazy"
                      className="img-fluid grandplus"/>
                  </a>
                  </div>
                </div>
                {/* slider col end */}

                 {/* slider col start */}
                 <div className="col">
                  <div className="otherbuy_balaji_logo">
                  <a href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank" rel="noopener noreferrer">
                    <img src={royalaquafresh} alt="royal aqua fresh" loading="lazy"
                      className="img-fluid royalaqua"/>
                  </a>
                  </div>
                </div>
                {/* slider col end */}

                 {/* slider col start */}
                 <div className="col">
                  <div className="otherbuy_balaji_logo">
                  <a href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank" rel="noopener noreferrer">
                    <img src={keel} alt="keel" className="img-fluid keel" loading="lazy"/>
                  </a>
                  </div>
                </div>
                {/* slider col end */}
                </div>
                {/* flex slider div end */}
               </div>
              {/* slider row end */}

              {/* slider row start */}
              <div className="row">
                {/* flex slider div start */}
                <div className="flex_slider_main_div">
                {/* slider col start */}
                <div className="col">
                  <div className="otherbuy_online_logo">
                   <img src={flipkart} alt="Amazon" loading="lazy" className="img-fluid"/>
                  </div>
                </div>
                {/* slider col end */}

               {/* slider col start */}
                 <div className="col">
                  <div className="otherbuy_balaji_logo">
                  <a href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank" rel="noopener noreferrer">
                    <img src={aquafresh} alt="Aqua Fresh" loading="lazy" className="img-fluid aquafresh"/>
                  </a>
                  </div>
                </div>
                {/* slider col end */}

                 {/* slider col start */}
                 <div className="col">
                  <div className="otherbuy_balaji_logo">
                  <a href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank" rel="noopener noreferrer">
                    <img src={grandplus} alt="grandplus" loading="lazy"
                      className="img-fluid grandplus"/>
                  </a>
                  </div>
                </div>
                {/* slider col end */}

                 {/* slider col start */}
                 <div className="col">
                  <div className="otherbuy_balaji_logo">
                  <a href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank" rel="noopener noreferrer">
                    <img src={royalaquafresh} alt="royal aqua fresh" loading="lazy"
                      className="img-fluid royalaqua"/>
                  </a>
                  </div>
                </div>
                {/* slider col end */}

                 {/* slider col start */}
                 <div className="col">
                  <div className="otherbuy_balaji_logo">
                  <a href="https://www.amazon.in/s?k=aqua+fresh+water+purifier"
                    target="_blank" rel="noopener noreferrer">
                    <img src={keel} alt="keel" className="img-fluid keel" loading="lazy"/>
                  </a>
                  </div>
                </div>
                {/* slider col end */}
                </div>
                {/* flex slider div end */}
               </div>
              {/* slider row end */}

            </Slider>
            {/* slider end */}
          </div>
          {/* main div slider otherway buy end */}
        </div>
        {/* col end */}
      </div>
      {/* row end */}
    </div>
    {/* container end */}
   </section>
   {/* other way to buy section end */}
   </>
  )
};
