import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../components/layout/Footer";
import { ScrollToTop } from "../components/layout/ScrollToTop";
import { Header } from "../components/layout/Header";
import Breadcrum from "../components/breadcrum/Breadcrum";

export const Home = () => {
  return (
    <div>
      <Header />
      {/* <Breadcrum/> */}
      <div>
        <Outlet/>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};
