import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineShoppingCart } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import Slider from "react-slick";
import { getDecryptedCookie } from "../utils/Utils";
import { Api_Url } from "../constant/Url";
import dummyproduct from "../assets/images/static/dummyproduct.jpg";

import { AddToCart, GetFilteredProducts } from "../api/ProductsApi";
import { Loader } from "../components/layout/Loader";

export const SimilarProducts = () => {
  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;
const navigate = useNavigate();
  const [AllProductsData, SetProductsData] = useState();

  const { CategoryNameSlug, SubCategoryNameSlug } = useParams();

  const fetchFilteredProducts = async () => {
    try {
      const decodedCategoryName = decodeURIComponent(CategoryNameSlug || "");

      const filterData = {
        CategoryName: decodedCategoryName || "",
      };

      const res = await GetFilteredProducts(filterData);

      if (res?.status === "success") {
        SetProductsData(res?.data);
      } else {
        console.error("Failed to fetch products:", res);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchFilteredProducts();
  }, [CategoryNameSlug, SubCategoryNameSlug]);

  useEffect(() => {
    fetchFilteredProducts();
  }, []);

  const getShopColor = (shopNameSlug) => {
    const colors = {
      keel: "rgb(34,179,84)",
      aquafresh: "rgb(0,125,189)",
      "grand-plus": "rgb(104,208,247)",
      "royal-aquafresh": "rgb(254,0,0)",
    };

    // Default RGBA color if slug not found
    return colors[shopNameSlug] || "rgba(0, 0, 0, 1)"; // Black as fallback
  };
  
  const handleAddToCart = async (productData) => {
    try {
      const data = {
        productId: productData._id,
        quantity: 1,
        userId: userId,
      };

      const res = await AddToCart(data, token);

      if (res?.status === "success") {
        toast.success(res?.message);
        window.location.reload();
      } else {
        toast.error("Error in Add to Product");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewProduct = (productId) => {
    navigate(
      `${productId?.CategoryNameSlug}/${productId?.SubCategoryNameSlug}/product-details/${productId?._id}`
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3300,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Tablets and small desktops
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
<>
<Toaster />
{/* similar products section start */}
<section className="similar_section_main">
  {/* container start */}
  <div className="container">
    {/* similar products title start */}
    <div className="similar_title">
    <h2>Similar Products</h2>
    </div>
    {/* similar products title end */}
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-12">
        {/* similar products slider main div start */}
        <div className="similar_products_slider_main">
        {AllProductsData ? (
                        <Slider {...settings}>
                          {AllProductsData.map((product) => (
                            <div className="product-card" key={product._id}>
                              <div
                                className="card-content"
                                onClick={() => handleViewProduct(product)}
                              >
                                <div className="image-wrapper">
                                  <img
                                    src={`${Api_Url}${product.ProductImage[0]}`}
                                    loading="lazy"
                                    alt=""
                                  />
                                   <div className="mobile_view_discount">
                                      Save {product.DiscountPercentage}%
                                    </div>
                                </div>
                                <div className="product-details">
                                  <h5 className="">
                                    {product?.ProductName?.length > 25
                                      ? product?.ProductName?.slice(0, 20) +
                                        "..."
                                      : product?.ProductName}
                                  </h5>
                                  <div className="deals_txt_div">
                                    <p>
                                      {" "}
                                      {product?.ProductDescription?.length > 25
                                        ? product?.ProductDescription?.slice(
                                            0,
                                            20
                                          ) + "..."
                                        : product?.ProductDescription}
                                    </p>
                                  </div>
                                  <p>
                                    <b>
                                      ₹
                                      {parseInt(
                                        product.PriceAfterDiscount
                                      ).toLocaleString()}
                                    </b>
                                    <span className="px-2">
                                      ₹
                                      {parseInt(
                                        product.TotalPriceWithTax
                                      ).toLocaleString()}
                                    </span>
                                    <b className="discount">
                                      Save {product.DiscountPercentage}%
                                    </b>
                                  </p>

                                  <div className="brand-name-flex">
                                    <div className="brand_name_title">
                                      Brand:{" "}
                                    </div>
                                    <div className="deal_brand_name_div_txt" style={{
                                color: getShopColor(product.ShopNameSlug),
                              }}>
                                      {product.ShopName}
                                    </div>
                                  </div>
                                  <div className="product-actions">
                                    <button className="view-details">
                                      View Product
                                    </button>
                                    <button
                                      className="add-to-cart"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddToCart(product);
                                      }}
                                    >
                                      <MdOutlineShoppingCart />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <div>
                          <Loader />
                        </div>
                      )}
        </div>
        {/* similar products slider main div end */}
      </div>
      {/* col end */}
    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* similar products section end */}
</>
     

      
  );
};
