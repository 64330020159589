import React from "react";
import { TfiFaceSad } from "react-icons/tfi";
import notfound from "../assets/images/image/pagenotfound06.png";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <>
    {/* notfound section start */}
    <section className="notfound_section">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row justify-content-center">
          {/* col start */}
          <div className="col-lg-7 col-md-10 col-sm-12 col-12">
            {/* notfound image div start */}
            <div className="notfound_image_div">
              <img src={notfound} className="img-fluid" alt="notfound"/>
              <h1 className="notfound_heading"><Link to="/"> <span className="fa fa-home"></span> Back to Home</Link></h1>
            </div>
            {/* notfound image div end */}
          </div>
          {/* col end */}
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
    {/* notfound section end */}
    </>
  );
};
