import React, { useContext, useEffect, useState } from "react";
import userProfileDummyPhoto from "../../assets/images/icons/user.png";
import { getDecryptedCookie, isAuthenticated } from "../../utils/Utils";
import Cookies from "js-cookie";
import { Api_Url } from "../../constant/Url";
import { GetUserProfile } from "../../api/UserApi";
import { getallSocialMedia } from "../../api/SocialMediaApi";
import MyContext from "../../context/myContext";
import { Link } from "react-router-dom";

export const Sidenavbar = () => {
  const [selectedItem, setSelectedItem] = useState("");
  const { userData,userProfile,cartData} = useContext(MyContext);
  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleLogout = () => {
    Cookies.remove("UserData");
    window.location.href = "/";
  };

  const [SocialMediaData, SetSocialMediaData] = useState([]);

  const FetchAllSocialMedia = async () => {
    try {
      const response = await getallSocialMedia();
      if (response?.status === "success") {
        SetSocialMediaData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // User Profile

  useEffect(() => {
    userProfile();
    FetchAllSocialMedia();
  }, []);

  return (
    <>
      {/* side bar nav main div start */}
      <div className="sidebar_main_div">
        <div
          className="offcanvas offcanvas-start d-block d-lg-none"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabIndex="-1"
          id="sidenavbar"
          aria-labelledby="sidenavbarLabel"
        >
          {/* start header */}
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
              {userData ? (
                <div className="d-flex align-items-center">
                  <div>
                    {userData?.ProfilePhoto ? (
                      <div>
                        <img
                          src={`${Api_Url}${userData?.ProfilePhoto}`}
                          alt=""
                          className="userProfilePhoto"
                        />
                      </div>
                    ) : (
                      <div>
                        <img src={userProfileDummyPhoto} alt="" />
                      </div>
                    )}
                  </div>

                  <div
                    className="ps-1 text-white"
                    style={{ fontWeight: "700" }}
                  >
                    <a href="/userprofile" className="text-white">
                    {userData?.name && userData?.mobile
                      ? userData?.name
                      : userData?.mobile}</a>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <a href="/login">
                    <img src={userProfileDummyPhoto} alt="" />
                    <span className="ps-1">SIGN IN/SIGN UP</span>
                  </a>
                </div>
              )}
            </h5>
            <button
              type="button"
              className="fa fa-times"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          {/* end haeder */}

          {/* offcanvas body start */}
          <div className="offcanvas-body ">
            {/* sidebar navlink div start */}
            <div className="sidenav_navlink_div">
              <ul>
                <li>
                  <a
                    href="/"
                    className={selectedItem === "home" ? "active" : ""}
                    onClick={() => handleItemClick("home")}
                  >
                    <i className="bx bx-home"></i> Home
                  </a>
                </li>
                <li>
                    <a href="/cart">
                      <i className="bx bxs-shopping-bags"></i> Cart ({cartData?.length})
                    </a>
                  </li>

              { isAuthenticated && <li>
                    <a href="/orders">
                      <i className="bx bxs-shopping-bags"></i> Orders
                    </a>
                  </li>}
                <li>
                  <a href="/aboutus">
                    <i className="bx bx-book-reader"></i> About Us
                  </a>
                </li>

                {/* Water Purifiers li */}
                <div className="categories_div_main">
                  <li>
                    <a href="/">
                      <i className="bx bx-category-alt"></i>
                      Water Purifiers{" "}
                    </a>{" "}
                    <span
                      data-bs-toggle="collapse"
                      data-bs-target="#categories-menu01"
                      className="bx bx-chevron-down"
                    />
                  </li>
                  <div className="collapse" id="categories-menu01">
                    <div className="card card-body categories_card_body">
                      <ul className="categories_ul_list">
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Water Purifier
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> RO Water Purifier
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Water Filter
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> UV Water Purifier
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Gravity Water
                            Purifier
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Commercial Water
                            Purifier
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/*Water Purifiers drop li end */}

                {/* Vacuum Cleaners li */}
                <div className="categories_div_main">
                  <li>
                    <a href="/">
                      <i className="bx bx-category-alt"></i>
                      Vacuum Cleaners{" "}
                    </a>{" "}
                    <span
                      data-bs-toggle="collapse"
                      data-bs-target="#categories-menu02"
                      className="bx bx-chevron-down"
                    />
                  </li>
                  <div className="collapse" id="categories-menu02">
                    <div className="card card-body categories_card_body">
                      <ul className="categories_ul_list">
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Wiz Handheld
                            Vacuum Cleaner
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Wet & Dry Vacuum
                            Cleaner. (Launching Soon)
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/*Vacuum Cleaners drop li end */}

                {/* Kitchen Appliances li */}
                <div className="categories_div_main">
                  <li>
                    <a href="/">
                      <i className="bx bx-category-alt"></i>
                      Kitchen Appliances{" "}
                    </a>{" "}
                    <span
                      data-bs-toggle="collapse"
                      data-bs-target="#categories-menu03"
                      className="bx bx-chevron-down"
                    />
                  </li>
                  <div className="collapse" id="categories-menu03">
                    <div className="card card-body categories_card_body">
                      <ul className="categories_ul_list">
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Mixer Grinder
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Egg Boiler
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/*Kitchen Appliances drop li end */}

                {/* Water Dispensers li */}
                <div className="categories_div_main">
                  <li>
                    <a href="/">
                      {" "}
                      <i className="bx bx-category-alt"></i>
                      Water Dispensers{" "}
                    </a>{" "}
                    <span
                      data-bs-toggle="collapse"
                      data-bs-target="#categories-menu04"
                      className="bx bx-chevron-down"
                    />
                  </li>
                  <div className="collapse" id="categories-menu04">
                    <div className="card card-body categories_card_body">
                      <ul className="categories_ul_list">
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Hot & Normal
                            (Omega)
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Hot, Normal & Cold
                            (Launching Soon)
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Hot, Normal, Cold
                            & Cooling Cabinet (Launching Soon)
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="bx bx-droplet"></i> Hot, Normal, Cold
                            with RO.(Launching Soon)
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/*Water Dispensers drop li end */}

                <li>
                  <a href="/sitemap">
                    <i className="bx bx-sitemap"></i> Sitemap
                  </a>
                </li>

                <li>
                  <a href="/contactus">
                    <i className="bx bxs-contact"></i> Contact Us
                  </a>
                </li>
              </ul>
            </div>
            {/* sidebar navlink div end*/}

            {/* side bar profile link start  */}
            <div className="sidebar_profile_div_main">
              {userData && (
                <ul>
                  <li>
                    <a href="/userprofile">
                      <i className="bx bxs-user-detail"></i> My Profile
                    </a>
                  </li>
                </ul>
              )}
            </div>
            {/* side bar profile link end */}

            {/* side bar terms link start  */}
            <div className="sidebar_terms_div_main">
              <ul>
                <li>
                  <a href="/termsconditions">
                    <i className="bx bx-file"></i> Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="/returnrefundpolicy">
                    <i className="bx bx-money"></i> Refund & Cancellation
                  </a>
                </li>
                <li>
                  <a href="/privacypolicy">
                    <i className="bx bx-check-shield"></i> Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="/disclaimer">
                    <i className="bx bx-check-shield"></i> Disclaimer
                  </a>
                </li>
              </ul>
            </div>

            <div className="sidebar_terms_div_main">
              <ul>
               {isAuthenticated && <li onClick={handleLogout}>
                  <a href="">
                    <i className="bx bx-log-in"></i> Logout
                  </a>
                </li>}

                <li>
                  <a href="/termsconditions">
                    <i className="bx bx-file"></i> Follow Us:
                  </a>
                </li>

                <div className="d-flex px-3">
                  {SocialMediaData?.map((ele, index) => {
                    return (
                      <div className="d-flex" key={index}>
                        <a
                          href={`${ele?.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={`${Api_Url}${ele?.image}`}
                            alt=""
                            style={{ height: "42px", width: "40px" }}
                          ></img>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </ul>
            </div>
            {/* side bar terms link end */}
          </div>
          {/* offcanvas body end */}
        </div>
      </div>
      {/* side bar nav main div end */}
    </>
  );
};
