import React, { useEffect, useState } from "react";
import { getDecryptedCookie } from "../utils/Utils";
import { GetAllOrdersApi } from "../api/UserApi";
import { Link, useNavigate } from "react-router-dom";
import { formatPrice } from "../constant/PriceConvertor";
import "../styles/order.css";
import orderimg from "../assets/images/image/keelproduct4.jpeg";
import { Api_Url } from "../constant/Url";

export const Orders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(5); // Adjust as needed
  const [orderData, setOrderData] = useState([]);
console.warn(orderData,'orderDataorderDataorderData')
  const handleOrdersApi = async () => {
    const userData = getDecryptedCookie("UserData");
    const token = userData?.token;
    const userId = userData?.userId;

    const data = {
      userId: userId,
    };

    try {
      const response = await GetAllOrdersApi(data);

      if (response?.status === "success") {
        setOrderData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Pagination logic
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orderData?.slice(indexOfFirstOrder, indexOfLastOrder);

  const totalPages = Math.ceil(orderData?.length / ordersPerPage);

  useEffect(() => {
    handleOrdersApi();
  }, []);

  const navigate = useNavigate();

  const handleGetInvoice = (data) => {
    navigate(`invoice/${data}`);
  };

  return (
    <>
      {/* breadcrumb section start */}
      <section className="breadcrumb_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/* breadcrumb main div start */}
              <div className="breadcrumb_main_div">
                {/* breadcrumb nav div start */}
                <nav className="breadcrum_nav">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="">My Account</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      My Orders{" "}
                    </li>
                  </ol>
                </nav>
                {/* breadcrumb nav div end */}
              </div>
              {/* breadcrumb main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/*  */}

      {/* oreder section start */}
      <section className="order-section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row justify-content-center">
            {/* col start */}
            <div className="col-lg-9 col-md-12 col-sm-12 col-12">
              {/* orders main div start */}
              <div className="orders-main-div">
                {/* orders sticky div start */}
                <div className="orders_sticky_div">
                  {/* order title end */}
                  <div className="order-title">
                    <h1>My Orders</h1>
                  </div>
                  {/* order title end */}

                  {/* orders search input div start */}
                  <div className="orders_search_input_div">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search your orders here"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon2"
                      >
                        {" "}
                        <span className="fa fa-search"></span> Search orders
                      </button>
                    </div>
                  </div>
                  {/* orders search input div end */}
                </div>
                {/* orders sticky div end */}

                {/*=== 1 === orders list main div start */}

                {orderData?.length > 0 ? (
                  <div>
                    <div>
                      {orderData?.map((order, orderIndex) => (
                        <div
                          key={orderIndex}
                          className="orders_list_main_div  mb-4 p-3"
                        >
                          {/* <p>
                          <strong>Order Status:</strong> {order.orderStatus}
                        </p>
                        <p>
                          <strong>Total Price:</strong> Rs.{" "}
                          {order.totalPrice.toFixed(2)}
                        </p>
                        <p>
                          <strong>Order Created At:</strong>{" "}
                          {new Date(order.createdAt).toLocaleString()}
                        </p>
                        <p>
                          <strong>Order Updated At:</strong>{" "}
                          {new Date(order.updatedAt).toLocaleString()}
                        </p> */}

                          {/* <div className="orders_list_main_div">
                  <Link to="">
                    <div className="order_list_flex_div">
                      <div className="order_image_details_list_div">
                        <div className="order_list_img_box"> */}

                          <Link to={`/ordersdetails/${order?._id}`}>
                            <div className="order_items_container">
                              {order?.orderItems?.map((item, itemIndex) => (
                                <div
                                  key={itemIndex}
                                  className="order_item_card  d-flex align-items-start"
                                >
                                  <div className="orders_list_main_div w-100">
                                    <Link to={`/ordersdetails/${order?._id}`}>
                                      {/* order list flex div start */}
                                      <div className="order_list_flex_div">
                                        {/* order list div start */}
                                        <div className="order_image_details_list_div">
                                          {/* order list img box start */}
                                          <div className="order_list_img_box">
                                            <img
                                              src={`${Api_Url}${item?.productId?.ProductImage?.[0]}`}
                                              alt="Product"
                                              className="img-fluid"
                                            />
                                          </div>
                                          {/* order list img box end */}
                                          {/* order list details div start */}
                                          <div className="order_list_details_div">
                                            <h2>{item.productId?.ProductName}</h2>
                                            {/* <h4>
                                              Rs.{" "}
                                              {item?.productId?.totalPriceWithGst(
                                                2
                                              )}
                                            </h4> */}
                                            <h4>Rs.{parseInt(item.totalPriceWithGst)}</h4>
                                          
                                           
                                          </div>

                                          {/* order list details div end */}
                                        </div>
                                        {/* order list div end */}

                                        {/* order delivered and not delivered div start */}
                                        <div className="order_delivered_not_delivered_div">
                                          {/* order delivered information div start */}
  <div className="order_delivered_information_div">
  <button>Buy it again</button>
  <button onClick={() => navigate(`/productDetails/${item.productId?._id}`)}>View your item</button>
</div>
                                          {/* order delivered information div end */}
                                        </div>
                                        {/* order delivered and not delivered div end */}
                                      </div>
                                      {/* order list flex div end */}
                                    </Link>
                                  </div>
                                </div>
                              ))}
                            </div>
                            

                            {/* total price and products div start */}
                            <div className="total_price_products_flex_div_main">
                               {/* order total div start */}
                               <div className="order_total_flex_div">
                                {/* order placed div start */}
                                <div className="order_placed_div">
                                  <b>order placed</b>
                                  <p>{new Date(order?.createdAt).toLocaleString()}</p>
                                </div>
                                {/* order placed div end */}

                                 {/* order placed div start */}
                                 <div className="order_placed_div">
                                  <b>Total</b>
                                  <p>₹{order?.totalPrice}</p>
                                </div>
                                {/* order placed div end */}
                               </div>
                               {/* order total div start */}

                              {/* paid and not paid div start */}
                              <div className="paid_not_paid_div">
                                <p>Order #{order?._id}</p>
                                <b>  <div>
 
 <span>
   {order.isPaid ? ( // Check if the item is paid
     <span style={{ color: "green", marginLeft: "8px" }}>
       Online Payment <i className="fa fa-check" aria-hidden="true"></i>
     </span>
   ) : (
     <span style={{ color: "green", marginLeft: "8px" }}>
       Cash on delivery 
     </span>
   )}
 </span>


</div></b>
                              </div>
                              {/* paid and not paid div end */}

                            </div>
                            {/* total price and products div end */}

                          </Link>

                         
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div>No orders found.</div>
                )}

                {/*=== 1 === orders list main div end */}

                {/*=== 2 === orders list main div start */}
              
                {/*=== 2 === orders list main div end */}
              </div>
              {/* orders main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* oreder section end */}
    </>
  );
};
