import React, { useEffect, useState } from "react";
import "../../styles/Footer.css";
import FooterAccordion from "./FooterAccordion";
import logo from "../../assets/images/logo/logo2.png";
import payment1 from "../../assets/images/social-media-icons/visaimg1.jpg";
import payment2 from "../../assets/images/social-media-icons/visaimg2.jpg";
import payment3 from "../../assets/images/social-media-icons/visaimg3.jpg";
import payment4 from "../../assets/images/social-media-icons/visaimg4.jpg";
import payment5 from "../../assets/images/social-media-icons/visaimg5.jpg";
import Cookies from "js-cookie";
import { getAllCategories } from "../../api/CategoryApi";
import { getallSocialMedia } from "../../api/SocialMediaApi";
import { Api_Url } from "../../constant/Url";
import { FetchAllOurBrands } from "../../api/OurBrandsApi";
import { TfiControlShuffle } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../../utils/Utils";

export const Footer = () => {
  const [CategoryData, SetCategoryData] = useState([]);
  const [SocialMediaData, SetSocialMediaData] = useState([]);
  const [OurBrandsData, SetOurBrandsData] = useState([]);

  const FetchAllCategories = async () => {
    try {
      const response = await getAllCategories();
      if (response?.status === "success") {
        SetCategoryData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllSocialMedia = async () => {
    try {
      const response = await getallSocialMedia();
      if (response?.status === "success") {
        SetSocialMediaData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllOurBrands = async () => {
    try {
      const response = await FetchAllOurBrands();
      if (response?.status === "success") {
        SetOurBrandsData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOurBrands();
    FetchAllCategories();
    FetchAllSocialMedia();
  }, []);

  return (
    <>
      {/* footer section start */}
      <footer className="footer">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              {/* footer logo div main start */}
              <div className="footer_logo_div_main">
                {/* footer logo div start */}
                <div className="footer_logo_div">
                  <Link to="/">
                    <img src={logo} alt="logo" className="img-fluid" />
                  </Link>
                </div>
                {/* footer logo div end */}
                <p>
                  Balaji Traders is a reputable company, our brand is that
                  specializes in providing high-quality water purifiers...{" "}
                  <Link to="/aboutus">Read more</Link>
                </p>

                {/* footer media relative div start */}
                <div className="footer_media_object_relative_div">
                  {/* footer media object main div start */}
                  <div className="footer_media_object_main_div">
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <span className="fa fa-phone"></span>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <div className="footer_media_contact">
                          <Link to="tel:+91 9810392397"> +91-9810392397 </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* footer media object main div start */}

                  {/* footer media object main div start */}
                  <div className="footer_media_object_main_div">
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <span className="fa fa-envelope"></span>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <div className="footer_media_contact">
                          <Link to="mailto:helpdesk@aquafreshwaterpurifiers.in">
                            {" "}
                            helpdesk@aquafreshwaterpurifiers.in{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* footer media object main div start */}
                </div>
                {/* footer media relative div end */}
              </div>
              {/* footer logo div main end */}
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-2 col-md-6 col-sm-6 col-6">
              {/* footer link main div start */}
              <div className="footer_link_main_div">
                {/* footer link title div start */}
                <div className="footer_link_title_div">
                  <h5>Products</h5>
                </div>
                {/* footer link title div end */}

                {/* footer ul links div start */}
                <div className="footer_ul_link_div">
                  <ul>
                    {CategoryData?.length > 0
                      ? CategoryData?.map((ele, index) => (
                          <li key={index}>
                            {" "}
                            <Link to={`/${ele?.CategoryNameSlug}`}>
                              {" "}
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>{" "}
                              {ele?.CategoryName}
                            </Link>
                          </li>
                        ))
                      : ""}
                  </ul>
                </div>
                {/* footer ul links div end */}
              </div>
              {/* footer link main div end */}
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-2 col-md-6 col-sm-6 col-6">
              {/* footer link main div start */}
              <div className="footer_link_main_div">
                {/* footer link title div start */}
                <div className="footer_link_title_div">
                  <h5>Information</h5>
                </div>
                {/* footer link title div end */}

                {/* footer ul links div start */}
                <div className="footer_ul_link_div">
                  <ul>
                    <li>
                      {" "}
                      <Link to="/aboutus">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                        About Us
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/contactus">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                        Contact Us
                      </Link>
                    </li>
                    {/* <li>
                      {" "}
                      <Link to="">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                        Blogs
                      </Link>
                    </li> */}
                    <li>
                      {" "}
                      <Link to="/sitemap">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                        Sitemap
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* footer ul links div end */}
              </div>
              {/* footer link main div end */}
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-2 col-md-6 col-sm-6 col-6">
              {/* footer link main div start */}
              <div className="footer_link_main_div">
                {/* footer link title div start */}
                <div className="footer_link_title_div">
                  <h5>Our Brand</h5>
                </div>
                {/* footer link title div end */}

                {/* footer ul links div start */}
                <div className="footer_ul_link_div">
                  <ul>
                    {OurBrandsData?.length > 0
                      ? OurBrandsData?.map((ele, index) => (
                          <li key={index}>
                            {" "}
                            <Link to={`${ele?.OurBrandsUrl}`}>
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>{" "}
                              {ele?.OurBrandsName}
                            </Link>
                          </li>
                        ))
                      : ""}
                  </ul>
                </div>
                {/* footer ul links div end */}
              </div>
              {/* footer link main div end */}
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
              {/* footer link main div start */}
              <div className="footer_link_main_div">
                {/* footer link title div start */}
                <div className="footer_link_title_div">
                  <h5>Quick Links</h5>
                </div>
                {/* footer link title div end */}

                {/* footer ul links div start */}
                <div className="footer_ul_link_div">
                  <ul>
                    <li>
                      {" "}
                      <Link to="/privacypolicy">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/termsconditions">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                        Terms & Conditions
                      </Link>
                    </li>

                    <li>
                      {" "}
                      <Link to="/returnrefundpolicy">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                        Return & Refund Policy
                      </Link>
                    </li>
                    {/* <li>
                      {" "}
                     {!(Cookies.get("UserData")) && <Link to="/login">
                        <i className="fa fa-angle-right" ></i>{" "}
                        Login
                      </Link>}
                    </li> */}
                  </ul>
                </div>
                {/* footer ul links div end */}
              </div>
              {/* footer link main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}

          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/* footer follow us and payment method div start  */}
              <div className="followus_payment_method_main_div_footer">
                {/* footer follow us div start */}
                <div className="footer_follow_us_div">
                  <b>Follow Us:</b>
                  {SocialMediaData?.map((ele) => {
                    return (
                      <Link
                        to={`${ele?.link}`}
                        key={ele?._id}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={`${Api_Url}${ele?.image}`}
                          className="img-fluid"
                          alt=""
                        />{" "}
                      </Link>
                    );
                  })}
                </div>
                {/* footer follow us div end */}

                {/* payment method div start */}
                <div className="payment_method_div">
                  <b>Payment Method:</b>
                  <Link>
                    <img src={payment1} className="img-fluid" alt="" />
                  </Link>
                  <Link>
                    <img src={payment2} className="img-fluid" alt="" />
                  </Link>
                  <Link>
                    <img src={payment3} className="img-fluid" alt="" />
                  </Link>
                  <Link>
                    <img src={payment4} className="img-fluid" alt="" />
                  </Link>
                  <Link>
                    <img src={payment5} className="img-fluid" alt="" />
                  </Link>
                </div>
                {/* payment method div end */}
              </div>
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </footer>
      {/* footer section end */}

      {/* copyright section start */}
      <div className="copyright_section">
        {/* container start  */}
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/* copyright main div start */}
              <div className="copyright_main_div">
                <p>Copyright © 2021 Balaji Traders. All Right Reserved.</p>
              </div>
              {/* copyright main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end  */}
      </div>
      {/* copyright section end */}
    </>
  );
};
