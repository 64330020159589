import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { FetchAllOurBrands } from "../../api/OurBrandsApi";
import { Link } from "react-router-dom";
import { Api_Url } from "../../constant/Url";

export const OurBrands = () => {
  const [AllOurBrandData, SetAllOurBrandData] = useState();

  const AllOurBrands = async () => {
    try {
      const res = await FetchAllOurBrands();
      if (res?.status === "success") {
        SetAllOurBrandData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllOurBrands();
  }, []);

  const settings = {
    dots: false,
    infinite: true, // Enables infinite scrolling
    speed: 3000, // Controls how fast the slider moves
    slidesToShow: 4,
    slidesToScroll: 1, // Keep this small for a smooth transition effect
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0, // Forces the slider to move continuously
    cssEase: "linear", // Ensures constant speed
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: { slidesToShow: 2, slidesToScroll: 1 }, // Show 2 slides on mobile
      },
    ],
  };
  return (
    <>
    {/* our barnd section start */}
    <section className="ourbrand_section">
      {/* container start */}
      <div className="container">
        {/* our barnd title start */}
        <div className="ourbrand_title">
          <h2>Our Brands</h2>
        </div>
        {/* our barnd title end */}
        {/* row start */}
        <div className="row">
          {/* col start */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            {/* our brand slider start */}
            <div className="ourbrand_slider_main_div">
            {AllOurBrandData ? (
          <Slider {...settings}>
            {AllOurBrandData.map((ele) => (
              <div className="px-1" key={ele?._id} data-aos="fade-up">
                <Link target="_blank" to={ele?.OurBrandsUrl}>
                  <div className="Section_Card_1">
                    <img
                      src={`${Api_Url}${ele?.OurBrandsImage}`}
                      alt={ele?.OurBrandsName}
                      className="img-fluid"
                        loading="lazy"
                    />
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <div className="loader"></div>
          </div>
        )}
            </div>
            {/* our brand slider end */}
          
          </div>
          {/* col end */}
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
    {/* our barnd section end */}
    </>
  );
};
