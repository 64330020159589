import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import { tableData, totalData } from "./Data";
import "./Invoice.css"; // Only for non-PDF components
import { useParams } from "react-router-dom";
import { getSingleOrderApi } from "../../api/UserApi";
import logoColor from "../../assets/images/logo/logo-color.png";

export default function Invoice() {
  const { invoiceId } = useParams();

  const [InvoiceData, SetInvoiceData] = useState();

  const FetchSingleInvoice = async () => {
    try {
      const res = await getSingleOrderApi(invoiceId);
      console.log(res, "getSingleOrderApigetSingleOrderApi");

      if (res?.status === "success") {
        SetInvoiceData(res.data);
      }
      console.log(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchSingleInvoice();
  }, []);

  const formatDateTime = (isoDate) => {
    const date = new Date(isoDate);
    return isNaN(date.getTime())
      ? "Invalid Date"
      : date.toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
  };

  const InvoicePDF = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        {/* Header Section */}
        <View style={pdfStyles.header}>
          <View>
            <Text style={[pdfStyles.title, pdfStyles.textBold]}>
              TAX INVOICE
            </Text>
            <Text style={pdfStyles.smallText}>
              Order ID: {InvoiceData?.orderId}
            </Text>
            <Text style={pdfStyles.smallText}>
              Invoice No: {InvoiceData?.orderId}
            </Text>
            <Text style={pdfStyles.smallText}>
              Order Date: {formatDateTime(InvoiceData?.createdAt)}
            </Text>
            <Text style={pdfStyles.smallText}>
              Mode of Payment: {InvoiceData?.paymentDetails?.method}
            </Text>
          </View>
        </View>

        {/* Address Section */}
        <View style={pdfStyles.addressSection}>
          <View style={pdfStyles.addressBox}>
            <Text style={pdfStyles.textBold}>Sold By</Text>
            <Text>BALAJI TRADERS</Text>
            <Text>Plot No 70, Vikash Vihar, New Delhi - 110086</Text>
            <Text>GST: 07FQZPS1788F1ZT</Text>
          </View>
          <View style={pdfStyles.addressBox}>
            <Text style={pdfStyles.textBold}>Shipping Address</Text>
            <Text>{InvoiceData?.shippingAddress?.fullName}</Text>
            <Text>{InvoiceData?.shippingAddress?.addressLine1}</Text>
            <Text>{InvoiceData?.shippingAddress?.addressLine2}</Text>
            <Text>
              {InvoiceData?.shippingAddress?.city},{" "}
              {InvoiceData?.shippingAddress?.state},{" "}
              {InvoiceData?.shippingAddress?.postalCode}
            </Text>
            <Text>{InvoiceData?.shippingAddress?.country}</Text>
          </View>
        </View>

        {/* Table Section */}
        <View style={pdfStyles.table}>
          <View style={[pdfStyles.tableHeader, pdfStyles.textBold]}>
            <Text style={pdfStyles.td}>Product</Text>
            <Text style={pdfStyles.td}>Price</Text>
            <Text style={pdfStyles.td}>Qty</Text>
            <Text style={pdfStyles.td}>Total</Text>
          </View>
          {InvoiceData?.orderItems.map((item, index) => (
            <View key={index} style={pdfStyles.row}>
              <Text style={pdfStyles.td}>{item.name || "Unknown Product"}</Text>
              <Text style={pdfStyles.td}>₹{item.perProductPrice || 0}</Text>
              <Text style={pdfStyles.td}>{item.quantity}</Text>
              <Text style={pdfStyles.td}>₹{item.totalItemPrice || 0}</Text>
            </View>
          ))}
        </View>

        {/* Totals Section */}
        <View style={pdfStyles.totals}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <Text style={pdfStyles.textBold}>Total Amount</Text>
            <Text style={pdfStyles.textBold}>₹{InvoiceData?.totalPrice}</Text>
          </View>
        </View>

        {/* Footer Section */}
        <View style={pdfStyles.footer}>
          <Text>Seller Registered Address: BALAJI TRADERS</Text>
          <Text>
            Declaration: The goods sold are intended for end-use consumption and
            not for resale.
          </Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="container-fluid p-0">
      <div className="viewer">
        <PDFViewer width="100%" height="450px">
          <InvoicePDF />
        </PDFViewer>
      </div>
      <div className="download-link mb-3">
        <PDFDownloadLink document={<InvoicePDF />} fileName="invoice.pdf">
          <button className="btn btn-primary">Download PDF</button>
        </PDFDownloadLink>
      </div>
    </div>
  );
}

const pdfStyles = StyleSheet.create({
  page: { backgroundColor: "#fff", padding: 30, fontSize: 10 },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  title: { fontSize: 18, marginBottom: 10 },
  textBold: { fontFamily: "Helvetica-Bold" },
  smallText: { fontSize: 10, marginBottom: 5 },
  qrCode: {
    textAlign: "center",
    justifyContent: "center",
    width: 100,
    height: 100,
    border: "1px solid #000",
  },
  addressSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  addressBox: {
    width: "30%",
    padding: 10,
    border: "1px solid #ddd",
    borderRadius: 4,
  },
  table: { width: "100%", marginVertical: 20 },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f4f4f4",
    borderBottom: "1px solid #ddd",
  },
  td: { padding: 5, flex: 1, textAlign: "center" },
  row: { flexDirection: "row", borderBottom: "1px solid #ddd" },
  totals: { marginTop: 20 },
  footer: {
    marginTop: 30,
    fontSize: 8,
    borderTop: "1px solid #ddd",
    paddingTop: 10,
  },
});
