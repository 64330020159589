import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { GetFilteredBanners } from "../../api/HomePageBannersApi";
import { Api_Url } from "../../constant/Url";
import { Link } from "react-router-dom";

export const ShopBanners = () => {
  const BannerSettings = {
    dots: false,
    infinite: true,
    speed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const [DesktopAdsBanners, SetDesktopAdsBanners] = useState();

  const fetchShopFilteredBanners = async () => {
    try {
      const filterData = {
        title: "",
        titleSlug: "",
        type: "ads",
        platform: "desktop",
        status: "active",
      };

      const res = await GetFilteredBanners(filterData);
      if (res?.status === "success") {
        SetDesktopAdsBanners(res?.data);
      } else {
        console.error("Failed to fetch products:", res);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchShopFilteredBanners();
  }, []);

  return (
    <>
      {/* shop banner section start*/}
      <div className="shop_banner_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/* shop banner slider main div start */}
              <div className="shop_banner_slider_main_div">
                {DesktopAdsBanners?.length > 0 ? (
                  <Slider {...BannerSettings}>
                    {DesktopAdsBanners.map((ele, index) => (
                      <div className="shpbanner_image_div" key={index}>
                        <Link to={ele?.titleSlug}>
                          <img
                            src={`${Api_Url}${ele?.BannerImage}`}
                            alt=""
                            className="img-fluid"
                            loading="lazy"
                          />
                        </Link>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="loader"></div>
                  </div>
                )}
              </div>
              {/* shop banner slider main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </div>
      {/* shop banner section end*/}
    </>
  );
};
