import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import { getDecryptedCookie } from "../utils/Utils";
import { AddGSTNumber, DeleteGSTNumber, GetAllGSTNumber } from "../api/GSTApi";
import { MdDeleteSweep } from "react-icons/md";
import { Link } from "react-router-dom";
import "../styles/Gst.css";

export const GSTNumber = () => {
  const initialValues = {
    gstnumber: "",
  };

  const validationSchema = Yup.object().shape({
    gstnumber: Yup.string()
      .required("GST Number is required")
      .matches(
        /^[A-Z0-9]{15}$/,
        "GST number must be exactly 15 characters, with uppercase letters and digits only"
      ),
  });

  const [GSTData, SetGSTData] = useState(null);

  const fetchGSTNumbers = async () => {
    try {
      const userData = getDecryptedCookie("UserData");
      const mobile = userData?.mobile;

      if (!mobile) {
        toast.error("Mobile number not found!");
        return;
      }

      const response = await GetAllGSTNumber({ mobile });
      if (response.status === "success") {
        SetGSTData(response.data || []);
      } else {
        toast.error(response.message || "Failed to fetch GST numbers.");
      }
    } catch (error) {
      console.error("Error fetching GST numbers:", error);
      toast.error("An error occurred while fetching GST numbers.");
    }
  };

  useEffect(() => {
    fetchGSTNumbers();
  }, []);

  const handleFormSubmit = async (values, actions) => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      const mobile = userData?.mobile; // Extract mobile number from userData
      if (!mobile) {
        toast.error("Mobile number not found!");
        return;
      }

      const formData = {
        gstnumber: values.gstnumber.toUpperCase(),
        mobile: mobile,
      };

      const res = await AddGSTNumber(formData);

      if (res?.status === "success") {
        toast.success("GST Added successfully!", { duration: 3000 });
        actions.resetForm();
        fetchGSTNumbers();
      } else {
        toast.error(res?.message, { duration: 3000 });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }

    actions.setSubmitting(false); // Enable the submit button after process
  };

  const handleDeleteGSTNumber = async (data) => {
    const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
    const mobile = userData?.mobile; // Extract mobile number from userData
    if (!mobile) {
      toast.error("Mobile number not found!");
      return;
    }
    const formData = {
      gstnumber: data,
      mobile: mobile,
    };

    const res = await DeleteGSTNumber(formData);
    if (res?.status === "success") {
      toast.success("GST Delete successfully!", { duration: 3000 });
      fetchGSTNumbers();
    }
    try {
    } catch (error) {
      console.log(error);
    }
  };

  return (
   <>
 <Toaster />
   
{/* breadcrumb section start */}
<section className="breadcrumb_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/* breadcrumb main div start */}
              <div className="breadcrumb_main_div">
                {/* breadcrumb nav div start */}
               
                {/* breadcrumb nav div end */}
              </div>
              {/* breadcrumb main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/*  */}
   
   {/* gst numbers section start */}
   <div className="gst_numbers_section">
     {/* container start */}
     <div className="container">
      {/* row start */}
      <div className="row justify-content-center">
        {/* col start */}
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          {/* gst number main div start */}
          <div className="gst_number_main_div">
            {/* gst number title start */}
            <div className="gst_number_title">
              <h1>Add GST Number</h1>
            </div>
            {/* gst number title end */}

           {/* gst form main div start */}
           <div className="gst_form_main_div">
           <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFormSubmit}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  {/* nested row start */}
                  <div className="row">
                    {/* col start */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
             <div className="form_gst_input_div">
                 <label> GST Number <span>*</span></label>
                 <Field type="text" name="gstnumber" placeholder="Enter your GST Numbers" className="form-control" maxLength={15}/>
                 <ErrorMessage name="gstnumber" component="div" className="error_msg_div"/>
       <button type="submit" className="gst_add_btn_div" disabled={isSubmitting}> Add GST Number</button>
    </div>
    </div>
                    {/* col end */}

 {/* col start */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="note_gst_div">
            <h2>Notes related to GST Number</h2>
               <ul>
                  <li>A GST Number consists of 15 digits</li>
                   <li> For Example: We take a GST No. <b>08ABCDE9999F1Z8</b></li>
                    <li> The first two digits indicate the state code, for
                            Rajasthan it is 08</li>
               </ul>
            </div>
           
                        </div>
                     {/* col end */}
                  </div>
                  {/* nested row end */}
                 
                </Form>
              )}
            </Formik>
           </div>
           {/* gst form main div end */}

          </div>
          {/* gst number main div end */}
        </div>
        {/* col end */}

         {/* col start */}
         {GSTData?.length > 0 && (
         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            {/* all saved gst numbers div start */}
           
            <div className="all_saved_gst_numbers_div">
              <h4>All Saved GST Numbers</h4>
                <ul className="list-group">
                  {GSTData?.map((gst, index) => (
                    <li key={index}>{gst}
                      <button className="delete_btn_gst" onClick={() => handleDeleteGSTNumber(gst)}>
                        <span><MdDeleteSweep className="text-danger fs-4 ms-2"/>
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
            </div>
         
            {/* all saved gst numbers div end */}
            
         </div>
          )}
         {/* col end */}
      </div>
      {/* row end */}
     </div>
     {/* container end */}
   </div>
   {/* gst numbers section end */}
   </>
  );
};
