import { Api_Url } from "../constant/Url";

export const createOrderApi = async (data, token) => {

  try {
    const res = await fetch(`${Api_Url}/order/createorder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
      body: JSON.stringify(data), // Send the data as a JSON string in the request body
    });

    // Parse the response as JSON
    const responseData = await res.json();

    // Check if the request was successful
    if (!res.ok) {
      throw new Error(responseData.message || "Failed to add product to cart");
    }

    // Return the response data (e.g., the updated cart or success message)
    return responseData;
  } catch (error) {
    // Handle errors more explicitly
    console.error("An error occurred while adding to cart:", error.message);
    return { success: false, message: error.message }; // Return error message to handle in the calling function
  }
};





