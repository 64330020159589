import { Api_Url } from "../constant/Url";

export const getallSocialMedia = async () => {
    try {
      const res = await fetch(`${Api_Url}/socialmedia/getallsocialmedia`, {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      });
      return await res.json();
    } catch (error) {
      console.log(error);
    }
  };