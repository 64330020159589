import React from "react";
import Slider from "react-slick";
import waterpurifier1 from "../../assets/images/image/waterpurifier1.png";
import waterdispenser1 from "../../assets/images/image/waterdispenser1.png";
import vacuumcleaner1 from "../../assets/images/image/vacuumcleaner1.png";
import kitchenappliance1 from "../../assets/images/image/kitchenappliance1.png";

export const OurProductsCategory = () => {
  const TopToBottomSettings = {
    dots: false,
    infinite: true,
    vertical: true,
    rtl: true,
    verticalSwiping: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const BottomToTopSettings = {
    dots: false,
    infinite: true,
    vertical: true,
    rtl: false,
    verticalSwiping: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200, // Large desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // Tablets and small desktops
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile phones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
     {/* our products category slider start */}
     <section className="ourproducts_category_section">
      {/* container start */}
      <div className="container">
        {/* ourcategory title start */}
        <div className="ourcategory_title">
        <h2>Our Products Category</h2>
        </div>
        {/* ourcategory title end */}
        {/* row start */}
        <div className="row">
          {/* col start */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            {/* our category slider start */}
            <Slider {...TopToBottomSettings}>
              {/* our category slider img box start */}
              <div className="our_category_slider_img_box">
              <img src={waterpurifier1} alt="slider" className="img-fluid"  loading="lazy"></img>
              </div>
              {/* our category slider img box end */}

               {/* our category slider img box start */}
               <div className="our_category_slider_img_box">
              <img src={waterdispenser1} alt="slider" className="img-fluid"  loading="lazy"></img>
              </div>
              {/* our category slider img box end */}

            </Slider>
            {/* our category slider end */}
          </div>
          {/* col end */}

          {/* col start */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            {/* our category slider start */}
            <Slider {...BottomToTopSettings}>
              {/* our category slider img box start */}
              <div className="our_category_slider_img_box">
              <img src={kitchenappliance1} alt="slider" className="img-fluid"  loading="lazy"></img>
              </div>
              {/* our category slider img box end */}

               {/* our category slider img box start */}
               <div className="our_category_slider_img_box">
              <img src={vacuumcleaner1} alt="slider" className="img-fluid"  loading="lazy"></img>
              </div>
              {/* our category slider img box end */}

            </Slider>
            {/* our category slider end */}
          </div>
          {/* col end */}


        </div>
        {/* row end */}
      </div>
      {/* container end */}
     </section>
     {/* our products category slider end */}
    </>
  );
};
