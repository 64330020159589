import { AddToCart, GetSingleProductsApi } from "../api/ProductsApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import MyContext from "./myContext";
import { useState, useEffect } from "react";
import { GetUserProfile } from "../api/UserApi";
import { getDecryptedCookie, setEncryptedCookie } from "../utils/Utils";
import { GetAllCart, RemoveCart, UpdateAddToCart } from "../api/CartApi";

const MyProvider = ({ children }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [cartData, setCartData] = useState([]);
  console.log(cartData, "cartDatacartDatacartDatacartDatacartData");
  const [totalPages,setTotalPages]= useState();
  const [loadings,setLoadings]= useState();
  const [particularItemQty,setParticularItemQty]= useState();
  const [responseData,setResponseData]= useState();
  
  // Get User Profile from cookies and sync with API
  const userProfile = async () => {
    try {
      const userData = getDecryptedCookie("UserData");
      const token = userData?.token;
      const mobile = userData?.mobile;

      if (!token) {
        console.log("Token not found in cookies");
        return;
      }

      const res = await GetUserProfile(mobile, token);

      if (res?.status === "success") {
        setUserData(res.data);
      } else {
        console.error("Failed to fetch user profile", res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Add to Cart function (for both logged in and guest users)
  const handleAddtoCart = async (newData) => {
    try {
      console.log(newData, 'provider');
      
      // Check if user is logged in
      const userData = getDecryptedCookie("UserData");
      const token = userData?.token;
      const userId = newData?.userId || userData?.userId;
  
      const data = {
        productId: newData?.productId,
        quantity: newData?.quantity,
        userId: userId,
      };
  
      // If logged in, sync with API
      if (token) {
        const res = await AddToCart(data, token);
  
        if (res?.status === "success") {
          // Sync the cart state after adding the item to the API
          userProfile(); // Update user profile if needed
          toast.success(res?.message);
          fetchCartData(); // Fetch updated cart data after adding the item
        } else {
          toast.error("Error in adding product to cart");
        }
      } else {
        // If not logged in, add to cart in cookies
        let currentCart = getDecryptedCookie("CartData") || [];
        const existingProductIndex = currentCart.findIndex(item => item.productId === newData.productId);
  
        if (existingProductIndex >= 0) {
          // If product already exists, update quantity
          currentCart[existingProductIndex].quantity += newData.quantity;
        } else {
          // If product doesn't exist, add it to cart
          currentCart.push({
            productId: newData.productId,
            quantity: newData.quantity,
          });
        }
  
        // Save the updated cart data to cookies
        setEncryptedCookie("CartData", currentCart);
        toast.success("Product added to cart (Guest Mode)");
  
        // Fetch updated cart data after adding the item (for guest user)
        fetchCartData();
      }
    } catch (error) {
      console.error("Error in adding to cart:", error);
      toast.error("Error adding product to cart");
    }
  };
  

  // Sync guest cart to API when user logs in
  async function syncGuestCartToAPI(){
    const guestCartData = getDecryptedCookie("CartData") || [];
    
    if (guestCartData.length > 0) {
      const userId = userData?._id;
      const token = getDecryptedCookie("UserData")?.token;

      // Push all guest cart items to API
      for (let item of guestCartData) {
        await handleAddtoCart({...item, userId, token});
      }

      // Clear guest cart from cookies after sync
      setEncryptedCookie("CartData", []);
      toast.success("Your guest cart has been synced with your account!");
    }
  };

  // Call sync guest cart to API after login
  useEffect(() => {
    if (userData?._id) {
      syncGuestCartToAPI();
    }
  }, [userData]);

// Fetch Cart Data (with product details)

const fetchCartData = async (page, limit) => {
  try {
    console.log('page, limitfetchCartDatafetchCartDatafetchCartDatafetchCartData');
    const userData = getDecryptedCookie("UserData");
    const token = userData?.token;
    const userId = userData?.userId;

    setLoadings(true);

    if (userId && token) {
      // Fetch cart data for logged-in user
      const data = { userId: userId, page, limit: 10 };

      const res = await GetAllCart(data, token);

      if (res?.status === "success") {
        // Initialize summary variables
        let totalPriceAfterDiscount = 0;
        let totalPrice = 0;
        let totalGst = 0;
        let totalDiscountPercentage = 0;
        let totalPriceWithTax = 0;

        // Fetch product details for each cart item
        const cartItemsWithDetails = await Promise.all(res.data.map(async (item) => {
          const { 
            TotalPriceWithTax, 
            DiscountPercentage, 
            PriceAfterDiscount, 
            ProductPrice, 
            ProductGst 
          } = item.product;

          // Get quantity of the item
          const quantity = item.quantity;

          // Update the summary totals based on the product's price and quantity
          totalPriceAfterDiscount += PriceAfterDiscount * quantity;
          totalPrice += ProductPrice * quantity;
          totalGst += (ProductPrice * ProductGst / 100) * quantity;
          totalDiscountPercentage += DiscountPercentage * quantity;
          totalPriceWithTax += TotalPriceWithTax * quantity;

          // Return cart item with additional details
          return {
            ...item
          };
        }));

        // Calculate average discount percentage (if needed)
        const avgDiscountPercentage = res.data.length > 0 ? totalDiscountPercentage / res.data.length : 0;

        // Update state with data and summary
        setResponseData({
          ...res,
          summary: {
            totalPriceAfterDiscount,    // Total price after discount (considering quantity)
            totalGst,                   // Total GST amount (considering quantity)
            totalPrice,                 // Total price with GST (considering quantity)
            totalPriceWithTax,          // Total price with tax (GST included) (considering quantity)
            avgDiscountPercentage,      // Average discount percentage applied, considering quantity
          }
        });

        setCartData(cartItemsWithDetails); // Update cart state with product details
        setTotalPages(Math.ceil(res.totalPages)); // Update total pages
      } else {
        setCartData([]);
        setTotalPages(2);
      }
    } else {
      // If guest, fetch cart from cookies
      const cartDataFromCookies = getDecryptedCookie("CartData") || [];

      if (cartDataFromCookies.length > 0) {
        // Initialize summary variables
        let totalPriceAfterDiscount = 0;
        let totalPrice = 0;
        let totalGst = 0;
        let totalDiscountPercentage = 0;
        let totalPriceWithTax = 0;

        // Optionally, map through cart items and get product details if needed
        const cartItemsWithDetails = await Promise.all(cartDataFromCookies.map(async (item) => {
          const productDetails = await GetSingleProductsApi(item.productId);
          const product = productDetails.data;

          const { 
            TotalPriceWithTax, 
            DiscountPercentage, 
            PriceAfterDiscount, 
            ProductPrice, 
            ProductGst 
          } = product;

          // Get quantity of the item
          const quantity = item.quantity;

          // Update the summary totals based on the product's price and quantity
          totalPriceAfterDiscount += PriceAfterDiscount * quantity;
          totalPrice += ProductPrice * quantity;
          totalGst += (ProductPrice * ProductGst / 100) * quantity;
          totalDiscountPercentage += DiscountPercentage * quantity;
          totalPriceWithTax += TotalPriceWithTax * quantity;

          // Return cart item with additional details
          return {
            ...item,
            product
          };
        }));

        // Calculate average discount percentage (if needed)
        const avgDiscountPercentage = cartDataFromCookies.length > 0 ? totalDiscountPercentage / cartDataFromCookies.length : 0;

        // Update state with data and summary
        setResponseData({
          status: "success",
          message: "Cart data fetched successfully",
          totalPages: Math.ceil(cartDataFromCookies.length / limit),
          totalItems: cartDataFromCookies.length,
          data: cartItemsWithDetails,
          summary: {
            totalPriceAfterDiscount,    // Total price after discount (considering quantity)
            totalGst,                   // Total GST amount (considering quantity)
            totalPrice,                 // Total price with GST (considering quantity)
            totalPriceWithTax,          // Total price with tax (GST included) (considering quantity)
            avgDiscountPercentage,      // Average discount percentage applied, considering quantity
          }
        });

        setCartData(cartItemsWithDetails); // Update cart state with product details
        setTotalPages(Math.ceil(cartDataFromCookies.length / limit)); // Update total pages
      } else {
        setCartData([]);
        setTotalPages(1);
      }
    }
  } catch (error) {
    console.error("Error fetching cart data:", error);
  } finally {
    setLoadings(false);
  }
};
// Handle deleting items from the cart
const handleDeleteCart = async (id) => {
  try {
    const userData = getDecryptedCookie("UserData");
    const token = userData?.token;
    const userId = userData?.userId;

    // If user is not authenticated, remove from cookies
    if (!token || !userId) {
      // Remove item from cookies directly if not logged in
      const cartData = getDecryptedCookie("CartData") || [];
      const updatedCartData = cartData.filter((item) => item.productId !== id);
      setEncryptedCookie("CartData", updatedCartData); // Update cookies after removing the item
      toast.success("Product removed from cart.");

      // After removing the item locally (for guest user), fetch the updated cart data
      fetchCartData(); // Sync updated data for guest user
      return; // Skip the API call and return
    }

    // If the user is authenticated, proceed to API deletion
    const data = {
      productId: id,
      userId: userId,
    };

    // Make the API call to remove the item from the cart
    const response = await RemoveCart(data);

    // Check the response for success or failure
    if (response?.success === true) {
      toast.success(response?.message);
      // After deleting from server, re-fetch the cart data to sync
      fetchCartData();
    } else {
      // Handle failure case
      toast.error("Failed to remove the product from cart");
    }
  } catch (error) {
    // Log and display any errors
    console.error("Error deleting product from cart:", error);
    toast.error(error.message || "An unexpected error occurred");
  }
};

const handleQuantityChange = async (productId, action, quantity) => {
  console.log("handleQuantityChange called with productId:", productId, "and action:", action, quantity);

  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;

  // Update cart state locally
  setCartData((prevCartData) => {
    const updatedCart = prevCartData
      .map((item) => {
        if (item.product._id === productId) {
          let newQuantity = item.quantity;

          // Handle quantity decrease
          if (action === "decrease") {
            if (newQuantity > 1) {
              newQuantity -= 1;
            } else {
              // If quantity is 1, set it to 0 for removal
              newQuantity = 0;
            }
          }

          // Log the item before updating the quantity
          console.log("Updated Item:", { ...item, quantity: newQuantity });

          setParticularItemQty(newQuantity);

          // Return updated item with the new quantity
          return { ...item, quantity: newQuantity };
        }
        return item;
      })
      .filter((item) => item.quantity > 0); // Remove items with quantity 0

    return updatedCart; // Return the updated cart
  });

  // If the quantity becomes 0 for the product, delete it from the cookies for guest users
  if (action === "decrease") {
    const currentCart = getDecryptedCookie("CartData") || [];
    const updatedCart = currentCart.filter((item) => item.productId !== productId || item.quantity > 0); // Remove items with quantity 0

    // Only update cookies if the cart has changed
    if (updatedCart.length !== currentCart.length) {
      setEncryptedCookie("CartData", updatedCart); // Save updated cart to cookies
      toast.success("Product removed from cart.");
    }
  }

  // Proceed with server update if logged in
  if (token && userId) {
    const data = {
      productId: productId,
      quantity: action === "increase" ? 1 : -1,
      userId: userId,
    };

    try {
      const UpdateApiResponse = await UpdateAddToCart(data, token);
      if (UpdateApiResponse?.status === "success") {
        toast.success(UpdateApiResponse?.message);
        // Call fetchCartData after successful update
        fetchCartData(); // Sync updated data from the server
      }
    } catch (error) {
      console.error("Error updating cart:", error);
      toast.error("Failed to update cart.");
    }
  } else {
    // Guest user: update cart in cookies
    const currentCart = getDecryptedCookie("CartData") || [];
    const updatedCart = currentCart
      .map((item) => {
        if (item.productId === productId) {
          let newQuantity = item.quantity;

          if (action === "increase" && newQuantity < 10) {
            newQuantity += 1;
          } else if (action === "decrease" && newQuantity > 1) {
            newQuantity -= 1;
          } else if (action === "decrease" && newQuantity === 1) {
            newQuantity = 0; // Set to 0 to mark for removal
          }

          return { ...item, quantity: newQuantity };
        }
        return item;
      })
      .filter((item) => item.quantity > 0); // Remove items with quantity 0

    setEncryptedCookie("CartData", updatedCart); // Save updated cart to cookies
    toast.success("Quantity updated in guest cart.");

    // Call fetchCartData after updating cart for guest user
    fetchCartData(); // Sync updated data for guest user
  }
};





  return (
    <MyContext.Provider value={{ handleAddtoCart, userProfile, userData,fetchCartData
     ,cartData,handleDeleteCart,handleQuantityChange,loadings,responseData}}>
      {children}
    </MyContext.Provider>
  );
};

export default MyProvider;
