import React, { useEffect, useState } from "react";
import { AddToCart, GetFilteredProducts } from "../api/ProductsApi";
import { MdOutlineShoppingCart } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import { getDecryptedCookie } from "../utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Api_Url } from "../constant/Url";

export const WaterDispensers = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const navigate = useNavigate();

  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;
  const userId = userData?.userId;

  const [ProductsData, SetProductsData] = useState([]);

  // Filter State
  const [priceMin, setPriceMin] = useState(1000); // Set initial min price
  const [priceMax, setPriceMax] = useState(100000); // Set initial max price
  const [shopName, setShopName] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'
  const [selectedShops, setSelectedShops] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const shops = ["Keel", "Grand Plus", "Royal AquaFresh", "Aqua Fresh"];
  const subCategories = [
    "Hot & Normal",
    "Hot, Normal & Cold",
    "Hot, Normal, Cold & Cooling Cabinet",
    "Hot, Normal, Cold with RO",
  ];

  const fetchFilteredProducts = async () => {
    const filterData = {
      CategoryName: "Water Dispensers",
      SubCategoryName: selectedSubCategories.join(","),
      priceMin,
      priceMax,
      shopName: selectedShops.join(","),
      order: sortOrder,
    };

    try {
      const res = await GetFilteredProducts(filterData);
      if (res?.status === "success") {
        SetProductsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShopChange = (shop) => {
    setSelectedShops((prev) =>
      prev.includes(shop) ? prev.filter((s) => s !== shop) : [...prev, shop]
    );
  };

  const handleSelectAllShops = (e) => {
    if (e.target.checked) {
      setSelectedShops(shops);
    } else {
      setSelectedShops([]);
    }
  };

  const handleSubCategoryChange = (subCategory) => {
    setSelectedSubCategories((prev) =>
      prev.includes(subCategory)
        ? prev.filter((s) => s !== subCategory)
        : [...prev, subCategory]
    );
  };

  const handleSelectAllSubCategories = (e) => {
    if (e.target.checked) {
      setSelectedSubCategories(subCategories);
    } else {
      setSelectedSubCategories([]);
    }
  };

  // Trigger API on filter changes
  useEffect(() => {
    fetchFilteredProducts();
  }, [priceMin, priceMax, selectedShops, sortOrder, selectedSubCategories]);

  // Handle Slider Change
  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    if (name === "min") {
      setPriceMin(parseInt(value));
    } else if (name === "max") {
      setPriceMax(parseInt(value));
    }
  };

  const HandleAddToCart = async (productData) => {
    try {
      if (userId === undefined) {
        // //  navigate("/login");;
      }

      const data = {
        productId: productData._id,
        quantity: 1,
        userId: userId,
      };

      const res = await AddToCart(data, token);

      if (res?.status === "success") {
        toast.success(res?.message);
        window.location.reload();
      } else {
        toast.error("Error in Add to Product");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleAddtoCart = async (productData) => {
    try {
      if (userId === undefined) {
        // //  navigate("/login");;
      }

      const data = {
        productId: productData._id,
        quantity: 1,
        userId: userId,
      };

      const res = await AddToCart(data, token);

      if (res?.status === "success") {
        toast.success(res?.message);
        window.location.reload();
      } else {
        toast.error("Error in Add to Product");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewProduct = (productId) => {
    navigate(`product-details/${productId}`);
  };

  return (
    <div className="container-fluid">
      <Toaster />

      {Array.isArray(ProductsData) && ProductsData?.length > 0 ? (
        <div className="row my-4">
          <div className="col-md-3">
            <div>
              {/* Price Filter */}
              <div className="mb-3">
                <h5 className="m-0">Filter by Price</h5>
                <div className="price-slider-container">
                  <div className="price-values">
                    ₹ {priceMin} - ₹ {priceMax}
                  </div>
                  <input
                    type="range"
                    name="min"
                    min="1000"
                    max="100000"
                    value={priceMin}
                    onChange={handlePriceChange}
                    className="price-slider"
                  />
                  <input
                    type="range"
                    name="max"
                    min="1000"
                    max="100000"
                    value={priceMax}
                    onChange={handlePriceChange}
                    className="price-slider"
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <h5 className="m-0">Filter by Price</h5>

              <select
                className="form-select"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
              >
                <option value="asc">Low to High</option>
                <option value="desc">High to Low</option>
              </select>
            </div>

            <div className="mb-3">
              <h5 className="m-0">Filter by Subcategory</h5>
              <div className="subcategory-filters">
                <input
                  type="checkbox"
                  onChange={handleSelectAllSubCategories}
                />{" "}
                All Subcategories
                {subCategories.map((subCategory) => (
                  <div key={subCategory}>
                    <input
                      type="checkbox"
                      checked={selectedSubCategories.includes(subCategory)}
                      onChange={() => handleSubCategoryChange(subCategory)}
                    />{" "}
                    {subCategory}
                  </div>
                ))}
              </div>
            </div>

            <div className="mb-3">
              <h5 className="m-0">Filter by Shop Name</h5>
              <div className="shop-filters">
                <input type="checkbox" onChange={handleSelectAllShops} /> All
                Shops
                {shops.map((shop) => (
                  <div key={shop}>
                    <input
                      type="checkbox"
                      checked={selectedShops.includes(shop)}
                      onChange={() => handleShopChange(shop)}
                    />{" "}
                    {shop}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-md-9">
            <div>
              <h4 className="m-0">Water Purifier</h4>
              <p className="m-0">Total Products : {ProductsData?.length}</p>
            </div>
            <div className="row">
              {Array.isArray(ProductsData) && ProductsData.length > 0 ? (
                <div className="row d-flex">
                  {ProductsData.map((product, index) => (
                    <div className="col-md-4 pb-4">
                      <div key={product._id}>
                        <div
                          className="card-content"
                          onClick={() => handleViewProduct(product._id)}
                        >
                          <div className="image-wrapper">
                            <img
                              src={`${Api_Url}${product.ProductImage[0]}`}
                              alt={product.ProductName}
                            />
                          </div>
                          <div className="product-details">
                            <h5>{product.ProductName}</h5>
                            <p>
                              <b>
                                ₹
                                {parseInt(
                                  product.priceAfterDiscount
                                ).toLocaleString()}
                              </b>
                              <span className="px-2">
                                ₹
                                {parseInt(
                                  product.totalPriceWithTax
                                ).toLocaleString()}
                              </span>
                              <b className="discount">
                                Save {product.discountPercentage}%
                              </b>
                            </p>

                            <div className="brand-name">
                              Brand: {product.ShopName}
                            </div>
                            <div className="product-actions">
                              <button className="view-details">
                                View Product
                              </button>
                              <button
                                className="add-to-cart"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  HandleAddToCart(product);
                                }}
                              >
                                <MdOutlineShoppingCart />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <h5 className="text-center">No Data Present</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};
