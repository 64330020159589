import React, { useEffect, useState } from "react";
import "../../styles/ShopByCategory.css";
import { getAllCategories } from "../../api/CategoryApi";
import { Api_Url } from "../../constant/Url";

export const ShopByCategory = () => {
  const [allCategory, setAllCategory] = useState([]);
  async function fetchAllCategory() {
    const res = await getAllCategories();
    if (res?.status === "success") {
      setAllCategory(res?.data);
    }
  }
  useEffect(() => {
    fetchAllCategory();
  }, []);
  return (
    <>
      {/* shopby category section start */}
      <section className="shopby_category_Section">
        {/* container start */}
        <div className="container">
          {/* title start */}
          <div className="title_shopby">
            <h1>Shop By Category</h1>
          </div>
          {/* title end */}

          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="shopby_category_img">
                <a href={allCategory[0]?.CategoryNameSlug}>
                  <img
                    src={`${Api_Url}${allCategory[0]?.CategoryImage?.[1]}`}
                    alt=""
                    loading="lazy"
                    className="img-fluid"
                  />
                  <div className="shopnow_div">
                    <div className="shopnow_button">Shop Now</div>
                  </div>
                </a>
              </div>
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              {/* nested row start */}
              <div className="row">
                {/* col start nested*/}
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="nested_shopby_main_div">
                    <a href={allCategory[1]?.CategoryNameSlug}>
                      <img
                        src={`${Api_Url}${allCategory[1]?.CategoryImage?.[1]}`}
                        alt=""
                        loading="lazy"
                        className="img-fluid"
                      />
                      <div className="shopnow_div">
                          <div className="shopnow_button ">Shop Now</div>
                      </div>
                    </a>
                  </div>
                </div>
                {/* col end nested*/}

                {/* col start nested*/}
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="main_div_flex_shopby">
                    {/*  */}
                    <div className="nested_shopby_main_div">
                      <a href={allCategory[2]?.CategoryNameSlug}>
                        <img
                          src={`${Api_Url}${allCategory[2]?.CategoryImage?.[1]}`}
                          alt=""
                          loading="lazy"
                          className="img-fluid"
                        />
                        <div className="shopnow_div">
                            <div className="shopnow_button">Shop Now</div>
                        </div>
                      </a>
                    </div>
                    {/*  */}

                    {/*  */}
                    <div className="nested_shopby_main_div">
                      <a href={allCategory[0]?.CategoryNameSlug}>
                        <img
                          src={`${Api_Url}${allCategory[3]?.CategoryImage?.[1]}`}
                          alt=""
                          loading="lazy"
                          className="img-fluid"
                        />
                        <div className="shopnow_div">
                            <div className="shopnow_button">Shop Now</div>
                        </div>
                      </a>
                    </div>
                    {/*  */}
                  </div>
                </div>
                {/* col end nested*/}
              </div>
              {/* nested row end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* shopby category section end */}
    </>
  );
};
