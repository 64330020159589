import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/orderConfirmation.css';
import logoColor from "../assets/images/logo/logo-color.png";

const OrderConfirmation = () => {
  const [cartQuantity, setCartQuantity] = useState(5); // Example of cart quantity
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const navigate = useNavigate(); // React Router hook for navigation
  const location = useLocation(); // Get the current URL location

  // Extract the Razorpay payment reference (paymentId) from the URL query parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentId = queryParams.get("reference");
    if (paymentId) {
      setPaymentStatus('success');
      setOrderId(paymentId); // Set the order ID (this would be your Razorpay payment ID)
    } else {
      setPaymentStatus('failure');
    }
  }, [location]);

  // Handle the "Back to Shop" button click
  const handleBackToShop = () => {
    navigate("/"); // Navigate to the shop page
  };

  // Handle the "View Order List" button click
  const handleViewOrderList = () => {
    navigate("/orders"); // Navigate to the order list page
  };

  return (
    <div className="container-fluid orderPage">
      <div className="row justify-content-center align-items-center min-vh-100">
        <div className="col-12 col-md-8 col-lg-6 text-center message-box">
          <div className="card shadow-sm">
            <div className="card-body border-success">
              <br />
              <img
                src={logoColor}
                alt="Logo"
                height={60}
                width={290}
              />
              <hr />
              <h1 className="monserrat-font text-muted">
                Thank you for your order
              </h1>
              <div className="confirm-green-box   px-2 bg-light border border-success">
                 <div className="d-flex justify-content-center">
    <i className="fa fa-check-circle" style={{ fontSize: '4rem', color: '#28a745' }}></i>
  </div>
                <h5>ORDER CONFIRMATION</h5>
                {/* {paymentStatus === 'success' ? (
                  <p>Your order #{orderId} has been successful!</p>
                ) : (
                  <p>There was an issue with your payment. Please try again.</p>
                )} */}
                <p>Thank you for choosing Balaji Traders. You will shortly receive a confirmation email.</p>
              </div>
              {/* Buttons with Bootstrap 5 classes */}
              <div className="d-flex justify-content-center gap-3">
                <button
                  id="back-to-shop-btn"
                  className='btn'
                 style={{ backgroundColor: '#045b87', color: '#ffffff' }}
                  onClick={handleBackToShop}
                >
                  Back to Shop
                </button>

                <button
                  id="back-to-shop-btn"
                  className="btn bg-black text-white"
                  onClick={handleViewOrderList}
                >
                  View Order List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
